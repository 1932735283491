import React, { useEffect } from "react";

import CommonNav from "../CommonNav/CommonNav";
import EnquiryForm from "../EnquiryForm/EnquiryForm";
import Footer from '../Footer/Footer'

function HillStations() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <div className="Athira-wrapper">
      <CommonNav />
      <div className="athira-MainHeading">
        <h1>India / 12-Day Itineraries / Hill Stations </h1>

        <h4>Suggested itinerary</h4>
        <h6>Day 01: Coimbatore (arrival) </h6>
        <p>
          Upon reaching Coimbatore airport/railway station, we will transfer you
          to Ooty (100 KM). Check in at the hotel as you arrive Ooty. Evening
          free for shopping and other activities and overnight stay at Ooty.
        </p>
        <h6>Day 02: Ooty</h6>
        <p>
          After breakfast proceed for toy train ride (cost extra). Then transfer
          to visit the Doddabeta Peak which is the highest peak of Ooty. From
          here you will get a very panoramic view of the neighboring regions. By
          noon visit the famous Botanical garden built in 1847 and the Ooty Lake
          were boating facility is available. Horse-back riding around the lake
          also is available. Evening free for shopping and other activities .
          Overnight at Ooty.
        </p>
        <h6>Day 03: Ooty – Valparai</h6>
        <p>
          After breakfast drive to an off beat hill station Valparai (200 KM)
          Check into a tea estate bungalow. (Here you can enjoy optional forest
          drive (jungle safari). Overnight stay at Valparai.
        </p>
        <h6>Day 04: Valparai</h6>
        <p>Relax your holiday at this hill station. Stay at Valparai.</p>
        <h6>Day 05: Valparai - Kodaikanal </h6>
        <p>
          After breakfast departure for Kodaikanal (200 KM) Evening check in to
          resort .Overnight stay at Kodaikanal.
        </p>
        <h6>Day 06: Kodaikanal</h6>
        <p>
          After breakfast proceed for city sightseeing. Lake, Bryant park,
          Koker’s walk, Five hundred years tree, Pampar fall, Pillar Rock,
          valley view, upper lake view, shopping. Stay at Kodaikanal
        </p>
        <h6>Day 07: Kodaikanal – Dindigul </h6>
        <p>
          Today morning you drive to Rajakkad estate (75 KM) in Dindigul
          district in Tamil Nadu. Here you will be staying in a heritage house
          located in the middle of a coffee and pepper estate and located 1000
          metre above sea level. Experience the life of a planter at this
          estate. Stay at Rajakkad estate
        </p>
        <h6>Day 08: Rajakkad Estate</h6>
        <p>
          Today you can enjoy various activities - walks from one hr to a day
          long with packed lunch. Visit coffee and pepper plantation area with
          lot of semi forested areas. (If fortunate you get glance of some
          wildlife, indian gaur, deers, giant squirrels, lots of birds etc...)
          There is a small waterfall area also in the estate. A short walk to
          the farm and to the nearby village also possible. Over night stay at
          Rajakkad estate.
        </p>
        <h6>Day 09: Rajakkad Estate – Thekkady </h6>
        <p>
          After breakfast check out from Rajakkad estate and drive to Thekkady
          (140 KM), where the famous Periyar Tiger Reserve forest is located.
          Visit the Periyar Wild life Sanctuary (optional: go for a wildlife
          cruise to view a large variety of wild animal like Wild Elephants,
          Nilgiri Langur, Sambar Deer, Wild Bison, Wild Boar etc... and quite a
          lot of unique birds can also be spotted) Overnight stay at Thekkady
        </p>
        <h6>Day 10: Thekkady – Gavi </h6>
        <p>
          Proceed to Gavi (45 KM) and here you will be staying in a forest
          bungalow. Gavi is the major off-the-track destination ideal for a
          wilderness retreat replete with trekking, birding, canoeing and
          facilities for boarding. This biological garden, with hills and
          valleys, tropical forests, sprawling grass lands, sholas, cascading
          waterfalls and cardamom plantation, is home for many endangered
          species like lion tailed macaque; nilgiri tahrs etc. and they can be
          sighted also. Here you can get involved in jungle safari, trekking,
          pedal boating etc…. Stay overnight in the bungalow.
        </p>

        <h6>Day 11: Gavi - Kumarakom </h6>
        <p>
          After breakfast check out and drive to Paruthumpara (35 KM - Less than
          half hour), also known as grampi or eagle rock, offers a panoramic
          view of entire region. The view is mind blowing. Later proceed to
          Kumarakom (110 KM - 3 hours). Upon reaching embark on a houseboat.
          Enjoy backwater cruise through the paddy fields, coconut lagoons,
          narrow canals etc. If possible you can interact with local people,
          experience the local life. Enjoy a walk along the paddy fields. If
          required you can enjoy fishing (angling). If fortunate you can ask the
          chef to prepare it afresh for lunch or dinner. Overnight stay in house
          boat.
        </p>
        <h6>Day 12: Kumarakom – Kochi </h6>
        <p>
          After breakfast, dis embark from the houseboat and proceed to the
          heritage town Fort Kochi (70 KM - 2 hours). Visit Dutch Palace, Jewish
          Synagogue, Jew Street, Chinese fishing nets etc... Enjoy shopping in
          the Kashmiri shops here. Later you would be dropped at Kochi airport
        </p>
        <h4>Properties foreseen (Room category):</h4>
        <table>
          <thead>
            <tr>
              <th>Destination</th>
              <th>Property</th>
              <th>No.of.nights</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ooty</td>
              <td>Sherlock</td>
              <td>2</td>
            </tr>
            <tr>
              <td>Valparai</td>
              <td>Valley View Bungalow</td>
              <td>2</td>
            </tr>
            <tr>
              <td>Kodaikanal</td>
              <td>Tangerine Luxury Villa</td>
              <td>2</td>
            </tr>
            <tr>
              <td>Dindigul</td>
              <td>Rajakkad Estate</td>
              <td>2</td>
            </tr>
            <tr>
              <td>Thekkady</td>
              <td>Treetop</td>
              <td>1</td>
            </tr>
            <tr>
              <td>Gavi</td>
              <td>Periyar Tiger Reserve</td>
              <td>1</td>
            </tr>
            <tr>
              <td>Kumarakom</td>
              <td>Houseboat</td>
              <td>1</td>
            </tr>
          </tbody>
        </table>
      </div>
      <EnquiryForm/>
      <Footer/>
    </div>
  );
}

export default HillStations;
