import React, { useEffect } from 'react'

import CommonNav from "../CommonNav/CommonNav";
import Footer from '../Footer/Footer'
import goa from "../Assets/goa.webp";
import goa1 from "../Assets/goa1.webp";
import goa2 from "../Assets/goa2.jpg";
import goa3 from "../Assets/goa3.jpg";
import goa4 from "../Assets/goa4.webp";
import goa5 from "../Assets/goa5.jpg";
import EnquiryForm from '../EnquiryForm/EnquiryForm';

function BangaloreGoa() {
        useEffect(() => {
                window.scrollTo(0, 0);
              },[])
  return (
    <div className="Athira-wrapper">
      <CommonNav />
      <div className="athira-MainHeading">
        <h1>India / 12-Day Itineraries / South: Bangalore, Goa and Kerala </h1>
        <img className="athira-headingImg" src={goa} alt="" />
        <h6>Over to South</h6>
        <p>
        Embarking on a trip to Bangalore, Goa, and Kerala promises to be a thrilling adventure, filled with a kaleidoscope of diverse cultural experiences, mouth-watering cuisine, picturesque landscapes, and warm hospitality. From the vibrant cosmopolitan city of Bangalore, which is the tech hub of India, to the sandy beaches of Goa, known for its relaxed vibe, and the lush tropical forests, tranquil backwaters, and spice plantations of Kerala, there's something for everyone. This three-stop journey takes travelers on a mesmerizing tour of South India, immersing them in the sights, sounds, and flavors of this beautiful region.
        </p>
        <h4>Suggested itinerary</h4>
        <h6>Day 01:</h6>
        <img className="athira-headingImg" src={goa1} alt="" />
        <p>
        Upon arrival at Bangalore airport, our vehicle will transfer you to the hotel. Overnight stay at Bangalore.
        </p>
        <h6>Day 02:</h6>
        <img className="athira-headingImg" src={goa2} alt="" />
        <p>
        After breakfast, enjoy Bangalore sightseeing. English speaking guide will either join at hotel or else on the way at city. (Our driver will coordinate with him)
Priority 1 - Lal Bagh Garden, Tippu Summer Palace, ISKCON temple 
Priority 2 - Cubban park, Vidhana Soudha, Bangalore Palace
Drop back at hotel. <br />
 Please carry copy of passport; to obtain entry pass at certain monuments its required <br />
(Regret to inform that due to the worst traffic condition in Bangalore, we will try to cover as much as sightseeing possible). <br />
Overnight stay at Bangalore

        </p>
        <h6>Day 03:</h6>
        <img className="athira-headingImg" src={goa3} alt="" />
        <p>
        Our vehicle will drop you at Blore airport at appropriate time. BLR to GOA

Upon reaching Goa airport, our driver will pick you and drop at hotel
Free & easy – Vehicle available Overnight stay at Goa


        </p>
        <h6>Day 04:</h6>
        <img className="athira-headingImg" src={goa4} alt="" />
        <p>
        Enjoy guided sightseeing of South Goa
St Cathedral church, Panjim bazaar, Dona Paula bay & Basilica of Bom Jesus (FYI: The Feast of Saint Francis Xavier will be celebrated on the 3rd December 2022. So there would be big crowd and queue here. If you want to visit the guide will certainly accompany you)
Hindu temple, Miramar beach Stay at Goa

        </p>
        <h6>Day 05:</h6>
        <img className="athira-headingImg" src={goa5} alt="" />
        <p>
        Guided tour of Goa  <br />
Chapora Fort, Vagator Beach, Reise Magos Fort <br />
Overnight stay at Goa

        </p>
        <h6>Day 06:</h6>
        <p>
        Free and easy. No guide needed. <br />
Checkout time (12:00 noon) <br />
Vehicle required only for airport drop <br />
Drop at Goa airport at appropriate time <br />

Arrive at Cochin airport <br />
We will transfer you to hotel nearby airport. <br />
Stay at Cochin

        </p>
        <h6>Day 07: </h6>
        <p>
        After breakfast check out and proceed to Munnar. (3:30/4:00 hrs). Upon reaching Munnar, check into the hotel. If required, you can just go for a drive. Stay overnight at Munnar

        </p>
        <h6>Day 08:</h6>
        <p>
        Today you can enjoy Kolukkumalai jeep trip & trek. <br />

The Kolukkumalai tea estate in Munnar is the highest organic tea plantation and the oldest orthodox tea factory in the region. <br />

The jeep will pass through many scenic regions with amazing vistas. On your way to this tea estate you will have a beautiful and panoramic view of emerald green tea estates and the curvy hills of Munnar that just touch the low lying clouds. The last 10 kilometers of this bumpy jeep safari will be truly challenging and adventurous because this place has many sharp and narrow curves towards the end of this memorable trip. (including -  packed lunch & refreshments and also assistance of guide) <br />

Return to hotel after Kolukkumalai trek and jeep drive. Stay at Munnar


        </p>
        <h6>Day 09: </h6>
        <p>
        Guided sightseeing of Munnar – Tata Tea Museum, Eravikulam nationalpark, Mattupetty dam, Kundala lake, view points etc….  Stay in Munnar <br />
(Option: Enjoy lunch at Tea County)


        </p>
        <h6>Day 10: </h6>
        <p>
        After breakfast check out and drive to Kochi (135 KM – 4 hrs) Drop at hotel.
Free & easy. Vehicle available Overnight stay at Kochi

        </p>
        <h6>Day 11: </h6>
        <p>
        Drive to Cochin city  <br />
Mullapanthal toddy shop <br />
Vehicle available at disposal <br />
Overnight stay at Kochi

        </p>

        <h6>Day 12: </h6>
        <p>
        Vehicle is at disposal until drop at Cochin airport at appropriate time
        </p>
 
        <h6>Cost includes:</h6>
        <ul>
            <li>Accommodation</li>
            <li>Daily breakfast and dinner</li>
            <li>All meals in houseboat</li>
            <li>Transfer and sightseeing by AC tempo traveller (17 seat)</li>
            <li>Toll, parking, driver bata, inter-state permit</li>
            <li>All applicable taxes</li>
        </ul>
        <h6>Inclusions:</h6>
        <ul>
            <li>Accommodation</li>
            <li>Daily breakfast and dinner</li>
            <li>Guided sightseeing of Bangalore</li>
            <li>Guided sightseeing of Goa (as mentioned in itinerary) </li>
            <li>Kulukkumalai jeep trip and trek with guide at Munnar  </li>
            <li>Guided tour of Munnar  </li>
            <li>Entry fee to all sightseeing spots (Bangalore, Goa & Kerala)  </li>
            <li>AC vehicle for whole transportation in Bangalore, Goa and Kerala  </li>
            <li>Toll, parking, driver charges etc…  </li>
            <li>All applicable taxes  </li>

        </ul>
        
      </div>
      <EnquiryForm/>
      <Footer/>
    </div>
  )
}

export default BangaloreGoa