import React, { useEffect } from 'react'
import CommonNav from '../CommonNav/CommonNav'
import './Destination.css'
import goc from '../Assets/goc.jpg'
import bangalore from '../Assets/bangalore.png'
import MeenakshiTemple from '../Assets/Meenakshi Temple.png'
import tajMahal from '../Assets/tajMahal.webp'
import jammu2 from '../Assets/jammu2.png'
import manali from '../Assets/Manali_City.jpg'
import goaBeach from '../Assets/goaBeach.avif'
import Footer from '../Footer/Footer';

function Destinations() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <div className='Destinations-wrapper'>
    <CommonNav/>
    <div className="destination-cards">

      <div className="destinations-card">
      <a className='destinations-cardLink' href='/#/destination/GodsOwnCounry'>
      <img className='destinations-cardImg' src={goc} alt="" />
      <div className="destination-cardDescription">
          <h1>Kerala: Gods Own Country</h1>

        </div>
        
        
        </a>
      </div>

      <div className="destinations-card">
      <a className='destinations-cardLink' href='/#/BangaloreKarnataka'>
      <img className='destinations-cardImg' src={bangalore} alt="" />
      <div className="destination-cardDescription">
          <h1>Bangalore and Karnataka</h1>
        </div>   
        </a>
      </div>

      <div className="destinations-card">
      <a className='destinations-cardLink' href='/#/ChennaiTamilNadu'>
      <img className='destinations-cardImg' src={MeenakshiTemple} alt="" />
      <div className="destination-cardDescription">
          <h1>Chennai and Tamil Nadu</h1>
        </div>   
        </a>
      </div>

      <div className="destinations-card">
      <a className='destinations-cardLink' href='/#/GoaDestination'>
      <img className='destinations-cardImg' src={goaBeach} alt="" />
      <div className="destination-cardDescription">
          <h1>Goa</h1>
        </div>   
        </a>
      </div>


      <div className="destinations-card">
      <a className='destinations-cardLink' href='/#/GoldenTriangle'>
      <img className='destinations-cardImg' src={tajMahal} alt="" />
      <div className="destination-cardDescription">
          <h1>Golden Triangle</h1>
        </div>   
        </a>
      </div>

      <div className="destinations-card">
      <a className='destinations-cardLink' href='/#/SrinagarJammu'>
      <img className='destinations-cardImg' src={jammu2} alt="" />
      <div className="destination-cardDescription">
          <h1>Srinagar and Jammu Kashmir</h1>
        </div>   
        </a>
      </div>

      <div className="destinations-card">
      <a className='destinations-cardLink' href='/#/ShimlaManali'>
      <img className='destinations-cardImg' src={manali} alt="" />
      <div className="destination-cardDescription">
          <h1>Shimla, Manali, and Chandigarh</h1>
        </div>   
        </a>
      </div>



    

</div>
     <Footer/>
    </div>
  )
}

export default Destinations