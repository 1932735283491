import React, { useEffect } from 'react'

import jammu1 from '../Assets/jammu1.png'
import jammu2 from '../Assets/jammu2.png'
import jammu3 from '../Assets/jammu3.png'


import CommonNav from "../CommonNav/CommonNav";
import Footer from '../Footer/Footer'
import EnquiryForm from '../EnquiryForm/EnquiryForm'

function SrinagarJammu() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <div className="Athira-wrapper">
    <CommonNav />
    <div className="athira-MainHeading">
      <h1>India /Srinagar and Jammu Kashmir </h1>
      <p>
      Srinagar is a popular tourist destination located in the northern state of Jammu and Kashmir. It is a
beautiful valley nestled in the Himalayan mountain ranges and is known for its picturesque landscapes,
serene lakes, and vibrant culture. Here are some of the top tourist attractions in Srinagar:

      </p>
      <p>
      Srinagar is located in the heart of the Kashmir valley at an altitude of 1,730 m (1530 feet) above sea
level, spread on both sides of the river Jhelum. The Dal and Nigin lakes enhance its picturesque setting,
while the changing play of the seasons and the salubrious climate ensures that the city is equally attractive
to visitors around the year.

      </p>
      <img className="athira-headingImg" src={jammu1} alt="" />
      <p>
      Sonamarg. Situated at an altitude of 9000 Ft. Sonamarg – 84 Kms is also called as “The meadow of Gold”
has, as its backdrop, snowy mountains against a cerulean sky. The Sindh River that meanders through the
valley abounds with trout and Mahseer.
      </p>
      <p>
      Pahalgam, situated at the confluence of the streams flowing from Sheshnag Lake and the Lidder River,
Pahalgam (2,130 m) was once a humble shepherd&#39;s village with several breathtaking views. Pahalgam is
also associated with the annual Amarnath Yatra. (Hindu pilgrim tour)
      </p>
      <img className="athira-headingImg" src={jammu2} alt="" />
      <p>
      Gulmarg, called as “Meadow of Flowers”, is 53 Kms from Srinagar at an altitude of 8700 – ft. Gulmarg
also called “Switzerland of India”. Gulmarg&#39;s legendary beauty, prime location and proximity to Srinagar
naturally make it one of the premier destination in the country.
      </p>
      <img className="athira-headingImg" src={jammu3} alt="" />
      
    </div>
    <EnquiryForm/>
    <Footer/>
  </div>
  )
}

export default SrinagarJammu