import React from 'react'
import './Navbar.css'
import logo from '../Assets/logo.svg'
import { Link } from 'react-router-dom'
function NavBar() {
  function handlemenu(){
    const menu=document.getElementsByClassName('nav-menus')[0]
    menu.classList.toggle('active')  
    }
  return (
    <div className='nav-wraper'>
        <div className="logo">
            <img className='logoimg' src={logo} alt="" />
        </div>
        <div  className='nav-menus' >
            <ul className="Nav-menu">
                <li><Link to='/'>HOME</Link></li>
                <li><Link to='/destination'>DESTINATION</Link></li>
                <li><Link to='/gallery'>GALLERY</Link></li>
                <li><Link to='/AboutUs'>ABOUT US</Link></li>
                <li><Link to='/Iteneraries'>ITINERARY</Link></li>
                <li><Link to='/Activities'>ACTIVITIES</Link></li>
                <li><Link className='nav-contactButton' to='/contact'>CONTACT US</Link></li>
            </ul>
        </div>
        <div onClick={handlemenu} className="nav-toggle-button">
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
    </div>
  )
}

export default NavBar