import React, { useEffect } from "react";
import CommonNav from "../CommonNav/CommonNav";
import EnquiryForm from "../EnquiryForm/EnquiryForm";
import Footer from '../Footer/Footer'
import bangalore from '../Assets/Bangalore.jpg'

function OverToSouth() {
    useEffect(() => {
        window.scrollTo(0, 0);
      },[])
  return (
    <div className="Athira-wrapper">
      <CommonNav />
      <div className="athira-MainHeading">
        <h1>India / 11-Day Itinerary / Over to South </h1>
        <h4>Suggested itinerary</h4>
        <h6>Day 01:</h6>
        <p>
        Upon arrival at Bangalore airport, our vehicle will transfer you to the hotel. Overnight stay at Bangalore.
        </p>
        <img src={bangalore} alt="" />
        <h6>Day 02:</h6>
        <p>
        After breakfast, enjoy Bangalore sightseeing. English speaking guide will either join at hotel or else on the way at city. (Our driver will coordinate with him)
Lal Bagh Garden, Tippu Summer Palace, ISKCON temple 
Cubban park, Vidhana Soudha, Bangalore Palace
Drop back at hotel.
Please carry copy of passport; to obtain entry pass at certain monuments its required
Overnight stay at Bangalore

        </p>
        <h6>Day 03:</h6>
        <p>
        Our vehicle will drop you at Blore airport at appropriate time. BLR to GOA <br/>
Upon reaching Goa airport, our driver will pick you and drop at hotel
Free & easy. Overnight stay at Goa


        </p>
        <h6>Day 04:</h6>
        <p>
        Enjoy guided sightseeing of South Goa. Can start at 9 am and end by 5 pm
St Cathedral church, Panjim bazaar, Dona Paula bay & Basilica of Bom Jesus 
(Optional: Hindu temple, Miramar beach) Stay at Goa

        </p>
        <h6>Day 05: </h6>
        <p>
        Guided tour of Goa (Start Time: 10:00 AM - End Time: 17:00 PM) <br/>
Chapora Fort, Vagator Beach <br/>
Reise Magos Fort. Keep vehicle until 5 pm <br/>
Overnight stay at Goa


        </p>
        <h6>Day 06:</h6>
        <p>
        Free and easy. No guide needed. <br />
Vehicle required only for airport drop <br />
Drop at Goa airport at appropriate time (Reach Cochin on same day) <br />
Upon reaching Cochin airport, we will take you to a hotel nearby Cochin airport. <br />
After breakfast or after relaxing some time, we will transfer you to Munnar. (3:30/4:00 hrs). Upon reaching Munnar, check into the hotel. If required (if there is time), you can just go for a drive. Stay overnight at Munnar

        </p>
        <h6>Day 07: </h6>
        <p>
        Today you can enjoy Kolukkumalai jeep trip &amp; trek. <br />
The Kolukkumalai tea estate in Munnar is the highest organic tea plantation and the oldest
orthodox tea factory in the region. <br />
The jeep will pass through many scenic regions with amazing vistas. On your way to this tea
estate you will have a beautiful and panoramic view of emerald green tea estates and the curvy
hills of Munnar that just touch the low lying clouds. The last 10 kilometers of this bumpy jeep
safari will be truly challenging and adventurous because this place has many sharp and narrow
curves towards the end of this memorable trip. (including - packed lunch &amp; refreshments and
also assistance of guide) <br />
Return to hotel after Kolukkumalai trek and jeep drive. Stay at Munnar
        </p>
        <h6>Day 08:</h6>
        <p>
        Guided sightseeing of Munnar – Tata Tea Museum, Eravikulam nationalpark, Mattupetty dam,
Kundala lake, view points etc…. Stay in Munnar <br />
(Option: Enjoy lunch at Tea County – settle directly)

 

        </p>
        <h6>Day 09:</h6>
        <p>
        After breakfast check out and drive to Kochi (135 KM – 4 hrs) Drop at hotel.
Free &amp; easy. Vehicle available Overnight stay at Kochi


        </p>
        <h6>Day 10:</h6>
        <p>
        Enjoy whole day sightseeing of heritage town – Fort Kochi and Mattancherry. Guided
sightseeing of ancient monuments here. <br />
(Optional: Mullapanthal toddy shop visit) <br />
Vehicle available at disposal <br />
Overnight stay at Kochi


        </p>

        <h6>Day 11:</h6>
        <p>
        Vehicle is at disposal until drop at Cochin airport at appropriate time
        </p>

        <h6>Properties suggested</h6>
        <p>Bangalore - Radisson Bengaluru City Center (Superior City View) <br />
Goa - Country Inn &amp; Suites by Radisson (Deluxe Room – Pool View) <br />
Cochin - Flora Airport Hotel (Executive Club) <br />
Munnar - Spicetree (Classic Cottage) <br />
Cochin –Casino Hotel (Superior)</p>
       
        <h6>Inclusions:</h6>
        <ul>
            <li>Accommodation</li>
            <li>Daily breakfast</li>
            <li>Guided sightseeing of Bangalore </li>
            <li>Guided sightseeing of Goa (as mentioned in itinerary)</li>
            <li>Kulukkumalai jeep trip and trek with guide at Munnar </li>
            <li>Guided tour of Munnar </li>
            <li>Entry fee to all sightseeing spots (Bangalore, Goa & Kerala)</li>
            <li>AC vehicle for whole transportation in Bangalore, Goa and Kerala</li>
            <li>Toll, parking, driver charges etc…</li>
            <li>All applicable taxes</li>
            
        </ul>


        <h6>Please note:</h6>
        <ul>
            <li>In case of any political strike or natural calamity during the tour, we would not refund/compensate</li>
        </ul>

        <h6>Important:</h6>
        <p>As per the government directives it is mandatory for the entire guest to show their photo ID proof at the time of check-in. 
        <ul>
            <li>For Indian citizens ID can be in form of driving license, voter ID card, aadhaar card and
pass port.
</li>
            <li>For foreign nationals & NRI's, passport with valid visa/resident permit is mandatory failing which the hotel reserves the right of refusal.
</li>
        </ul>
        </p>
        
      </div>
      <EnquiryForm/>
      <Footer/>
    </div>
  )
}

export default OverToSouth