import React, { useEffect } from 'react'

import CommonNav from "../CommonNav/CommonNav";
import EnquiryForm from '../EnquiryForm/EnquiryForm';
import Footer from '../Footer/Footer'

import kashmir1 from '../Assets/Kashmir1.png'
import kashmir2 from '../Assets/kashmir2.png'
import kashmir3 from '../Assets/kashmir3.png'
import kashmir4 from '../Assets/kashmir4.png'
import kashmir5 from '../Assets/kashmir5.png'
import kashmir6 from '../Assets/kashmir6.png'
import kashmir7 from '../Assets/Kashmir7.png'
import kashmir8 from '../Assets/kashmir8.png'
import kashmir9 from '../Assets/kashmir9.png'
import kashmir10 from '../Assets/kashmir10.png'

function Kashmir() {
      useEffect(() => {
            window.scrollTo(0, 0);
          },[])
  return (
    <div className="Athira-wrapper">
    <CommonNav />
    <div className="athira-MainHeading">
      <h1>India / 8-Day Itinerary / Kashmir </h1>
<img src={kashmir1} alt="" />
      <h4>Suggested itinerary</h4>
      <h6>DAY 01: Arrival Srinagar</h6>
      <p>
      Upon arrival our representative will meet you at the Srinagar Airport and transfer you to Hotel. <br />
<span className='wordHighlight'>Srinagar</span>  is located in the heart of the Kashmir valley at an altitude of 1,730 m (1530 feet)
above sea level, spread on both sides of the river Jhelum. The Dal and Nigin lakes enhance its
picturesque setting, while the changing play of the seasons and the salubrious climate ensures
that the city is equally attractive to visitors around the year.After Lunch you will visit the
Mughal gardens. With terraced lawns, cascading fountains, paint-box-bright flowerbeds with
the panorama of the Dal in front of them - the three Mughal Gardens of Chesmashahi, Nishat
and Shalimar are the Mughal Emperors&#39; concept of paradise and are today very popular places. <br />
<img src={kashmir2} alt="" />
<span className='wordHighlight'>Shalimar Bagh:</span> Built by Emperor Jehangir for his wife Nur Jehan, Shalimar Bagh, 15 Kms from
the TRC, is a beautiful garden with sweeping vistas over gardens and lakes, and shallow
terraces. The garden is 539 m by 182 m and has four terraces, rising one above the other. A
canal lined with polished stones and supplied with water from Harwan runs through the middle
of the garden. The fourth terrace, by far the best, was once reserved for royal ladies. <br />
<img src={kashmir3} alt="" />
<span className='wordHighlight'>Nishat Bagh:</span> Situated on the banks of the Dal Lake, with the Zabarwan Mountains as its
backdrop, (11 km. from TRC), this &#39;garden of bliss&#39; commands a magnificent view of the lake
and the snow capped Pir Panjal mountain range which stands far away to the west of the valley.
Nishat was designed in 1633 AD by Asif Khan, brother of Nur Jehan. <br />
<img src={kashmir4} alt="" />
<span className='wordHighlight'>Chashma Shahi</span> (&quot;Royal Spring&quot;) received its name from its water source, a spring located on
its uppermost terrace. The garden, one of the smaller Kashmiri gardens around Dal Lake, is
found on the southeastern side of the lake. Chashma Shahi was built by the Mughal governor Ali
Mardan Khan under the emperor Shah Jehan in 1632-1633.While returning from Mughal <br />
<img src={kashmir5} alt="" />
Gardens visit <span className='wordHighlight'> Shankarachariya Temple</span>. <br />
The Shankarachariya Temple, also known as the Jyesteshwara temple orPas-Pahar by Buddhists,
is situated in the  Zabarwan Mountain  in  Srinagar ,  Kashmir . It is dedicated to Lord  Shiva . The
temple is on the summit of the same name at a height of 1,000 feet (300 m) above the plain
and overlooks the city of  Srinagar . The hill is also referred to asTakht i Suleiman (Throne of
Solomon) and the temple also called &quot;Throne of Solomon.&quot; It is believed that King Solomon
came to worship on the hill which makes the place sacred from ancient times. Overnight Stay at
Srinagar
      </p>
      <h6>DAY 02: Srinagar - Sonmarg - Srinagar</h6>
      <p>
      After early breakfastFull day excursion to Sonamarg.
Situated at an altitude of 9000 Ft. Sonamarg – 84
Kms is also called as “The meadow of Gold” has, as
its backdrop, snowy mountains against a cerulean
sky. The Sindh River that meanders through the
valley abounds with trout and Mahseer. Stop at
Sonamarg Glacier Restaurant for lunch on your own. <br />
The Sindh River that meanders through the valley
abounds with trout and mahaseer. Ponies can be
hired for the trip up to Thajiwas glacier, which is a
major local attraction during the summer months.
Sonamarg is the base of a major trek that passes
along several mountain lakes –Vishensar, Kishensar,
Gadsar, Satsar and Gangabal. Sonamarg is also the takeoff station for the drive to Ladakh
across the Zojila, a major pass in the Great Himalayan Range, through which the Srinagar-Leh
Road passes.Sonamarg is also a base for undertaking the yatra to the holy Amarnath cave,
during Sawan Purnima. For details about the yatra, refer to the Amarnathji Yatra Link. Overnight
stay at Srinagar
      </p>
      <h6>DAY 03: Srinagar - Pahalgam</h6>
      <p>
      After breakfast you will be driven to Pahalgam. <br />
      <img src={kashmir6} alt="" />
      Pahalgam, situated at the confluence of the streams flowing from Sheshnag Lake and the Lidder
River, Pahalgam (2,130 m) was once a humble shepherd&#39;s village with breathtaking views. Now
it is Kashmir&#39;s premier resort, cool even during the height of summer when the maximum
temperature does not exceed 25 0 C. A number of hotels and lodges cater to all preferences and
budgets, from luxurious hotels to unpretentious trekkers&#39; lodges. Around Pahalgam are many
places of interest, and because the resort is set between fairly steep hills, it is worth hiring a
pony rather than walking. Pony fares are posted at prominent locations.
The most beautiful of these is the huge, undulating meadow of Baisaran, surrounded by thickly
wooded forests of pine. Hajan, on the way to Chandanwari, is an idyllic spot for a picnic.
Filmgoers will recognize it instantly as it has been the location of several movie scenes.  <br />
<img src={kashmir7} alt="" />
Pahalgam is also associated with the annual Amarnath Yatra. Chandanwari (2,895 m), 16
kms from Pahalgam, is the starting point of the yatra that takes place every year in the month
of Sawan (July to August). The road from Pahalgam to Chandanwariis on fairly flat terrain and
can be undertaken by car. From Chandanwarionwards the track becomes much steeper, and is
accessible on foot or by pony. About 11 kms from Chandanwariis the mountain lake of
Sheshnag (3,574 m), after which, 13 kms away is the last stop, Panchtarni.
TheAmarnathcave is 6 kms away from there. During the month of Sawan, an ice stalagmite
forms a natural shivling in the Amarnath cave, which waxes and wanes with the moon.
Overnight stay at Pahalgam.

      </p>
      <h6>DAY 04: Pahalgam</h6>
      <p>
      In Pahalgam, free day at Leisure for Independent activities to explorer surroundings. <br />
      After breakfast, you will visit to Aru, Chandanwari &amp; Betaab Valley (at your own cost) <br />
      <img src={kashmir8} alt="" />
      <span className='wordHighlight'>Aru</span> is a tourist spot in the  Anantnag District  of  Jammu &amp; Kashmir , India. It is located around 12
from  Pahalgam , 11 km upstream from the  Lidder River . The Aru valley is noted for its
scenic  meadows .It is popular among the tourists for its peaceful environment and scenic beauty.
The village is a base camp for trekkers to the Kolahoi Glacier, the Tarsar-Marsar lakes and the
Katrinag valley. There are about 20 alpine lakes, peaks and meadows around the Aru Valley. In
winters, when Aru receives heavy snowfall,  skiing  and heliskiing  are practiced. Other popular tourist activities include fishing of  trout  in the Lidder River, trekking, hiking, horse riding,
sightseeing and photography. <br />
<span className='wordHighlight'>Betaab valley</span> is situated at a distance of 15 kilometers from  Pahalgam  in  Anantnag district
of  Jammu and Kashmir . Betaab Valley lies between the two Himalayan Ranges –  Pir
Panjal  and  Zanskar . The valley got its name from the  Sunny Deol - Amrita Singh  hit debut
film  Betaab . The Valley is towards northeast of  Pahalgam  and falls between Pahalgam and
Chandanwari and is enroute  Amarnath Temple  Yatra. The valley is surrounded by lush green
meadows, snow clad  mountains  and covered with dense vegetation. Betaab Valley is a very
popular touristic destination. Betaab Valley is also a favorite campsite of travelers as it also
serves as a base camp for trekking and further exploration of the mountains.The valley is a
walk-able distance from Pahalgam. The crystal clear &amp; chilly water of the stream bubbling down
from the snowy hills is a delight; locals here drink this water too. Baisaran and Tulyan Lake are
few nearby attractions that can be visited. <br />
<span className='wordHighlight'>Chandanwari</span> situated 16 Kms from Pahalgam, is the starting point for the famous Amarnath
Yatra. It is also famous for snow sledging on Snow Bridge. At Chandanwari the Sheshnag River
is captivating with its sheer force and clear waters. Overnight stay at Pahalgam


      </p>
      <h6>DAY 05: Pahalgam – Gulmarg</h6>
      <img src={kashmir9} alt="" />
      <p>
      After breakfast drive to Gulmarg via Srinagar Bye-Pass. Gulmarg, called as “Meadow of Flowers”
with picnic lunch. Gulmarg is 53 Kms from Srinagar at an altitude of 8700 – ft. Gulmarg also
referred as “Switzerland of India” is a famous Ski Resort &amp; the world’s highest 18 hole Golf
Course. The place was prominent during the British time. Gulmarg&#39;s legendary beauty, prime
location and proximity to Srinagar naturally make it one of the premier hill resorts in the
country. Originally called ‘Gaurimarg’ by shepherds, its present name was given in the 16th
century by Sultan Yusuf Shah, who was inspired by the sight of its grassy slopes emblazoned
with wild flowers. Gulmarg was a favourite haunt of Emperor Jehangir who once collected 21
different varieties of flowers from here. Today Gulmarg is not merely a mountain resort of
exceptional beauty- it also has the highest green golf course in the world, at an altitude of 2,650
m, and is the country&#39;s premier ski resort in the winter.

      </p>
      <h6>DAY 06: Gulmarg</h6>
      <p>
      In Gulmarg, free day at Leisure for Independent activities to explorer surroundings.
After lunch you can enjoy a pony ride and also enjoy a Gondola ride (cable car) to Khillanmarg
(at your own cost) <br />
<img src={kashmir10} alt="" />
<span className='wordHighlight'>Gulmarg Gondola</span>  in  Gulmarg ,  Jammu and Kashmir  is the world&#39;s second highest operating
cable car since the closure in 2008 of the  Mérida cable car  in Venezuela. Gulmarg boasts Asia&#39;s
highest and longest cable car project, the Gulmarg Gondola. The two-stage ropeway ferries
about 600 people per hour to and from Kongdoori Mountain, a shoulder of nearby Affarwat Peak
(4,200 m (13,780 ft)). The ropeway project is a joint venture of the Jammu and Kashmir
government and French firm Pomagalski. The first stage transfers from the Gulmarg resort at
2,600 m (8,530 ft) to Kongdoori Station in the bowl-shaped Kongdoori valley. The second stage
of the ropeway, which has 36 cabins and 18 towers, takes skiers to a height of 3,747 m
(12,293 ft) on Kongdoori Mountain, a shoulder of nearby Affarwat Peak (4,200 m (13,780 ft)).
The timing of the gondola is 10 AM (IST) to 5 PM (It&#39;s highly dependent upon the weather at
both stages). A gondola can carry six people at a time. Also offers Chair Cars for phase-II. It
takes approximately 9 minutes to reach the first stage and 12 minutes for second stage.
Overnight stay at Gulmarg
      </p>
      <h6>DAY 07: Gulmarg - Srinagar </h6>
      <p>
      After breakfast you will be driven to Srinagar. Evening 1 Hour Boat Ride on Dal Lake / Nigeen
Lake by a gondola Boat called ”SHIKARA” witness the ride on lake, called Venice of India &amp;
experience the aquatic life of Srinagar. Also see the scenic extravaganza of the Kashmir and
enjoy the profusion of colors in the Paradise on Earth. While returning visit to the local crafts
market and see the fine carved hand-made articles from Paper Machine &amp; wood of a walnut.
Overnight stay at Srinagar (Houseboat)
      </p>
      <h6>DAY 08: Departure</h6>
      <p>
      Breakfast at your hotel. In time transfer from Hotel at Srinagar to Srinagar Airport to connect
flight for Onwards Destination
      </p>
  
      
    </div>
    <EnquiryForm/>
    <Footer/>
  </div>
  )
}

export default Kashmir