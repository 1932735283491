import React, { useEffect } from 'react'

import goaBeach from '../Assets/goaBeach.avif'


import CommonNav from "../CommonNav/CommonNav";
import Footer from '../Footer/Footer'
import EnquiryForm from '../EnquiryForm/EnquiryForm'

function GoaDestination() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <div className="Athira-wrapper">
    <CommonNav />
    <div className="athira-MainHeading">
      <h1>India /Goa </h1>
      <p>
      Goa, smallest state in the country, is located on the western coast of India. Goa has its prominence in the
global list of tourist destinations and is known for its beautiful beaches, vibrant nightlife, and unique
blend of Indian and Portuguese cultures.

      </p>
      <p>
      Goa was a Portuguese colony for over 400 years until it was annexed by India in 1961. The influence of
Portuguese culture can be seen in the state&#39;s architecture, cuisine, and religion, with Christianity being the
dominant religion in the region.

      </p>
      <img className="athira-headingImg" src={goaBeach} alt="" />
      <p>
      Goa is a popular tourist destination, particularly for its beaches such as Baga, Calangute, and Anjuna.
Visitors can enjoy water sports such as parasailing, jet skiing, and banana boat rides. The state also has
several historical landmarks such as the Basilica of Bom Jesus, a UNESCO World Heritage Site that
houses the remains of Saint Francis Xavier.
      </p>
      <p>
      Overall, Goa is a unique and fascinating destination that offers a blend of Indian and Portuguese cultures,
stunning beaches, delicious cuisine, and a vibrant atmosphere.
      </p>
      
    </div>
    <EnquiryForm/>
    <Footer/>
  </div>
  )
}

export default GoaDestination