import React, { useEffect } from 'react'

import kumbalangi from '../Assets/kumbalangiMulti.png'
import CommonNav from '../CommonNav/CommonNav'
import EnquiryForm from '../EnquiryForm/EnquiryForm'
import Footer from '../Footer/Footer'

function KumbalangiVillage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <div className="Athira-wrapper">
    <CommonNav />
    <div className="athira-MainHeading">
      <h1 style={{paddingBottom:'50px'}}>India / Kerala / HALF DAY TOUR OF KUMBALANGI VILLAGE : DAY WITH FISHERMEN</h1>
      <img src={kumbalangi} alt="" />
      <p>
      Proceed to Kumbalangi 13 kms away and half an hour drive from Cochin. It is
identified as the first Model fisheries and tourism village in India.Kumbalangi has
a vast stretch of backwaters connected by interlocking waterways to the whole of
Kerala. It is an ideal getaway location for tourists from all over the world. What
makes it more interesting to visit Kumbalangi is that it is a virgin landscape
unspoiled by excessive presence of tourists. Here you will find the calm, quite
and tranquil landscape with warm inhabitants who depend on nature for their
survival. A day in Kumbalangi can be a festival to remember...a treat for the eye
and the mind. Kumbalangi is rich with exotic local cuisines, boat building and
masonry, manually operated coir looms, fish farms etc. <br />
We will take a cruise in country boats to Kallanchery ,an Island in Kumbalangi
which is about 700mtrs length and 150 mtrs in breadth. There are around 500
people living there. Most of the people are engaged in fishing and clam meat
processing for their livelihood, the Island is surrounded by Chinese fishing nets .
The villagers are engaged in agriculture, fishing, crab &amp; fish farming, coir making
etc. Main crops cultivated here are coconut and betel nuts, nutmegs, vanilla,
anthurium farm, and trees like teakwood, manchium, spices like pepper,
pathimugham etc. In the aquaculture farm they cultivate shrimps, crabs, milk
fishes, pearl spots and mullets etc. . . . You can also see small fresh water ponds
and narrow water channels conserved for years in this farm. You are later led to
the host family house where a traditional Kerala Non vegetarian lunch is waiting
to tickle Your taste buds .
      </p>
    
      <h6>STOPOVER</h6>
      <ul>
          <li>Leave for Kumbalangi village.</li>
          <li>Arrive and board country boats for a cruise watching fishing activities like
casting of nets and groping of fishes .</li>
          <li>Watch various activities like crab catching, clam meat processing , coconut
processing , coir yarn processing ,coconut leaves weaving ,Chinese net
operation .</li>
          <li>Non Vegetarian traditional Kerala Lunch.</li>
          <li>Depart the island</li>
      </ul>

      <h6>Note:</h6>
      <ul>
          <li>Schedule and duration of tour subject to change according to local
circumstances</li>
          <li>Smoking is not allowed in any of the monuments, public places and
vehicles.</li>
          <li>The tour involves mild walking under the Sun.We recommend visitors to
use hats , sunscreen lotion ,loose cotton attire etc</li>
          <li>Country canoes are non air conditioned, without shades and does not
have toilets. </li>
<li>Canoes are not recommended for wheel chair / physically challenged
passengers.</li>
<li>No scheduled stops anywhere enroute during the cruise</li>
<li>Cruise duration subject to change depending on Low/High tides and water
level in the canals.</li>
<li>Basic Toilet facility is available at the lunch venue .</li>
<li>Suggest to carry mosquito repellent lotion etc...since backwaters have
more mosquitoes.</li>
<li>Visitors are expected to wear decent dress / not revealing during village
visit.</li>
<li>At the lunch venue it is not allowed to serve alcoholic drinks violation of
which can result in penalty / imprisonment.</li>
      </ul>
      
    </div>
    <EnquiryForm/>
    <Footer/>
  </div>
  )
}

export default KumbalangiVillage