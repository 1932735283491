import React from 'react'



import CommonNav from "../CommonNav/CommonNav";
import Footer from '../Footer/Footer'
import EnquiryForm from '../EnquiryForm/EnquiryForm'
import { useEffect } from "react";

function ShimlaManali() {
    useEffect(() => {
        window.scrollTo(0, 0);
      },[])
      return (
        <div className="Athira-wrapper">
        <CommonNav />
        <div className="athira-MainHeading">
          <h1>India /Shimla, Manali, and Chandigarh </h1>
          <p>
          Shimla, Manali, and Chandigarh are popular tourist destinations in Northern India and all these 
destinations have its own unique attractions and charm.

    
          </p>
          <p>
          Shimla is the capital of Indian state of Himachal Pradesh. It is known for its colonial architecture, scenic 
beauty, and pleasant climate. Key attractions in Shimla include the Mall Road, Ridge, Christ Church, 
Jakhu Temple, Kufri, and Summer Hill.
    
          </p>
          <p>
          Manali is a hill station located in the Kullu district of Himachal Pradesh. It is situated in the Beas River 
Valley and is surrounded by majestic mountains and beautiful landscapes. Manali is famous for its 
picturesque views, adventure sports, and religious sites. Attractions in Manali include Rohtang Pass, 
Solang Valley, Hadimba Temple, Vashisht Hot Water Springs, and Old Manali.
          </p>
          <p>
          Chandigarh is a well-planned city and serves as the capital of both Punjab and Haryana states in India. It 
is known for its modern architecture, urban design, and wide roads. Chandigarh is home to several 
noteworthy landmarks such as the Rock Garden, Sukhna Lake and Rose Garden. The city offers a blend 
of urban amenities and green spaces, making it a popular destination for both business and leisure 
travelers.
          </p>
          <p>
          These three destinations are often included in a popular tourist circuit in Himachal Pradesh. Visitors often 
start their journey from Chandigarh and then proceed to Shimla and Manali. The proximity of these 
places allows for easy travel and exploration of the beautiful landscapes, cultural heritage, and adventure 
activities that the region has to offer. 
          </p>
          
        </div>
        <EnquiryForm/>
        <Footer/>
      </div>
      )
}

export default ShimlaManali