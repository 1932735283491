import React, { useEffect } from 'react'

import CommonNav from "../CommonNav/CommonNav";
import EnquiryForm from '../EnquiryForm/EnquiryForm';
import Footer from '../Footer/Footer'

function ACVA() {
    useEffect(() => {
        window.scrollTo(0, 0);
      },[])
  return (
    <div className="Athira-wrapper">
    <CommonNav />
    <div className="athira-MainHeading">
      <h1>India / 5-Day Itineraries / Alleppey,Cochin,Vagamon & Athirapally </h1>
      <h4>Suggested itinerary</h4>
      <h6>Day 1: Arrive at Alleppey railway station.</h6>
      <p>
      Upon reaching check into a hotel (check in at 2 pm).
Alleppey is famous for backwaters, the key attraction in Kerala.
During the day enjoy a motorboat ride for 3 hrs. In the evening our driver will take you to
Alleppey beach. Stay at Alleppey.
      </p>
      <h6>Day 2: Drive to Cochin</h6>
      <p>
      After breakfast check out and drive to Cochin (65 KM – 1.30 hrs). Visit Chottanikkara
temple (15 KM). Later visit Marine drive, Lulu mall, enjoy shopping at Broadway etc.... Stay at
Cochin
      </p>
      <h6>Day 3: Cochin to Vagamon</h6>
      <p>
      Cochin to Vagamon –(100 KM - 3:30 hrs). After breakfast check out from the hotel and
drive to Vagamon. Vagamon is a beautiful hill station. Enjoy the whole day sightseeing. Visit
pine forest. Stay at Vagamon.
      </p>
      <h6>Day 4: Vagamon to Athirapally</h6>
      <p>
      Vagamon to Athirapally (100 KM- 3 hrs). Upon reaching, check into hotel. After
freshening up, we will proceed to Athirapally waterfalls (1 hr), one of the largest waterfalls in
Kerala. Enjoy the whole day at this waterfall. Stay at Athirapally

      </p>
      <h6>Day 5: </h6>
      <p>
      Check out after breakfast. Our vehicle will drop you at railway station

      </p>
    
      <table>
          <thead>
              <tr>
                  <th>Destination</th>
                  <th>Nights</th>

              </tr>
          </thead>
          <tbody>
              <tr>
                  <td>Alleppey</td>
                  <td>1</td>
              </tr>
              <tr>
                  <td>Cochin</td>
                  <td>1</td>
              </tr>
              <tr>
                  <td>Vagamon</td>
                  <td>1</td>
              </tr>
              <tr>
                  <td>Athirapally</td>
                  <td>1</td>
              </tr>
          </tbody>
      </table>
      <h6>Cost includes:</h6>
      <ul>
          <li>Accommodation</li>
          <li>Daily breakfast and dinner</li>
          <li>Motor boat ride at Alleppey (3 hrs)</li>
          <li>Camp fire at Vagamon</li>
          <li>Entire transportation and sightseeing by ac vehicle</li>
          <li>Jeep transfers arranged at Vagamon (as large coach do not have access to the resort
located on hilltop)</li>
<li>All applicable tax</li>
      </ul>
      <h6>Cost excludes:</h6>
      <ul>
          <li>Charges for any optional activities</li>
          <li>Tip, laundry, telephone charges etc....</li>
          <li>All other expenses which are not mentioned in the inclusions </li>
      </ul>

      <h6>Note:</h6>
      <ul>
          <li>At Vagamon, since the coach will not go up the hill, we will arrange jeep transfer to
reach the hotel and drive back.</li>
      </ul>
      
    </div>
    <EnquiryForm/>
    <Footer/>
  </div>
  )
}

export default ACVA