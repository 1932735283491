import React, { useEffect } from 'react'

import MeenakshiTemple from '../Assets/Meenakshi Temple.png'


import CommonNav from "../CommonNav/CommonNav";
import Footer from '../Footer/Footer'
import EnquiryForm from '../EnquiryForm/EnquiryForm'

function ChennaiTamilNadu() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <div className="Athira-wrapper">
    <CommonNav />
    <div className="athira-MainHeading">
      <h1>India /Chennai and Tamil Nadu </h1>
      <p>
      Chennai is the capital of Tamil Nadu, a state in the southern part of India that is known for its
rich culture, history, and natural beauty. The state is home to several popular tourist destinations,
and the capital city of Chennai is a major gateway for visitors.
      </p>
      <p>
      Chennai, also known as Madras, is the capital of Tamil Nadu and is a bustling metropolis that
offers visitors a mix of traditional and modern experiences. Some of the popular tourist
attractions in Chennai include the Marina Beach, Fort St. George, Kapaleeswarar Temple, and
the Government Museum.
      </p>
      <p>
      Apart from Chennai, Tamil Nadu offers several other popular tourist destinations such as:
      </p>
      <h6>Meenakshi Temple</h6>
      <p>
      Of the many attractions that make South India a cultural paradise on earth, Meenakshi Temple is
the most iconic and popular one. Located on the southern bank of the Vaigai River in Madurai,
this Hindu temple is an epitome of classic architecture with murals so gorgeous that it will take
your breath away.
      </p>
      <img className="athira-headingImg" src={MeenakshiTemple} alt="" /> 
      <h6>Mahabalipuram</h6>
      <p>
      A UNESCO World Heritage Site that is famous for its ancient rock-cut temples
and monuments.
      </p>
      <h6>Kanchipuram</h6>
      <p>
      Known as the &quot;City of a Thousand Temples,&quot; Kanchipuram is home to several
ancient temples that are known for their intricate architecture.

      </p>
      <h6> Pondicherry</h6>
      <p>
      A former French colony, Pondicherry is a charming coastal town that is known for
its colonial architecture and French cuisine.
      </p>
      <h6>Ooty</h6>
      <p>
      A popular hill station located in the Nilgiri Mountains, Ooty is known for its scenic
landscapes, tea plantations, and botanical gardens.
      </p>
      <h6>Kodaikanal</h6>
      <p>
      Another popular hill station, Kodaikanal is known for its pristine lakes, waterfalls,
and scenic views.
      </p>
      <h6>Thanjavur</h6>
      <p>
      Also known as Tanjore, Thanjavur is known for its ancient temples, including the
Brihadeeswarar Temple, which is a UNESCO World Heritage Site.
      </p>
      <br />
      <p>
      Apart from these destinations, Tamil Nadu is also known for its cuisine, which includes several
vegetarian and non-vegetarian dishes such as dosa, idli, sambar, and biryani. The state is also
famous for its silk sarees and handicrafts.
      </p>
    </div>
    <EnquiryForm/>
    <Footer/>
  </div>
  )
}

export default ChennaiTamilNadu