import React from "react";
import Footer from "../Footer/Footer";
import AboutUs from "./AboutUs/AboutUs";
import "./Home.css";
import LandingPage from "./LandingPage/LandingPage";
import NavBar from "./NavBar";
function Home() {
  return (
    <>
      <div className="home-wraper">
        <NavBar />
        <div className="home-content">
          <h1>JOURNEY TO</h1>
          <h1>EXPLORE INDIA</h1>
          <p>
            Learning Trails arrange travel packages across India. This family
            owned business has been catering to the travel requirements of
            several globetrotters since 2015. Our customized
            packages/itineraries include most of the tourist destinations in the
            country. Let the thirst for a relaxing, joyful, merry making
            excursion exist forever and we are ready to welcome more number of
            travellers. While travelling with LT, the guests not only enjoy a
            tour but an experience as well.We welcome you for an exciting
            cultural exploration trip to India !!!
          </p>
          <div>
            {/* <button>LEARN MORE</button>
            <button>BOOK NOW</button> */}
          </div>
        </div>
      </div>
      <LandingPage id='aboutUs' />
      <AboutUs />
      <Footer />
    </>
  );
}

export default Home;
