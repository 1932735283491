import React, { useEffect } from 'react'

import CommonNav from "../CommonNav/CommonNav";
import EnquiryForm from '../EnquiryForm/EnquiryForm';
import Footer from '../Footer/Footer'

function AKVK() {
    useEffect(() => {
        window.scrollTo(0, 0);
      },[])
  return (
    <div className="Athira-wrapper">
    <CommonNav />
    <div className="athira-MainHeading">
      <h1>India / 5-Day Itineraries / Alleppey, Kulamavu, Vagamon &amp; Kuttikanam </h1>
      <h4>Skelton itinerary</h4>
      <h6>Day 01:</h6>
      <p>
      Board the house boat at Alleppey. Enjoy all meals on board. Stay onboard
      </p>
      <h6>Day 02:</h6>
      <p>
      After breakfast, check out from the houseboat and drive to Kulamavu.
      </p>
      <h6>Day 03:Kulamavu to Vagamon</h6>
      <p>
Aug 22 (pm) or Aug 23 (am): You can enjoy a jeep ride to Idukki dam and return to the resort (Please ask the resort staff to arrange
jeep)

      </p>
      <h6>Day 04: Vagamon to Kuttikanam</h6>
      <p>
      Optional tour – Sightseeing trip to the nearby view point Paruthumpara, offering a mind blowing view, is surrounded by several
mountains covered with mist throughout the day. One can enjoy the chilling breeze here anytime of the year. (This spot has been
featured in Mammooty stared Malayalam movie ‘Nazrani’)
      </p>
      <h6>Day 05:Drive back </h6>

      <h6>Cost includes:</h6>
      <ul>
          <li>Accommodation for 04 nights</li>
          <li>Daily breakfast</li>
          <li>All food in houseboat</li>
      </ul>

      <h6>Cost excludes:</h6>
      <ul>
          <li>Transportation</li>
          <li>Charges for any optional activities, entry charges etc…</li>
          <li>Tip, laundry, telephone charges etc....</li>
          <li>All other meals and expenses which are not mentioned in the inclusions</li>
      </ul>

      <table>
          <thead>
              <tr>
                  <th>Destination</th>
                  <th>No.of.nights</th>
              </tr>
          </thead>
          <tbody>
              <tr>
                  <td>Alleppey</td>
                  <td>1</td>
              </tr>
              <tr>
                  <td>Kulamavu</td>
                  <td>1</td>
              </tr>
              <tr>
                  <td>Vagamon</td>
                  <td>1</td>
              </tr>
              <tr>
                  <td>Kuttikanam</td>
                  <td>1</td>
              </tr>
          </tbody>
      </table>
      
    </div>
    <EnquiryForm/>
    <Footer/>
  </div>
  )
}

export default AKVK