import React from "react";
import "./LandingPage.css";
// import landingPageMobileBg from '../Assets/LandingPageBgMobile.svg'
import indiaBg from "../../Assets/indiaBG.svg";
import indiaMain from "../../Assets/Map.png";
import locationIcon from "../../Assets/locationIcon.svg";

function LandingPage() {
  return (
    <div className="LandingPage-wrapper">
      {/* <img className='LandingPageMobileBg' src={landingPageMobileBg} alt="" /> */}
      <div className="LandingPage-contentMain">
        <div className="LandingPage-content1">
          <img className="indiaBg" src={indiaBg} alt="" />
          <img className="indiaMain" src={indiaMain} alt="" />
          <img className="locationIcon" src={locationIcon} alt="" />
          <div className="locationDescription"> Goa</div>
          <img className="locationIcon2" src={locationIcon} alt="" />
          <div className="locationDescription2"> Kochi</div>
          <img className="locationIcon3" src={locationIcon} alt="" />
          <div className="locationDescription3"> Chennai</div>
          <img className="locationIcon4" src={locationIcon} alt="" />
          <div className="locationDescription4"> Bangalore</div>
          <img className="locationIcon5" src={locationIcon} alt="" />
          <div className="locationDescription5"> Delhi</div>
          <img className="locationIcon6" src={locationIcon} alt="" />
          <div className="locationDescription6"> Jaipur</div>
          <img className="locationIcon7" src={locationIcon} alt="" />
          <div className="locationDescription7"> Agra</div>
          <img className="locationIcon8" src={locationIcon} alt="" />
          <div className="locationDescription8"> Srinagar</div>
          <img className="locationIcon9" src={locationIcon} alt="" />
          <div className="locationDescription9"> Lakshadweep</div>
          <img className="locationIcon10" src={locationIcon} alt="" />
          <div className="locationDescription10"> Andaman & Nicobar</div>
          <img className="locationIcon11" src={locationIcon} alt="" />
          <div className="locationDescription11"> Shimla & Manali</div>
          <img className="locationIcon12" src={locationIcon} alt="" />
          <div className="locationDescription12"> Chandigarh</div>
        </div>
        <div className="LandingPage-content">
          <p>
            " India is the cradle of the human race, the birthplace of human
            speech, the mother of history, the grandmother of legend, and the
            great grandmother of tradition. Our most valuable and most
            astrictive materials in the history of man are treasured up in India
            only!. " - Mark Twain
          </p>
          <p>
            Learning Trails arrange travel packages across India. While talking
            of arranging tours we organize leisure tours for families & friends,
            MICE (Meetings, Incentives, Conference and Exhibition) for
            corporates, study tour for students and also shore excursion for
            cruise passengers.
          </p>
          <p>
            Our success mantra is very simple; make sure that our guests
            (clients) should have not only a wonderful time while travelling but
            a new experience as well. This family owned business has been
            catering to the travel requirements of several globetrotters since
            2015.
          </p>
          <p>
         We provide memories worth keeping!!!!!!!!!!!! Learning Trails is promoted by a family of professional members scattered all over the globe. Eventually, we began a tradition where every reunion is accompanied by a vacation. So far, we’ve camped in forests, holidayed in bungalows, and accumulated many memories worth keeping. And now, we want to help other families do the same..
          </p>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
