import React, { useEffect } from "react";

import kumarakamFishing from "../Assets/kumarakamFishing.jpg";
import CommonNav from "../CommonNav/CommonNav";
import EnquiryForm from "../EnquiryForm/EnquiryForm";
import Footer from '../Footer/Footer'

function FishingKumarakam() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <div className="Athira-wrapper">
      <CommonNav />
      <div className="athira-MainHeading">
        <h1>India /Kerala: Guided fishing activity at Kumarakom </h1>
        <img className="athira-headingImg" src={kumarakamFishing} alt="" />
        <h6>Fishing at Kumarakom</h6>
        <p>
          Join with your guide in the morning and cruise in Kumarakom backwaters
          in a traditional canoe. Your guide will help you to get a traditional
          angling gear comprising a simple fishing rod, a fishing line and a
          hook. <br />
          Your guide will show and explain you the different other traditional
          methods for fishing using nets, bamboo traps, vellavali etc…
        </p>
        <h6>FISHING WITH NETS:</h6>
        <p>
          When fishing in shallow waters, the fishermen set up artificial reefs
          or bushes on the muddy bottom of the lake and canals and keep fish
          food (flour or tapioca pieces) on them. When the fish bite, the bushes
          tremble and the waiting fisherman can throw the net for a good catch.{" "}
          <br />
          This type of fishing is now a rarity in Kumarakom, However, catching
          fish by throwing food into the water and then trapping the fish with
          nets or bamboo traps is common. The three main types of nets used in
          the area are the koruvala (trawl net), the veesuvala (casting net) and
          the cheenavala (Chinese net).
        </p>
        <h6>FISHING WITH BAMBOO TRAPS: </h6>
        <p>
          Another common method of fishing in Kumarakom is bamboo trap fishing.
          This technique is good for small canals and paddy fields. <br />
          A kind of basket made of bamboo is used for this type of fishing. The
          basket has one conical closed end and a wide opening at the other.
          This trap device is called koodu (cage) by the natives. <br />
          The trap is placed in the narrowest area of running water. The part of
          the canal towards the back of the trap is blocked with twigs or mud.
          The fishes enter the trap through its wide mouth. They cannot escape
          as the other end is closed. The trap is then lifted from the waters
          for the catch. Sometimes, in paddy fields, the fishermen throw the
          fish food and when the fishes gather, they are caught by covering them
          with bamboo traps.
        </p>
        <h6>VELLAVALI (SEASONAL FISHING): </h6>
        <p>
          Vellavali, a method of fishing used to catch Karimeen (Pearl Spot) is
          common in Kumarakom. This method requires expertise in fishing and
          swimming. First, a piece of coir rope, about a km long, is shaped into
          an inverted U. Tender palm leaves are attached to the rope and then
          two fishermen tie the ends of the rope to their waists while standing
          in water in bright sunlight. The men then walk through shallow waters
          using poles. The palm leaves tied to their waists shine in the water.
          The light reflected from the leaves scares the Pearl Spot and they
          hide in the muddy areas. At this time, expert swimmers, who have been
          following the two men in country boats, dive into the muddy water and
          catch the prized fish with their bare hands. NOTE: Being VELLAVALI is
          a seasonal fishing, demonstration cannot be assured. But your guide
          will explain well
        </p>
      </div>
      <EnquiryForm/>
      <Footer/>
    </div>
  );
}

export default FishingKumarakam;
