import React, { useState }  from 'react'

import './GalleryPage.css'

import {
  MDBCarousel,
  MDBCarouselItem,
} from 'mdb-react-ui-kit';
import CommonNav from '../CommonNav/CommonNav';
import Footer from '../Footer/Footer';
import kochi1 from '../Assets/bolgatty_palace_kochi.jpg'
import kochi2 from '../Assets/bolgatty_palace_kochi1.jpg'
import kochi3 from '../Assets/bolgatty_palace_kochi2.jpg'
import kochi4 from '../Assets/bolgatty_palace_kochi3.jpg'
import kochi5 from '../Assets/cherai_beach_resort_kochi.jpg'
import kochi6 from '../Assets/ramada_hotel_kochi1.jpg'

import kumarakom1 from '../Assets/kumarakom/lake_resort_kumarakom1.jpg'
import kumarakom2 from '../Assets/kumarakom/lake_resort_kumarakom2.jpg'
import kumarakom3 from '../Assets/kumarakom/lake_resort_kumarakom3.jpg'
import kumarakom4 from '../Assets/kumarakom/lake-resort-kumarakom4.jpg'
import kumarakom5 from '../Assets/kumarakom/lake_resort_kumarakom5.jpg'
import kumarakom6 from '../Assets/kumarakam.jpg'

import alappuzha1 from '../Assets/alappuzha/abad_alappuzha.jpg'
import alappuzha2 from '../Assets/alappuzha/abad_alappuzha1.jpg'
import alappuzha3 from '../Assets/alappuzha/lake-palace-resort_alappuzha.jpg'
import alappuzha4 from '../Assets/alappuzha/spice_routes_alleppey.jpg'
import alappuzha5 from '../Assets/alappuzha/spice_routes_alleppey1.jpg'
import alappuzha6 from '../Assets/alappuzha/spice_routes_alleppey2.jpg'

import theakady1 from '../Assets/Theakady/carmelia_thekkady.jpg'
import theakady2 from '../Assets/Theakady/carmelia_tree_house_thekkady.jpg'
import theakady3 from '../Assets/Theakady/in_my_place_thekkady.jpg'
import theakady4 from '../Assets/Theakady/in_my_place_thekkady_2.jpg'
import theakady5 from '../Assets/teakady.png'
import theakady6 from '../Assets/theakady3.jpg'

import munnar1 from '../Assets/munnar1.jpg'
import munnar2 from '../Assets/Munnar/Blackberry_hills_munnar1.jpg'
import munnar3 from '../Assets/Munnar/Blackberry_hills_munnar2.jpg'
import munnar4 from '../Assets/munnar2.jpg'
import munnar5 from '../Assets/Munnar/sping_dale_munnar.jpg'
import munnar6 from '../Assets/teaPlantationPeriyar.jpeg'

import wayanad1 from '../Assets/vayanad/the-windflower-resort-wayanad.jpg'
import wayanad2 from '../Assets/vayanad/the-windflower-resort-wayanad1.jpg'
import wayanad3 from '../Assets/vayanad/vythiri-resort-wayanad.jpg'
import wayanad4 from '../Assets/vayanad/vythiri_resort_wayanad.jpg'
import wayanad5 from '../Assets/vayanad/vythiri_resort_wayanad1.jpg'
import wayanad6 from '../Assets/wayanad.jpg'

import kovalam1 from '../Assets/kovalam/leela-kovalam.jpg'
import kovalam2 from '../Assets/kovalam/soma-palmshore-kovalam.jpg'
import kovalam3 from '../Assets/kovalam/the_ocean_park_kovalam.jpg'
import kovalam4 from '../Assets/kovalam/thrutle-of-the-beach_kovalam.jpg'
import kovalam5 from '../Assets/kovalam/thrutle-of-the-beach_kovalam1.jpg'
import kovalam6 from '../Assets/kovalam.jpg'

import homestay1 from '../Assets/homeStay/peppercounty_home_stay.jpg'
import homestay2 from '../Assets/homeStay/peppercounty_home_stay2.jpg'
import homestay3 from '../Assets/homeStay/taravad_the_farm_house_home_stay.jpg'
import homestay4 from '../Assets/homeStay/taravad_the_farm_house_home_stay0.jpg'
import homestay5 from '../Assets/homeStay/taravad_the_farm_house_home_stay1.jpg'
import homestay6 from '../Assets/homestay1.jpg'


// import waterfall from '../Assets/desc/soochipara_waterfalls.jpg'
// import artCulture from '../Assets/desc/art_culture.jpg'
// import backwaters from '../Assets/desc/backwaters.jpg'
// import mosque from '../Assets/desc/bheemapally_mosque.jpg'
// import birdSanctury from '../Assets/desc/bird_sanctuary.jpg'
// import hillStation from '../Assets/desc/hill_station.jpg'
// import dam from '../Assets/desc/idukki_dam.jpg'
// import church from '../Assets/desc/champakulam_church.jpg'
// import pooram from '../Assets/desc/thrissur_pooram.jpg'
// import temple from '../Assets/desc/vadakunathan_temple.jpg'
// import wildlife from '../Assets/desc/wildlife.jpg'
// import beach from '../Assets/desc/varkala_beach.jpg'




function GalleryPage() {


  const Kochi=[
    {id:1, src:kochi1},
    {id:2, src:kochi2},
    {id:3, src:kochi3},
    {id:4, src:kochi4},
    {id:5, src:kochi5},
    {id:6, src:kochi6},
  ]
  const Kumarakom=[
    {id:1, src:kumarakom1},
    {id:2, src:kumarakom2},
    {id:3, src:kumarakom3},
    {id:4, src:kumarakom4},
    {id:5, src:kumarakom5},
    {id:6, src:kumarakom6},
  ]
  const Alappuzha=[
    {id:1, src:alappuzha1},
    {id:2, src:alappuzha2},
    {id:3, src:alappuzha3},
    {id:4, src:alappuzha4},
    {id:5, src:alappuzha5},
    {id:6, src:alappuzha6},
  ]
  const Teakady=[
    {id:1, src:theakady1},
    {id:2, src:theakady2},
    {id:3, src:theakady3},
    {id:4, src:theakady4},
    {id:5, src:theakady5},
    {id:6, src:theakady6},
  ]

  const Munnar=[
    {id:1, src:munnar1},
    {id:2, src:munnar2},
    {id:3, src:munnar3},
    {id:4, src:munnar4},
    {id:5, src:munnar5},
    {id:6, src:munnar6},
  ]

  const Wayanad=[
    {id:1, src:wayanad1},
    {id:2, src:wayanad2},
    {id:3, src:wayanad3},
    {id:4, src:wayanad4},
    {id:5, src:wayanad5},
    {id:6, src:wayanad6},
  ]
  const Kovalam=[
    {id:1, src:kovalam1},
    {id:2, src:kovalam2},
    {id:3, src:kovalam3},
    {id:4, src:kovalam4},
    {id:5, src:kovalam5},
    {id:6, src:kovalam6},
  ]

  const Homestay=[
    {id:1, src:homestay1},
    {id:2, src:homestay2},
    {id:3, src:homestay3},
    {id:4, src:homestay4},
    {id:5, src:homestay5},
    {id:6, src:homestay6},
  ]



  const [album,setAlbum]=useState(Kochi)
    console.log(album);
      
  return (
    <>
    <CommonNav/>
    <div className="Gallery-leftButtons">
      <button onClick={()=>setAlbum(Kochi)}>Kochi</button>
      <button onClick={()=>setAlbum(Kumarakom)}>Kumarakom</button>
      <button onClick={()=>setAlbum(Alappuzha)}>Alleppey</button>
      <button onClick={()=>setAlbum(Kovalam)}>Kovalam</button>
    </div>
    <div className="galleryAbumContainer">
    <div className="album">
        <MDBCarousel interval={2000} showControls>
        {album.map((albums)=>(
      <MDBCarouselItem
        className='w-100 d-block'
        style={{height:"30rem"}}
        itemId={albums.id}
        src={albums.src}
        alt='...'
      />
      ))}
    </MDBCarousel>
    </div>
    </div>
    <div className="Gallery-rightButtons">
      <button onClick={()=>setAlbum(Teakady)} >Thekkady</button>
      <button onClick={()=>setAlbum(Munnar)}>Munnar</button>
      <button onClick={()=>setAlbum(Wayanad)}>Wayanad</button>
      <button onClick={()=>setAlbum(Homestay)}>Homestay</button>
    </div>
    <div className='spacer'></div>
    {/* <div className="Gallery-heading">
      <h2>Tourist Attractions in Kerala</h2> <br />
      <div className="galleryDescription">
      <div className="gallery-descriptionsLeftSide">
      <div className="gallerydesAll">
        <div className="gallerydes">
          <img src={beach} alt="" />
          <p>Being a coastline State Kerala has a long western border with Arabian Sea. There are beaches of all types :- wet and wild hilly shores, long promenades, beaches adjoining backwaters and the sea .</p>
        </div>
        <div className="galleryDesPoints">
          <h4>Famous Beaches</h4>
          <ul>
                          <li>Kovalam</li>
                          <li>Varkala</li>
                          <li>Bakel</li>
                          <li>Alapuzha</li>
                          <li>Cherai</li>
                          <li>Kollam</li>
                          <li>Calicut</li>
                          <li>Kappad</li>
                      </ul> 
        </div>
        </div>
      </div>
      <div className="gallery-descriptionsLeftSide">
      <div className="gallerydesAll">
        <div className="gallerydes">
          <img src={backwaters} alt="" />
          <p>It is a network of rivers, lakes and inlets interconnected by canals forming 900 km of waterways on the west coast of South Kerala. 
                          A number of towns among this serve as points for starting and ending cruises.</p>
        </div>
        <div className="galleryDesPoints">
          <h4>Beauty of Backwaters</h4>
          <ul>
                          <li>Alapuzha</li>
                          <li>Kumarakom</li>
                      </ul> 
        </div>
        </div>
      </div>
      <div className="gallery-descriptionsLeftSide">
      <div className="gallerydesAll">
        <div className="gallerydes">
          <img src={hillStation} alt="" />
          <p>Kerala slopes from Western Ghats to Arabian Sea; as a result the eastern terrain is hilly and there are a number of evergreen hill stations amidst the ranges.</p>
        </div>
        <div className="galleryDesPoints">
          <h4>Top Hill Stations</h4>
          <ul>
                          <li>Munnar</li>
                          <li>Thekkady</li>
                          <li>Vagamon</li>
                          <li>Lakkidi</li>
                          <li>Gavi</li>
                          <li>Ponmudi</li>
                      </ul> 
        </div>
        </div>
      </div>
      <div className="gallery-descriptionsLeftSide">
      <div className="gallerydesAll">
        <div className="gallerydes">
          <img src={waterfall} alt="" />
          <p>Kerala also has some beautiful waterfalls across the state. 
                          Here are few of the most beautiful waterfalls which are worth a visit.</p>
        </div>
        <div className="galleryDesPoints">
          <h4>Beautiful Waterfalls</h4>
          <ul>
                          <li>Athirapally</li>
                          <li>Vazhachal</li>
                          <li>Palaruvi</li>
                          <li>Thommankuthu</li>    
                          <li>Soochipara</li>
                          <li>Aruvikkuzhi</li>                           
                      </ul> 
        </div>
        </div>
      </div>
      <div className="gallery-descriptionsLeftSide">
      <div className="gallerydesAll">
        <div className="gallerydes">
          <img src={dam} alt="" />
          <p>Dams are built for irrigation and hydro-electric projects, but they are also  beauty spots where visitors can enjoy the view and the various activities associated with reservoirs. 
                          There are comfortable lodgings, hotels and resorts for tourists nearby. There are 53 dams in Kerala.</p>
        </div>
        <div className="galleryDesPoints">
          <h4>Scenic Dam Reservoirs</h4>
          <ul>
          <li>Malampuzha Dam</li>
                          <li>Idukki Arch Dam</li>
                          <li>Parambikulam</li>
                          <li>Neyyar Dam</li>
                      </ul> 
        </div>
        </div>
      </div>
      <div className="gallery-descriptionsLeftSide">
      <div className="gallerydesAll">
        <div className="gallerydes">
          <img src={birdSanctury} alt="" />
          <p>A Bird Sanctuary is an ecologically significant zone. 
                          Several species of birds, both forest birds as well as the water birds, visit the sanctuaries.</p>
        </div>
        <div className="galleryDesPoints">
          <h4>Bird Sanctuaries</h4>
          <ul>
          <li>Thattekad</li>
                          <li>Kumarakom</li>
                          <li>Mangalavanam</li>
                          <li>Pathiramanal</li>                    
                      </ul> 
        </div>
        </div>
      </div>
      <div className="gallery-descriptionsLeftSide">
      <div className="gallerydesAll">
        <div className="gallerydes">
          <img src={wildlife} alt="" />
          <p> Nestled in the elaborate forest of the Western Ghats or Sahyadri Ranges in Kerala has a number of wildlife Sanctuaries.
                          Wildlife in Kerala ranges from tigers to wild elephants, bisons, deers and monkeys.</p>
        </div>
        <div className="galleryDesPoints">
          <h4>Wildlife Sanctuaries</h4>
          <ul>
          <li>Parambikulam</li>
                          <li>Thekkady</li>
                          <li>Periyar Tiger Reserve</li>
                          <li>Idukki</li>
                          <li>Wayanad</li>
                          <li>Chinnar</li>                         
                      </ul> 
        </div>
        </div>
      </div>
      <div className="gallery-descriptionsLeftSide">
      <div className="gallerydesAll">
        <div className="gallerydes">
          <img src={temple} alt="" />
          <p>There are many ancient temples  where devotees throng across the state. 
                          Here are few of the important temples in Kerala.</p>
        </div>
        <div className="galleryDesPoints">
          <h4>Prominent Temples</h4>
          <ul>
          <li>Sri Padmanabha Swamy Temple </li>
                          <li>Ettumanoor Mahadeva Temple </li>
                          <li>Srikrishna Temple at Guruvayoor</li>
                          <li>Chottanikara Temple</li>    
                          <li>Rajarajeswra Temple </li>
                          <li>Sabarimala Shastha </li>                      
                      </ul> 
        </div>
        </div>
      </div>
      <div className="gallery-descriptionsLeftSide">
      <div className="gallerydesAll">
        <div className="gallerydes">
          <img src={church} alt="" />
          <p>It has been considered that St.Thomas, one of the apostles of Christ had visited Kerala in 52 AD and that he introduced Christianity to India. </p>
        </div>
        <div className="galleryDesPoints">
          <h4>Famous Churches</h4>
          <ul>
          <li>Manarcad St.Mary&apos;s Jacobite Syrian Church</li> 
          <li>Malayattoor St.Thomas Church </li>
                          <li>Parumala Church </li>
                          <li>Niranam St.Thomas Church</li>
                          <li>Edappally St.George&apos;s Forane   </li>                         
                      </ul> 
        </div>
        </div>
      </div>
      <div className="gallery-descriptionsLeftSide">
      <div className="gallerydesAll">
        <div className="gallerydes">
          <img src={mosque} alt="" />
          <p>Kerala also has some ancient mosques with rich history and tradition. 
                          These mosques have also played an integral part in weaving the Kerala culture. </p>
        </div>
        <div className="galleryDesPoints">
          <h4>Important Mosques</h4>
          <ul>
          <li>Cheraman Juma Masjid</li>
                          <li>Kanjiramattom Mosque</li>
                          <li>Madayi Mosque</li>
                          <li>Pazhayangadi Mosque</li>   
                          <li>Beemapally Mosque</li>                          
                      </ul> 
        </div>
        </div>
      </div>
      <div className="gallery-descriptionsLeftSide">
      <div className="gallerydesAll">
        <div className="gallerydes">
          <img src={artCulture} alt="" />
          <p>The art and culture of Kerala portrays various dance forms and martial arts performed with the accompaniment of vocal and instrumental music. 
                          All these art forms are performed during some celebration or festivity.</p>
        </div>
        <div className="galleryDesPoints">
          <h4>Art and Culture</h4>
          <ul>
          <li>Kathakali</li>
                          <li>Kalaripayattu</li>
                          <li>Vallamkali</li>
                          <li>Thiruvathirakali</li>                            
                      </ul> 
        </div>
        </div>
      </div>
      <div className="gallery-descriptionsLeftSide">
      <div className="gallerydesAll">
        <div className="gallerydes">
          <img src={pooram} alt="" />
          <p>Kerala is a treasure trove of historical sites, architectural monuments and heritage centres that silently stand in majesty reflecting the glory of the past. 
                          And Ayurveda is a therapy that promises holistic wellness.</p>
        </div>
        <div className="galleryDesPoints">
          <h4>Traditions and Heritage</h4>
          <ul>
          <li>Ayurveda</li>
                          <li>Sadhya</li>
                          <li>Thrissur Pooram</li>
                          <li>Heritage Homes</li>                        
                      </ul> 
        </div>
        </div>
      </div>
      </div>
    </div> */}
    <Footer/>
    </>
  )
}

export default GalleryPage