import React, { useEffect } from "react";
import CommonNav from "../CommonNav/CommonNav";
import hillstations from "../Assets/hillstations.jpg";
import EnquiryForm from "../EnquiryForm/EnquiryForm";
import Footer from '../Footer/Footer'

function HillsRinse() {
    useEffect(() => {
        window.scrollTo(0, 0);
      },[])
  return (
    <div className="Athira-wrapper">
      <CommonNav />
      <div className="athira-MainHeading">
        <h1>India / 5-Day Itinerary / HILLS &amp; RINSE </h1>
        <img className="athira-headingImg" src={hillstations} alt="" />
        <h4>Suggested itinerary</h4>
        <h6>DAY 01: COCHIN - THEKKADY</h6>
        <p>
        Upon arrival at Cochin airport you would be transferred to Thekkady (180 KM / 5 hours).
Enjoy the drive through the tea mountains and the picturesque green carpet of the nature.
Upon reaching, check into hotel. Overnight stay at Thekkady.
        </p>
        <h6>DAY 02: THEKKADY</h6>
        <p>
        Thekkady is famous for the Periyar Wild life Sanctuary. Here you can enjoy various activities
like elephant ride, elephant interaction programme (bathing and feeding elephant), spice
plantation visit, jungle safari (by jeep) etc…. <br/>
Main attraction is the wildlife cruise. Without this your tour to Thekkady would be
incomplete. While enjoying the cruise you have a chance to view large variety of wild
animals elephants, langur, sambar deer, bison, boar etc... and quite a lot of unique birds
can also be spotted. Overnight stay at Thekkady.
        </p>
        <h6>DAY 03: THEKKADY – KUMARAKOM</h6>
        <p>
        After breakfast drive to Kumarakom (130 KM / 4 hrs). Upon reaching embark on a house
boat for your backwater cruise, a splendid area full of canals, lakes and coves. Your
houseboat or &#39;Kettuvalloms&#39; is a luxurious boat with well-appointed bedrooms and
bathrooms. The cruise takes you across lake Vembanad, through narrow canals, low lying
paddy fields - a complete life style in a water world. Stop to buy fish or tender coconut,
enjoy delicious traditional Kerala food. Boat anchors at sunset to prevent getting entangled
in the fish nets. It’s also a magic moment, rocking gently counting stars and finally to bed in
the middle of no-where. Apart from the mesmerizing surroundings, it&#39;s the life along the
backwaters that makes this a fascinating experience.

<h6>Please note:</h6>
        <ul>
            <li>A/C will be switched on inside bed room from 09:00 pm to 06:00 am only </li>
            <li>Check in / checkout time for House Boat: 12:30 hrs and 09:00 hrs</li>
            <li>Cruise time: from 12:30 hrs to 17:00 hrs &amp; from 07:00 hrs to 08:00 hrs</li>
            <li>Between 17:00 hrs &amp; 07:00 hrs the boat will be anchored near to the shore</li>
            <li>Food is normally non veg. If you require only veg food, inform before the tour</li>
        </ul>

        </p>
        <h6>DAY 04: KUMARAKOM – MARARI</h6>
        <p>
        After breakfast disembark from the houseboat and proceed to one of the most beautiful and
peaceful beach destinations in Kerala Marari (25 KM / 30 minutes). Enjoy the day at
leisure. Relax your holiday at the sprawling beach. Enjoy a short drive along this coastal
village. Stay at this beach resort

        </p>
        <h6>DAY 05: MARARI - COCHIN</h6>
        <p>
        After breakfast you would be transferred to Cochin (65 KM / 01.3 hrs)
If time permits enjoy tour of heritage town Fort Cochin and Mattancherry. Visit Jewish
Synagogue (closed on Fridays, Saturdays and Jewish holidays), which was built in 1568 and
is still an active house of worship for the few remaining Jews in the area. Next to the
synagogue is Cochin’s ancient and colorful Jew Street with its antique stores and souvenir
peddlers. There is evidence of a Jewish presence in Kochi from 70 AD. After the synagogue
you visit Dutch palace (closed on Fridays). Also visit the St. Francis Church, which was
established by the Portuguese Franciscan friars in 1503. This is one of the first European
churches built in India and the famed Vasco Da Gama was buried in its courtyard for a few
years before his remains were returned to Portugal.
Here you can also have an option to see the most photogenic of Cochin’s attractions, 15th
century Chinese fishing nets. You may see local fishermen using these massive nets with an
ease that comes from centuries of practice, the lowering and raising of the nets has a
synchronicity and rhythm that is quite an amazing experience to watch. You would be dropped at airport at appropriate time

        </p>
       
        <h4>Properties foreseen :</h4>
        <table>
            <thead>
                <tr>
                    <th>Destination</th>
                    <th>Property</th>
                    <th>No.of.nights</th>

                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Thekkady</td>
                    <td>Hills &amp; Hues</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Kumarakom</td>
                    <td>Lakes &amp; Lagoons - House Boat</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Marari</td>
                    <td>Xandari Pearl</td>
                    <td>1</td>
                </tr>
            
            </tbody>
        </table>
        <h6>Cost includes:</h6>
        <ul>
            <li>Accommodation</li>
            <li>Daily breakfast and dinner</li>
            <li>All meals in houseboat</li>
            <li>Boat ride at Thekkady</li>
            <li>Entire transportation by AC vehicle</li>
            <li>Toll, parking, driver bata</li>
            <li>All applicable taxes</li>
            
        </ul>
        <h6>Cost excludes:</h6>
        <ul>
            <li>Flight / Train tickets</li>
            <li>Charges for any optional activities tip, laundry, telephone charges etc....</li>
            <li>All other expenses which are not mentioned in the inclusions </li>
        </ul>

        <h6>Please note:</h6>
        <ul>
            <li>Booking confirmation is subject to availability. In case of non-availability of rooms at the
hotels mentioned, alternate hotels of similar category will be provided </li>
            <li>Check in time at the hotel is 12.00 hrs and check out time is 11.00 hrs</li>
            <li>Check in / out times in houseboat is 12:30 hrs and 09:00 hrs</li>
            <li>Vehicle cannot be used between 20:00 hrs and 08:00 hrs</li>
        </ul>
        
      </div>
      <EnquiryForm/>
      <Footer/>
    </div>
  )
}

export default HillsRinse