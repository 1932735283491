import React, { useEffect } from "react";
import CommonNav from "../CommonNav/CommonNav";
import EnquiryForm from "../EnquiryForm/EnquiryForm";
import Footer from '../Footer/Footer'

function KarnaatakaTamilnadu() {
    useEffect(() => {
        window.scrollTo(0, 0);
      },[])
  return (
    <div className="Athira-wrapper">
      <CommonNav />
      <div className="athira-MainHeading">
        <h1>India / 7-Day Itinerary / Karnataka & TamilNadu</h1>
        <h4>Suggested itinerary</h4>
        <h6>Day 01: Bangalore - Mysore</h6>
        <p>
        Upon arrival at Bangalore airport we will transfer you to Mysore (170 KM / 4 hrs). The
capital of a former princely state, Mysore is a heritage city which possesses palaces and
gardens and is famous for its silk, sandalwood and jasmine. En route you can visit
Srirangapatnam and Ranganathittu Bird Sanctuary. Overnight stay in Mysore
        </p>
        <h6>Day 02: Mysore</h6>
        <p>
        Enjoy full day tour of Mysore, one of the most important heritage cities in the country.
Here we drove around various tourist places like Chamundi hills, Nandi Bull
elegant Mysore palace etc…. Overnight stay in Mysore.
        </p>
        <h6>Day 03 &amp; 04: Mysore - Ooty</h6>
        <p>
        After breakfast, proceed to Ooty (150 KM / 5 hrs). Ooty - &quot;Queen of Hill stations&quot; is
nestled in the Nilgiris range. Visit Pykara Dam, Kamarajsagar and Ooty Botanical Garden.
(You will have 2 days to enjoy this hill station. Various other sightseeing spots include -
Dodda Betta Peak, Valley View Lake etc… ) A drive along this beautiful hill station itself
will rejuvenate you. Overnight stay at Ooty for 2 nights
        </p>
        <h6>Day 05 &amp; 06: Ooty - Kodaikanal</h6>
        <p>
        After breakfast, drive to Kodaikanal (275 KM / 6/7 hrs).
Enjoy 2 day days sightseeing of Kodaikanal. Coakers walk, Kodai lake, pine forest, suicide
point, guna cave, dolphins nose, pillar rocks etc… are the very few among the main spots.
Over night at this hill station (2 nights)
        </p>
        <h6>Day 07: Kodaiakanal – Madurai (departure)</h6>
        <p>
        Proceed to Madurai airport (150 KM / 03 / 04 hrs)
If time permits you can visit Madurai Meenakshi temple
You would be dropped at Madurai airport at appropriate time
        </p>
        <h6>Note:</h6>
        <p>Kodaiakanal- Bangalore is 475 KM and 8/9 hr journey. Hence we suggested Madurai airport</p>
       
      </div>
      <EnquiryForm/>
      <Footer/>
    </div>
  )
}

export default KarnaatakaTamilnadu