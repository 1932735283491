import React, { useEffect } from "react";

import CommonNav from "../CommonNav/CommonNav";
import EnquiryForm from "../EnquiryForm/EnquiryForm";
import Footer from '../Footer/Footer'

function KTK() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <div className="Athira-wrapper">
      <CommonNav />
      <div className="athira-MainHeading">
        <h1>India / 08-Day Itineraries / Karnataka, Tamil Nadu & Kerala </h1>
        <h4>Suggested itinerary</h4>
        <h6>Day 01: Bangalore – Mysore (You should reach morning flight)</h6>
        <p>
          Upon arrival at Bangalore airport we will transfer you to Mysore (170
          KM / 4 hrs). The capital of a former princely state, Mysore is a
          heritage city which possesses palaces and gardens and is famous for
          its silk, sandalwood and jasmine. En route you can visit
          Srirangapatnam and Ranganathittu Bird Sanctuary and later visit Mysore
          palace. Overnight stay in Mysore
        </p>
        <h6>Day 02 & 03: Mysore - Ooty</h6>
        <p>
          After breakfast, proceed to Ooty (150 KM / 5 hrs). Ooty - "Queen of
          Hill stations" is nestled in the Nilgiris range. Visit Pykara Dam,
          Kamarajsagar and Ooty Botanical Garden. (You will have 2 days to enjoy
          this hill station. Various other sightseeing spots include - Dodda
          Betta Peak, Valley View Lake etc… ) A drive along this beautiful hill
          station itself will rejuvenate you. Overnight stay at Ooty for 2
          nights
        </p>
        <h6>Day 04: Ooty - Munnar</h6>
        <p>
          After breakfast proceed to Munnar (250 KM – 6/7 hours). Upon reaching
          check in hotel and relax after the long drive. If required you can
          have a short drive and cover some sightseeing. Overnight stay at
          Munnar.
        </p>
        <h6>Day 05: Munnar </h6>
        <p>
          Proceed for sightseeing trip of Munnar after the breakfast; Visit
          Eravikulam National Park at Rajamlai where you can see the rare
          specious of Nilgiri Tahr, Mattupetty Dam which offers many fascinating
          sights to relish, the Shola forests around Mattupetty and Kundala Dam.
          Visit Photo Shooting Point, Echo Point, View Point & the green carpet
          of Tea Gardens on the way. Overnight stay at Munnar.
        </p>
        <h6>Day 06: Munnar - Kumarakom</h6>
        <p>
          After breakfast check out and drive to Kumarakom (150 KM –4 hours).
          Upon reaching embark on a houseboat. Enjoy whole day cruise. You can
          have traditional Kerala food onboard. If required you can enjoy
          fishing (angling) and ask the chef to prepare it fresh for lunch or
          dinner. Overnight stay on board.
        </p>
        <h6>Day 07: Kumarakom - Marari</h6>
        <p>
          After breakfast disembark from the houseboat and drive to Mararikkulam
          (30 KM –45 minutes), which is one of the most beautiful and peaceful
          beach destinations in Gods Own Country. Here you check into a
          beautiful resort. Enjoy the whole day at this beach destination If
          fortunate you can see fishermen coming back from the sea in country
          boats. Overnight stay at this beach destination
        </p>
        <h6>Day 08: Marari- Cochin (departure)</h6>
        <p>
          After breakfast proceed to Kochi (50 KM – 1.30 hour). Vehicle at
          disposal.
        </p>

        <h6>Cost includes:</h6>
        <ul>
          <li>Accommodation</li>
          <li>Daily breakfast and dinner</li>
          <li>All meals in houseboat</li>
          <li>Transfer and sightseeing by AC tempo traveller (17 seat)</li>
          <li>Toll, parking, driver bata, inter-state permit</li>
          <li>All applicable taxes</li>
        </ul>
        <h6>If time permits: you can visit (as per your flight time):</h6>
        <ul>
          <li>Visit Chottanikkara temple </li>
          <li>Flight / Train tickets</li>
          <li>Visit heritage town Fort Kochi and Mattancherry</li>
          <li>
            Enjoy a walk along the marine drive, shopping in Broadway, MG road
            etc…
          </li>
        </ul>

        <p>You would be dropped at Cochin airport at appropriate time</p>
      </div>
      <EnquiryForm/>
      <Footer/>
    </div>
  );
}

export default KTK;
