import React, { useEffect } from "react";
import CommonNav from "../CommonNav/CommonNav";
import EnquiryForm from "../EnquiryForm/EnquiryForm";
import Footer from '../Footer/Footer'

function GoldenTriangleGOC() {
    useEffect(() => {
        window.scrollTo(0, 0);
      },[])
  return (
    <div className="Athira-wrapper">
      <CommonNav />
      <div className="athira-MainHeading">
        <h1>India / 11-Day Itinerary / GoldenTriangle & Gods Own Country </h1>
        <h4>Suggested itinerary</h4>
        <h6>Day 01: Arrive Delhi</h6>
        <p>
        Arrive Delhi. Our chauffeur will be at the airport with a placard in your name OUTSIDE the airport.
Check-in at hotel. <br/>
Car will be provided only for arrival transfer on this day. Should you need car for sightseeing or
lunch/dinner out, extra charges will be applicable and to be informed in advance..
        </p>
        <h6>Day 02: Delhi</h6>
        <p>
        At 0830 hrs proceed with the sightseeing of Old Delhi. The tour will begin drive past the Red Fort
continuing to the Jama Masjid by bicycle rickshaws, one of Asia’s largest mosques. The magnificent Red
Fort, overlooking the river Jamuna was built during the years 1638 - 48 when the Moghul Empire was at its
peak. The tour continues to Jama Masjid, one of Asia’s largest mosques and which is viewed from the
outside. <br />
After visiting Old Delhi, you will be driven to New Delhi, which reflects the legacy the British left behind.
The division between New and Old Delhi is the division between the capitals of the British and the Mughals
respectively. The division in the walled city and New Delhi also marks the division in the life-styles. The
walled city is all tradition where one will be able to glean a past life-style in all its facets, colours and spells.
New Delhi in contrast, is a city trying to live up to the best of 21st century standards.
Imperial Delhi will include the Humayun Tomb, Qutub Minar, the tallest stone tower in India. The tour also
includes a drive past the imposing India Gate, the Parliament building and the Rastrapathi Bhawan, the
President’s residence. <br />
Car will be provided only sightseeing as per the itinerary. Should you need car for extra sightseeing or
lunch/dinner out, extra charges will be applicable and to be informed in advance.
        </p>
        <h6>Day 03: Delhi - Agra</h6>
        <p>
        Morning depart at 0830 hrs for Agra (203 kms-3.5 hrs). Arrive and transfer to hotel. Overnight at hotel.
        <br />
Evening view Taj Mahal across river Yamuna from Mehtabh Bagh (without local guide)

        </p>
        <h6>Day 04: Agra - Jaipur</h6>
        <p>
        Just after sunrise (around 0600 hrs) visit the world famous Taj Mahal built by the Moghul Emperor
Shahjehan in 1630 for his Queen Mumtaz Mahal to enshrine her mortal remains. <br />
Afternoon visit The Agra Fort containing the Pearl Mosque, the Halls of Public and Private Audience.
Also visit the Tomb of Itmad-ud-Daullah built by Empress Noorjehan in memory of her father.
Later drive to Jaipur (256 kms- 5/6 hrs drive). Arrive Jaipur and check in at hotel.

        </p>
        <h6>Day 05: Jaipur </h6>
        <p>
        After breakfast visit Amer Fort at 0800 hrs - the ancient capital of the State. Visit the Sheesh Mahal or the
Hall of Victory glittering with mirrors. <br />
Proceed for a tour of Jaipur. Jaipur Rajasthan was given a colour coat of pink a century ago in honour of
a visiting Prince. Built by Maharaja Jai Singh, the notable astronomer, this city is 260 years old. Visit
Maharaja&#39;s City Palace, the Observatory &amp; Ram Niwas Gardens. Drive past Hawa Mahal &amp; through the
pink rose residential &amp; business areas.

        </p>
        <h6>Day 06: Jaipur</h6>
        <p>
        Day at leisure to explore the city on your own. Incase you travel to Samode or Sanganer village or Chowki
Dhani, extra charges will be applicable.
        </p>
        <h6>Day 07: Jaipur - Delhi – Cochin - Kumarakom</h6>
        <p>
        Morning drive back to Delhi or board a flight for onward journey. <br />
Fly To Cochin (You have to take flight either from Jaipur or Delhi) <br />
Upon reaching at Cochin airport, we will transfer you to Kumarakom (2.30 hrs – 100 KM). Kumarakom is a
village on the side of Vembanad Lake in the backwaters of Kerala, Southern India. Check into a beautiful
lake side resort. Overnight stay at Kumarakom

        </p>
        <h6>Day 08: Kumarakom</h6>
        <p>
        Enjoy leisure at the resort <br />
(Optional: You can enjoy Ayurveda massage) <br />
Stay at Kumarakom
 

        </p>
        <h6>Day 09: Kumarakom – Alleppey -Kumarakom</h6>
        <p>
        After breakfast check out from the resort and drive to Alleppey (40 KM / 1.30 hrs). Embark on a house boat
for your backwater cruise, a splendid area full of canals, lakes and coves. Your houseboat or &#39;Kettuvalloms&#39;
is a luxurious boat with well-appointed bedrooms and bathrooms. Enjoy traditional Kerala lunch onboard.
Apart from the mesmerizing surroundings, it&#39;s the life along the backwaters that makes this a fascinating
experience. Later disembark from the boat and drive to Kumarakom for overnight stay

        </p>
        <h6>Day 10: Kumarakom - Marari</h6>
        <p>
        Today we will transfer you to one of the most beautiful and peaceful beach destinations in Gods Own
Country Marari. Here you check into another beautiful resort. <br />
Mararikkulam is a tiny village in Alleppey district, majority of the people belonging to fishermen
community. Stay at Marari.

        </p>

        <h6>Day 11: Marari</h6>
        <p>
        Enjoy the sprawling beach. Day at leisure. Stay at Marari.
        </p>
        <h6>Day 12: Marari - Cochin</h6>
        <p>
        Today you proceed to Cochin, financial capital of Kerala. Explore the sites &amp; cultures of medieval Kochi,
shaped by a collusion of Portuguese, Dutch and English traders. Enjoy tour of heritage town Fort Cochin
and Mattancherry. Visit Jewish Synagogue, which was built in 1568 and is still an active house of worship
for the few remaining Jews in the area. Next to the synagogue is Cochin’s ancient and colorful Jew Street
with its antique stores and souvenir peddlers. Stay at Cochin
        </p>
        <h6>Day 13: Cochin</h6>
        <p>
        Today visit the St. Francis Church, which was established by the Portuguese Franciscan friars in 1503. This
is one of the first European churches built in India and the famed Vasco Da Gama was buried in its
courtyard for a few years before his remains were returned to Portugal. Later you have an option to visit the
most photogenic of Cochin’s attractions, 15th century Chinese fishing nets. You may see local fishermen
using these massive nets with an ease that comes from centuries of practice, the lowering and raising of the
nets has a synchronicity and rhythm that is quite an amazing experience to watch. Enjoy Kathakali show
(traditional dance-drama art form of Kerala) in a nearby cultural theatre. Overnight at Cochin
        </p>
        <h6>Day 14: Cochin airport</h6>
        <p>
        Enjoy the sprawling beach. Day at leisure. Stay at Marari.
        </p>

        <h4>Properties foreseen :</h4>
        <table>
            <thead>
                <tr>
                    <th>Destination</th>
                    <th>Nights</th>

                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Delhi</td>
                    <td>02</td>
                </tr>
                <tr>
                    <td>Agra</td>
                    <td>01</td>
                </tr>
                <tr>
                    <td>Jaipur</td>
                    <td>03</td>
                </tr>
                <tr>
                    <td>Kumarakom</td>
                    <td>03</td>
                </tr>
                <tr>
                    <td>Marari</td>
                    <td>02</td>
                </tr>
                <tr>
                    <td>Cochin</td>
                    <td>2</td>
                </tr>
            </tbody>
        </table>
        <h6>Cost includes:</h6>
        <ul>
            <li>Accommodation for 13 nights</li>
            <li>Daily breakfast and dinner</li>
            <li>Local English speaking guide for Delhi, Agra, Jaipur and Cochin as per the itinerary</li>
            <li>Rickshaw ride in Delhi</li>
            <li>Interstate taxes, Delhi Agra new expressway toll tax</li>
            <li>Kathakali dance at Cochin</li>
            <li>Airconditioned vehicle</li>
            <li>Driver’s overnight allowance, his meals</li>
            <li>All applicable taxes</li>          
        </ul>
        
      </div>
      <EnquiryForm/>
      <Footer/>
    </div>
  )
}

export default GoldenTriangleGOC