import React, { useEffect } from "react";
import CommonNav from "../CommonNav/CommonNav";
import goa3 from "../Assets/goa3.jpg";
import EnquiryForm from "../EnquiryForm/EnquiryForm";
import Footer from '../Footer/Footer'

function GoaKochi() {
    useEffect(() => {
        window.scrollTo(0, 0);
      },[])
  return (
    <div className="Athira-wrapper">
      <CommonNav />
      <div className="athira-MainHeading">
        <h1>India / 18-Day Itinerary / Goa - Kochi </h1>
        <img className="athira-headingImg" src={goa3} alt="" />
        <h4>Suggested itinerary</h4>
        <h6>Day 01: Goa</h6>
        <p>
        Upon arrival at Goa, you will be transferred to the resort. Relax the whole day at this beautiful
property. Enjoy a short walk along the beach. Overnight at Goa
        </p>
        <h6>Day 02: Goa</h6>
        <p>
        This morning you can go out for a sight seeing trip of North Goa. You can see some of the most
beautiful beaches of Goa - Dona Paula, Calangute, Baga and Miramar. These are the popular beaches
of North Goa. Also visit Fort Aguada, Basilica of Bom Jesus and Se Cathedral. Overnight at Goa
        </p>
        <h6>Day 03: Goa</h6>
        <p>
        Today you will enjoy sight seeing of South Goa. It includes Colva Beach, Mangeshi Temple, Santa
Durga Temple and several other attractions. Visit Margao, the capital of Salcette taluka and
headquarters of the South Goa district. You can also visit Old Goa churches. Later enjoy a boat cruise
in river Mandovi. Return to the hotel for overnight stay.

        </p>
        <h6>Day 04: Goa to Badami</h6>
        <p>
        Proceed to Badami (275 KM). Upon reaching check into hotel and take rest for the whole day after this
long drive. Overnight at Badami.

        </p>
        <h6>Day 05: Badami </h6>
        <p>
        Today you enjoy sightseeing of Badami, picturesquely situated at the mouth of a ravine between two
rocky hills, is famous for its cave temples-all hewn out of sand stone on the rock face of a hill. Explore
Badami Caves. Visit Aihole and Pattadakkal (40 KM). Aihole was the ancient capital of Chalukyas.
Famous as the cradle of Indian architecture, Aihole has over a hundred temples, built between the 6th
and 8th centuries. Situated on the left bank of the Malaprabha River, Pattadakkal is a World Heritage
Centre. The town has 10 major temples representing early Chalukyan architecture. Later return to
Badami for over night stay.

        </p>
        <h6>Day 06: Badami - Hospet - Hampi</h6>
        <p>
        After checkout drive to Hospet (170 KM). Hospet, the satellite town built during the reign of
Krishnadevaraya, is a typical country town of Karnataka and is frequented by visitors to the
Vijayanagar ruins. Its nearby destination Hampi, once the capital of the Hindu empire Vijayanagara, is
listed as one of the UNESCO World Heritage Sites. Hampi is charismatic even in its ruined state. Vast
stretches of boulder-strewn hills make the backdrop of Hampi unique. Dotted around the hills and
valleys are 500 plus monuments. In Hampi at every turn there is a surprise. Every monument hides
more than what they reveal. Over night stay at Hospet.
        </p>
        <h6>Day 07: Hospet-Hassan </h6>
        <p>
        Today morning you check out and drive to Hassan (350 KM). Upon reaching, you check into the hotel.
Over night stay at Hassan.

        </p>
        <h6>Day 08: Hassan</h6>
        <p>
        Hassan, the city founded during the Hoysala Dynasty, which ruled the region from 11th to 14th century.

From Hassan drive about 30 KM to reach Hoysaleshwara temple at Halebidu. The beautiful temple,
located on star shaped base amidst wide strech of lawn all around, is a glaring example of Hoysalan
architectural excellence. Later drive another 10 km and reach Belur. Here pay a visit to the magnificent
Chennakeshava temple, dedicated to Lord Krishna. Both these temples, which are prominent in the
tourist attractions’ list, due to their importance in Indian epic stories Mahabharata and Ramayana,
witness lot of tourists as well as pilgrims round the year. Later return to the hotel at Hassan for over
night stay.
 

        </p>
        <h6>Day 09: Hassan - Coorg</h6>
        <p>
        Check out the hotel in the morning after breakfast and drive to Coorg (120 KM). Coorg or Kodagu is
aslo called the Scotland of India due to its peculiar weather. Coorg is famous for coffee plantation as
well as planters, majority of them belongs to Kshatriya clan. Explore this hill station. Visit Rajah Seat,
Fort etc ….at Madikkeri. Overnight stay at Coorg.

        </p>
        <h6>Day 10: Coorg</h6>
        <p>
        Enjoy whole day sightseeing of Coorg. Major attractions include: Iruppu falls (waterfall), Talacauvery
(birth place of Cauvery river), Dubare elephant camp (kraal), Kushal Nagar (Golden temple),
Bylakuppe (famous for Budha temple, monasteries and one of the major Tibetian settlements in India),
Madikkeri - Rajah Seat, Fort etc …. Overnight at Coorg.

        </p>

        <h6>Day 11: Coorg – Mysore</h6>
        <p>
        After breakfast check out from the hotel and proceed to Mysore (130 KM). The capital of a former
princely state, Mysore is a heritage city which possess palaces and gardens and is famous for its silk,
sandalwood and jasmine. Visit Srirangapatna, Mysore palace, Chamundi Hills etc... Visit the Brindavan
garden and watch in the evening. Overnight at Mysore.

        </p>

        <h6>Day 12: Mysore - Ooty</h6>
        <p>
        Check out from the hotel and visit Somnathpur before proceeding to Ooty (150 KM). Ooty, which is
also called &quot;Queen of Hill stations&quot;, nestled in the Nilgiris range, is one of the most beautiful hill
stations of South India. Over night stay at Ooty.

        </p>

        <h6>Day 13: Ooty</h6>
        <p>
        Visit Pykara Dam, Kamarajsagar and Ooty Botanical Garden. (It time permits visit various other sight
seeing spots - Dodda Betta Peak, Valley View Lake, Sim’s Peak, Coonoor Tea Gardens, Dolphin’s
Nose etc… ) A drive along this beautiful hill station itself will rejuvenate you. Over night stay at Ooty.

        </p>

        <h6>Day 14: Ooty-Palakkad</h6>
        <p>
        After breakfast drive from Ooty to Palakkad (150 KM). Check into a traditional heritage home stay, the
place to experience the village life of Kerala. The house set in a rustic village outside Palakkad Town
takes you back to a by gone era. Away from the busy traffic and into a countryside that is absolutely
quiet and calm. In the after noon you can visit a basket weaving centre. Over night stay at Palakkad.

        </p>

        <h6>Day 15: Palakkad-Kumarakom</h6>
        <p>
        After breakfast check out and proceed to a lake side resort at Kumarakom (210 KM). Enjoy your stay
at this resort. And if required you can go for a short drive along this coastal village or enjoy a walk
along the paddy fields. Visit Kumarakom bird sanctuary. Over night stay in the resort.

        </p>

        <h6>Day 16: Kumarakom-Alleppey</h6>
        <p>
        Today morning you embark on a houseboat. Enjoy the beauty of backwaters in Gods Own Country.
Enjoy one day life in India&#39;s largest lake &#39;Vembandu lake&#39;. If required you can enjoy fishing (angling).
And if fortunate you can ask the chef to prepare it fresh for your lunch/dinner. Enjoy narrow canal
cruise and see the life of common man, majority belonging to to fishermen community. Overnight stay
on board

        </p>


        <h6>Day 17: Alleppey-Cochin</h6>
        <p>
        After breakfast disembark from the houseboat at Alleppey on the opposite side of the lake and drive to
Kochi (70 KM). Full day sight seeing tour of Cochin. <br />
Cochin-Once a much prized pawn between the Portuguese, Dutch and English, Kochi or Cochin in the
Southern Indian state of Kerala has a history of being a premier port along the fabled “spice route” to
the east. Fort Cochin was a great emporium of trade, a harbor filled with ships, streets crowded with
merchants and warehouses stored with goods from every port of Asia and Europe <br />
Enjoy full day sight seeing of Cochin. Visit Chinese Fishing Nets, St. Francis Church, Dutch Palace
(closed on Fridays) and Jewish Synagogue.(closed on Fridays &amp; Saturdays and Jewish holidays). You
can also enjoy demonstration of Kathakali (traditional dance - drama how of Kerala ) and also
Kalaripayattu (martial art show) at cultural centre here. Overnight stay at Cochin.

        </p>

        <h6>Day 18: Departure</h6>
        <p>
        Our driver will drop you at Cochin airport at appropriate time

        </p>


        <h4>Properties foreseen :</h4>
        <table>
            <thead>
                <tr>
                    <th>Destination</th>
                    <th>Property</th>
                    <th>No.of.nights</th>

                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Goa</td>
                    <td>Casa De Goa</td>
                    <td>3</td>
                </tr>
                <tr>
                    <td>Badami</td>
                    <td>Badami Court</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Thekkady</td>
                    <td>Pepper County – Homestay</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Hampi/Hospet</td>
                    <td>Royal Orchid Central</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Hassan</td>
                    <td>The Ashok</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Coorg</td>
                    <td>Ambatty Greens Resort</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Mysore</td>
                    <td>Fortune JP Palace</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Ooty</td>
                    <td>Sterling Fern Hill</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Palakkad</td>
                    <td>Tharavadu Heritage Home</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Kumarakom</td>
                    <td>Lake Song</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Alleppey</td>
                    <td>Houseboat</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Cochin</td>
                    <td>Hotel Casino</td>
                    <td>1</td>
                </tr>
            </tbody>
        </table>
        <h6>Cost includes:</h6>
        <ul>
            <li>Accommodation in twin/double room</li>
            <li>Daily breakfast and dinner</li>
            <li>All meals in house boat</li>
            <li>Kathakali and martial arts demonstration at Cochin</li>
            <li>AC vehicle for entire transportation including sightseeing as per the
itinerary</li>
            <li>Driver bata, toll, parking, inter state permit (all expenses related to the
vehicle)</li>
            <li>All currently applicable taxes.</li>            
        </ul>
        <h6>Cost excludes:</h6>
        <ul>
            <li>Any air/train fare</li>
            <li>Any meals other than those specified above</li>
            <li>Expenses of personal nature such as tips, telephone calls, laundry, liquor
etc</li>
<li>Anything not specified in ‘cost includes’</li>
        </ul>

        <h6>Please note:</h6>
        <ul>
            <li>Rooms are subject to availability </li>
            <li>Early check in or late check out are subject to availability &amp; prior request</li>
        </ul>
        
      </div>
      <EnquiryForm/>
      <Footer/>
    </div>
  )
  
}

export default GoaKochi