import React from 'react'
import logo from '../Assets/logo.svg'
import { Link } from 'react-router-dom'
import './CommonNav.css'
function CommonNav() {
    function handlemenu(){
        const menu=document.getElementsByClassName('Commonnav-menus')[0]
        menu.classList.toggle('active')  
        }
  return (
        <div className='Commonnav-wraper'>
        <div className="logo">
            <img className='logoimg' src={logo} alt="" />
        </div>
        <div className='Commonnav-menus' >
            <ul className="CommonNav-menu">
                <li><Link to='/'>HOME</Link></li>
                <li><Link to='/destination'>DESTINATIONS</Link></li>
                <li><Link to='/gallery'>GALLERY</Link></li>
                <li><Link to='/AboutUs'>ABOUT US</Link></li>
                <li><Link to='/Iteneraries'>ITINERARY</Link></li>
                <li><Link to='/Activities'>ACTIVITIES</Link></li>
                <li><Link className='nav-contactButton' to='/contact'>CONTACT US</Link></li>
            </ul>
        </div>
        <div onClick={handlemenu} className="nav-toggle-button">
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
    </div>
  )
}

export default CommonNav