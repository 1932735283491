import React ,{useState} from 'react'
import './EnquiryForm.css'

function EnquiryForm() {
    const [FullName, setName] = useState("");
    const [email, setEmail] = useState("");
    const [contactNo,setContactNo]=useState('')
    const [StartDate,setPreferredDate]=useState('')
    const [duration,setDuration]=useState('')
    const [preferedDestination,setPreferedDestination]=useState('')
    const minDate=new Date().toISOString().split('T')[0]

    const handleDuration=(event)=>{
      const regex = /^[0-9\b]+$/; //regex to match numeric digits and backspace key
      if (event.target.value === '' || regex.test(event.target.value)) {
        setDuration(event.target.value);
    }
  }

    const handleSubmit = async (e) => {
      e.preventDefault();
      const response = await fetch("https://formspree.io/f/mwkjzaad", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({FullName,email,contactNo,preferedDestination,duration,StartDate}),
      });
      if (response.ok) {
        alert("Form submitted!");
        setName("");
        setEmail("");
        setContactNo("");
        setPreferredDate("");
        setDuration("");
        setPreferedDestination("");
      } else {
        alert("Error submitting form");
      }
    }
  return (
      <div className="enquiryWraper">
          <form onSubmit={handleSubmit}>
      <div className="enquiryField">
      <label  htmlFor='name' >Full Name</label>
<input type="text" required value={FullName} onChange={(e) => setName(e.target.value)}  />
      </div>
      <div className="enquiryField">
      <label  htmlFor='email' >Email ID</label>
<input type="email" required value={email} onChange={(e) => setEmail(e.target.value)}  />
      </div>
      <div className="enquiryField">
      <label  htmlFor='contactNo' >Contact Number</label>
<input type="number" required value={contactNo} onChange={(e) => setContactNo(e.target.value)}  />
      </div>
      <div className="enquiryField">
      <label  htmlFor='prefferedDate' >Start Date</label>
<input type="date" required value={StartDate} onChange={(e) => setPreferredDate(e.target.value)} min={minDate}  />
      </div>
      <div className="enquiryField">
      <label  htmlFor='duration' >Duration</label>
<input type="text" required maxLength={2} placeholder='No.of Days' value={duration} onChange={handleDuration}  />
      </div>
      <div className="enquiryField">
      <label  htmlFor='prefferedDestination' >Preferred Destinations</label>
      <textarea required rows="2" value={preferedDestination} onChange={(e) => setPreferedDestination(e.target.value)} ></textarea>
      </div>
      

<div className="enquiry-submit">
<button type="submit">Submit</button>
</div>

    </form>
      </div>

  )
}

export default EnquiryForm