import React from "react";
import "./AboutUs.css";

function AboutUs() {
  return (
    <div className="AboutUs-wrapper" id="aboutus">
      <div className="AboutUs-contents">
        <h1>About us</h1>
        <p>
          Learning Trails arrange travel packages across India. While talking of
          arranging tours we organize leisure tours for families & friends, MICE
          (Meetings, Incentives, Conference and Exhibition) for corporates, study
          tour for students and also shore excursion for cruise passengers.
        </p>
        <p>
          Tourists throng India during the season time October to February. The
          maximum travelers are seen during the peak season Dec 20 to Jan 10.
          Tourism being a service industry, all client’s hopes and demands
          hassle free experience and we offer this mandatorily.
        </p>
        <p>
          We customize all packages/itineraries. We also conduct trips to
          several ‘off the beaten spots’ surrounding the tourist destinations
        </p>
        <p>
          Close to Cochin we can take our guests to Kodanad, nearby Munnar we
          can arrange trekking at Meesappulimalai and around Thekkady we chose
          Gavi Reserve Forest and Vandanmedu. For beach, while we suggest unique
          properties at Marari, for enjoying backwater tours (the main tourist
          attraction in Kerala) we take the guests to tiny villages spread
          around Alleppey and Kumarakom.
        </p>
        <p>
          Towards North of Kerala, we select properties surrounding Muthanga
          Forest and Banasura Sagar dam at Wayanad and towards further North we
          conduct trips to Nileshwaram and Thalassery.
        </p>
        <p>
          Let the thirst for a relaxing, joyful, merry making excursion exist
          forever and we are ready to welcome more number of travellers. LT not
          only takes our guests to exciting places but we help them to enjoy a
          feel of the place as well. While travelling with LT, the guests not
          only enjoy a tour but an experience as well.
        </p>
      </div>
    </div>
  );
}

export default AboutUs;
