import React, { useEffect } from "react";
import CommonNav from "../CommonNav/CommonNav";
import GOC1 from "../Assets/GOC1.png";
import GOC2 from "../Assets/Goc.png";
import ChennaiCochin1 from "../Assets/ChennaiCochin1.png";
import ChennaiCochin2 from "../Assets/ChennaiCochin2.png";
import ChennaiCochin3 from "../Assets/ChennaiCochin3.png";
import ChennaiCochin4 from "../Assets/ChennaiCochin4.png";
import ChennaiCochin5 from "../Assets/ChennaiCochin5.png";
import ChennaiCochin6 from "../Assets/ChennaiCochin6.png";
import ChennaiCochin7 from "../Assets/ChennaiCochin7.png";
import ChennaiCochin8 from "../Assets/ChennaiCochin8.png";

import EnquiryForm from "../EnquiryForm/EnquiryForm";
import Footer from '../Footer/Footer'

function ChennaiCochin() {
    useEffect(() => {
        window.scrollTo(0, 0);
      },[])
  return (
    <div className="Athira-wrapper">
      <CommonNav />
      <div className="athira-MainHeading">
        <h1>India / 15-Day Itinerary / Chennai to Cochin</h1>
        <h4>Suggested itinerary</h4>
        <h6>Day 01: Arrive at Chennai</h6>
        <p>
        Upon arrival at Chennai airport, you would be transferred to the hotel.
Relax for the whole day. Over night stay
        </p>

        <h6>Day 02: Chennai</h6>
        <p>
        Chennai is a perfect blend of traditionalism and a bustling cosmopolitan life.
The major attraction of Chennai are its sprawling golden beaches, the most
popular among them being the picturesque Marina Beach that also boasts
of being the second longest beach in the world. Visit the Kapaleeshwarar
Temple dedicated to Lord Shiva and drive past the majestic Fort St.George
and Santhome Cathedral.
        </p>
        <img className="athira-headingImg" src={ChennaiCochin1} alt="" />
        <p>
        Later visit the Government Museum and Art Gallery, has a good collection
of paintings and sculptures, both traditional and modern. A must visit on a
trip to India, Chennai is a city with deep rooted values, rich culture and
friendly people. Overnight stay at Chennai.  
        </p>
        <h6>Day 03: Chennai-Kancheepuram-Mahabalipuram</h6>
        <p>
        After breakfast proceed for a trip to Kancheepuram (80 KM), the &#39;City of
Thousand Temples&#39;, considered as the second holiest place in India and
one of the seven sacred cities. It was successively the capital of the
Pallavas and the Cholas. This place is also famous for hand-woven silk
fabrics. <br/>
Later in the day drive to Mahabalipuram (80 KM). Mahabalipuram, once a
flourishing port of the Pallavas of ancient India. The monuments and
sculptures here are world-renowned.
<img className="athira-headingImg" src={ChennaiCochin2} alt="" />
<p>
Visit the Shore Temple, Arjuna&#39;s Penance and Artisans Village at
Mahabalipuram. Overnight stay at Mahabalipuram.   
</p>
        </p>
        <h6>Day 04: Mahabalipuram to Puducherry</h6>
        <p>
        After breakfast proceed to Puducherry (earlier called Pondicherry) (120
KM). This town, designed based on the French grid pattern and features,
has two sections: the French Quarter and the Indian quarter. In the French
quarter, the buildings are typically colonial style with long compounds and
stately walls.
        </p>
        <img className="athira-headingImg" src={ChennaiCochin3} alt="" />
        <p>
        The Indian quarter consists of houses lined with verandas and houses with
large doors and grills. The use of the French language can still be seen and
heard in Puducherry. Puducherry is one of the most popular tourist spots
for national and international tourists. There is also Sri Aurobindo Ashram,
where Sri Aurobindo spent his last years. There are several temples,
monuments, parks and mosques which attract tourists. Overnight at
Pondicherry.
        </p>
        <h6>Day 05: Puducherry-Chidambaram-Thanjavur </h6>
        <p>
        After breakfast check out the hotel and drive to Chidambaram (80 KM) and
have a glimpse of the town. Chidambaram highlights the beautiful temples,
it is the seat for the famous Nataraja temple. It is located in the center of
the town, covering an area of 40 acres. It was built by Kopperunjingan, who
ruled between 1229 AD and 1278 AD. Then drive to Thanjavur (120 KM).
Arrive at Thanjavur and check in to the hotel. Rest of the day free at
leisure. Overnight at Thanjavur.
        </p>
        <h6>Day 06: Thanjavur – Trichy - Chettinad</h6>
        <p>
        After breakfast proceed for sightseeing tour of Tanjore. Tanjore was once
the capital of Chola dynasty, that ruled South India between 10th and 14th
centuries. Visit the most spectacular Brihadeeswara Temple,capped by a
monolithic cupola made of a single granite rock weighing 80 tonnes.
        </p>
        <img className="athira-headingImg" src={ChennaiCochin4} alt="" />
        <p>
        Drive to Trichy (70 KM) Visit the magnificent Rock Fort that dominates the
city; 437 steps cut out into the rock side and interspersed with shrines, lead
to the Vinayaka Temple built at its highest point.
        </p>
        <img className="athira-headingImg" src={ChennaiCochin5} alt="" />
        <p>
        The ascent is climaxed by a magnificent view of the sacred Kaveri River. At
a lower level along the rock is the Tayumanaswamy Temple dedicated to
Lord Shiva. Later, visit Sriranqam, a massive temple on an island in the
Kauvery and dedicated to Lord Ranganathaswamy. Check in to hotel for
over night stay.
        </p>
        <h6>Day 07: Trichy - Chettinad</h6>
        <p>
        After breakfast proceed to Chettinad (100 KM). Visit bustling village built
over the last 200 years is a treasure trove of antiques and well-crafted
hand-made products ranging from Jewellery to Furniture. At least a dozen
villages are around Chettinad and Karaikudi having their own temples and
admiring the architectural heritage is very interesting. A stroll through the
rustic market will give you a close up cross section of village life. Chettinad
is also famous for its use of variety of spices used in preparing food,
especially chicken. Overnight at Chettinad.
        </p>

        <h6>Day 08: Chettinad - Madurai</h6>
        <p>
        After breakfast drive to Madurai (175 KM) and check in hotel. In the
afternoon visit the main attraction of Madurai - the great Sree Meenakshi
Temple, the finest example of Dravidian temple architecture. In the evening
attend the famous “Palli Arai” ceremony at Meenakshi Temple. Stay
overnight at Madurai.
        </p>

        <h6>Day 09: Madurai</h6>
        <p>
        One day is not enough to cover the beautiful temple. Enjoy the fabulous
collection of elaborately sculptured and painted deities in the hall of 1000
pillars. All hours of the day pilgrims from all over the world come to seek
the blessings of Meenakshi (Goddess Parvati) &amp; Sundareswarar (Lord
Shiva).
        </p>
        <img className="athira-headingImg" src={ChennaiCochin6} alt="" />
        <p>
        Witness Hindu priests performing various ceremonies in different parts of
the temple complex, including religious aspects of traditional weddings.
After visit to the temple,you can also visit nearby Thirumalai Nayak Palace
constructed in 1636 AD by an Italian architect, the palace is an interesting
confluence of Dravidian, Islamic &amp; European architectural styles. Overnight
at Madurai
        </p>

        <h6>Day 10: Madurai – Kanyakumari</h6>
        <p>
        Today proceed to the Southern tip of peninsular India Kanyakumari (250
KM), formerly known as Cape Comorin. Enjoy rest of the day at leisure.
Overnight at Kanyakumari
        </p>

        <h6>Day 11: Kanyakumari</h6>
        <p>
        The view of sunrise and sunset is the main attraction in Kanyakumari.
        </p>
        <img className="athira-headingImg" src={ChennaiCochin7} alt="" />
        <p>
        Visit rock memorial dedicated to Swami Vivekananda, the Gandhi
Memorial, Suchidram Temple with exquisite sculpture &amp; musical pillars
dedicated to Brahma, Vishnu &amp; Shiva. Stay at Kanyakumari
        </p>

        <h6>Day 12: Kanyakumari – Trivandrum – Kumarakom</h6>
        <p>
        Today proceed Trivandrum (90 KM). Visit Padmanabhaswamy temple,
Napier msueum etc... Later proceed to a lake side resort at Kumarakom
(180 KM). Enjoy your stay at this resort. And if required you can go for a
short drive along this coastal village or enjoy a walk along the paddy fields,
which resembles a wide stretch of green carpet all around. Over night stay
in the resort.
        </p>

        <h6>Day 13: Kumarakom - Alleppey</h6>
        <p>
        Today morning you embark on a houseboat at Kumarakom. Enjoy the
beauty of backwaters in Gods Own Country. Enjoy one day life in India&#39;s
largest lake &#39;Vembandu lake&#39;.
        </p>
        <img className="athira-headingImg" src={ChennaiCochin8} alt="" />
        <p>
        If required you can enjoy fishing (angling). And if fortunate you can ask the
chef to prepare it fresh for your lunch/dinner. Enjoy narrow canal cruise and
see the life of common man, majority belonging to to fishermen community.
Overnight stay on board
        </p>

        <h6>Day 14: Alleppey - Kochi</h6>
        <p>
        After breakfast disembark from the houseboat at Alleppey on the opposite
side of the lake and drive to Kochi (60 KM). Cochin-Once a much prized
pawn between the Portuguese, Dutch and English, Kochi or Cochin in the
Southern Indian state of Kerala has a history of being a premier port along
the fabled “spice route” to the east. Fort Cochin was a great emporium of
trade, a harbor filled with ships, streets crowded with merchants and
warehouses stored with goods from every port of Asia and Europe
        </p>
        <img className="athira-headingImg" src={GOC1} alt="" />
        <p>
        Enjoy full day sight seeing of Cochin. Visit Chinese Fishing Nets, St.
Francis Church, Dutch Palace (closed on Fridays) and Jewish
Synagogue.(closed on Fridays &amp; Saturdays and Jewish holidays). 
        </p>
        <img className="athira-headingImg" src={GOC2} alt="" />
        <p>
        You can also enjoy demonstration of Kathakali (traditional dance - drama
how of Kerala ) and also Kalaripayattu (martial art show) at cultural center
here. Overnight stay at Cochin.
        </p>

        <h6>Day 15: Departure</h6>
        <p>
        Our driver will drop you at Cochin airport at appropriate time
        </p>


        <h4>Properties foreseen: Premium</h4>
        <table>
            <thead>
                <tr>
                    <th>Destination</th>
                    <th>Property</th>
                    <th>No.of.nights</th>

                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Chennai</td>
                    <td>The Park</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Mahabalipuram</td>
                    <td>Taj Fishermens Cove</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Puducherry</td>
                    <td>Palais de Mahe</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Thanjavur</td>
                    <td>Svatma</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Chettinad</td>
                    <td>Visalam</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Madurai</td>
                    <td>Heritage Madurai</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Kanyakumari</td>
                    <td>Sparsa Resort</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Kumarakom</td>
                    <td>Coconut Lagoon</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Alleppey</td>
                    <td>Houseboat</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Cochin</td>
                    <td>Holiday Inn</td>
                    <td>1</td>
                </tr>
               
            </tbody>
        </table>

        <h4>Properties foreseen:</h4>
        <table>
            <thead>
                <tr>
                    <th>Destination</th>
                    <th>Property</th>
                    <th>No.of.nights</th>

                </tr>
            </thead>
            <tbody>
            <tr>
                    <td>Chennai</td>
                    <td>Accord Metropolitan</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Mahabalipuram</td>
                    <td>Radisson Blu Resort

Temple Bay</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Puducherry</td>
                    <td>Maison Perumal</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Thanjavur</td>
                    <td>Ideal River View</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Chettinad</td>
                    <td>Chidambara Vilas</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Madurai</td>
                    <td>Hotel Sangam</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Kanyakumari</td>
                    <td>Sea View</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Kumarakom</td>
                    <td>Lake Song</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Alleppey</td>
                    <td>Houseboat</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Cochin</td>
                    <td>Hotel Casino</td>
                    <td>1</td>
                </tr>
               
            </tbody>
        </table>
        
      </div>
      <EnquiryForm/>
      <Footer/>
    </div>
  )
}

export default ChennaiCochin