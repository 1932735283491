import React, { useEffect } from 'react'

import bangalore from '../Assets/bangalore.png'


import CommonNav from "../CommonNav/CommonNav";
import Footer from '../Footer/Footer'
import EnquiryForm from '../EnquiryForm/EnquiryForm'

function BangaloreKarnataka() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <div className="Athira-wrapper">
    <CommonNav />
    <div className="athira-MainHeading">
      <h1>India /Bangalore and Karnataka </h1>
      <p>
      Bangalore or Bengaluru is the capital of Karnataka state, located in the southern part of India. Bangalore
is the largest city in Karnataka and the center of India&#39;s booming IT industry.

      </p>
      <p>
      Tourism is an important industry in Karnataka, with the state offering a diverse range of experiences for
visitors.

      </p>
      <h6>Bengaluru</h6>
      <p>
      The capital city of Karnataka is a hub of technology and startup culture, but it also offers
several tourist attractions such as the Lalbagh Botanical Garden, Vidhana Soudha, and Bangalore Palace.
      </p>
      <img className="athira-headingImg" src={bangalore} alt="" />
      <h6>Mysuru</h6>
      <p>
      The cultural capital of Karnataka, Mysuru is known for its stunning palaces, including the
Mysore Palace, which is a major attraction for tourists. The city also offers several cultural experiences
such as the Mysuru Dasara festival.
      </p>
      <h6>Hampi</h6>
      <p>
      A UNESCO World Heritage Site, Hampi is a village that contains the ruins of the Vijayanagara
Empire, one of the largest and wealthiest empires in Indian history.

      </p>
      <h6> Coorg</h6>
      <p>
      Also known as Kodagu, Coorg is a scenic hill station known for its coffee plantations, waterfalls,
and natural beauty.
      </p>
      <h6>Kabini Wildlife Sanctuary</h6>
      <p>
      The Kabini Wildlife Sanctuary is a popular tourist destination that is home to a variety of wildlife such as
elephants, tigers, leopards, wild dogs, and deer. The sanctuary is located on the banks of the Kabini River.
The Kabini Wildlife Sanctuary offers a range of activities for visitors, including jungle safaris, boat rides,
and nature walks. Visitors can also stay at the various resorts and lodges located in and around the
sanctuary.
      </p>
      <h6>Bandipur National Park</h6>
      <p>
      A wildlife sanctuary located in the Chamarajanagar district, Bandipur is home to
a variety of wildlife such as tigers, elephants, and deer.
      </p>
    </div>
    <EnquiryForm/>
    <Footer/>
  </div>
  )
}

export default BangaloreKarnataka