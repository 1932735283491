import React, { useEffect } from "react";
import CommonNav from "../CommonNav/CommonNav";
import GOC1 from "../Assets/GOC1.png";
import GOC2 from "../Assets/Goc.png";
import GOC3 from "../Assets/GOC3.png";
import GOC4 from "../Assets/GOC4.png";
import GOC6 from "../Assets/GOC6.png";
import goc from "../Assets/Houeboat 1.jpeg.jpg";
import EnquiryForm from "../EnquiryForm/EnquiryForm";
import Footer from '../Footer/Footer'

function GOC() {
    useEffect(() => {
        window.scrollTo(0, 0);
      },[])
  return (
    <div className="Athira-wrapper">
      <CommonNav />
      <div className="athira-MainHeading">
        <h1>India / 7-Day Itinerary / Welcome to GODS OWN COUNTRY</h1>
        <h4>Suggested itinerary</h4>
        <h6>DAY 01: COCHIN</h6>
        <p>
        Later begin your tour of heritage town Fort Cochin and Mattancherry. Visit Jewish
Synagogue (closed on Fridays and Saturdays), which was built in 1568 and is still an active
house of worship for the few remaining Jews in the area. You can also see Dutch palace
(closed on Fridays) Next to the synagogue is Cochin’s ancient and colorful Jew Street with
its antique stores and souvenir peddlers. There is evidence of a Jewish presence in Kochi
from 70 AD. After the synagogue you visit the St. Francis Church, which was established by
the Portuguese Franciscan friars in 1503. This is one of the first European churches built in
India and the famed Vasco Da Gama was buried in its courtyard for a few years before his
remains were returned to Portugal.
        </p>
        <img className="athira-headingImg" src={GOC1} alt="" />
        <p>
        Later you have an option to visit the most photogenic of Cochin’s attractions, 15th century
Chinese fishing nets. You may see local fishermen using these massive nets with an ease
that comes from centuries of practice, the lowering and raising of the nets has a
synchronicity and rhythm that is quite an amazing experience to watch. Enjoy Kathakali
show (traditional dance-drama art form of Kerala) in a nearby cultural theatre. Overnight at
Cochin.   
        </p>
        <img className="athira-headingImg" src={GOC2} alt="" />
        <h6>DAY 02: COCHIN - MUNNAR</h6>
        <p>
        After breakfast check out and proceed to Munnar (140 KM / 5 hours). Enjoy the drive
through the tea mountains and the picturesque green carpet of the nature. On the way you
can watch Valara and Cheeyappara waterfalls and Karidippara view point. Upon reaching,
check into hotel. Overnight stay at Munnar.
        </p>
        <img className="athira-headingImg" src={GOC3} alt="" />
        <h6>Day 03: Munnar</h6>
        <p>
        Proceed for sightseeing trip of Munnar after breakfast. Visit the Blossom Garden, Tea
Museum (Closed on Mondays), Eravikulam National Park at Rajamlai (where you can see the
rare specious of Nilgiri Tahr), Mattupetty Dam which offers many fascinating sights to
relish, the Shola forests around Mattupetty area and Kundala Dam. (You can also visit Photo
Shooting Point, Echo Point, View Point &amp; the green carpet of Tea Gardens on the way.
Overnight stay at Munnar.

        </p>
        <h6>DAY 04: MUNNAR – THEKKADY/PERIYAR</h6>
        <p>
        Proceed to Thekkady (120 KM - 4 hours) after breakfast. Check into hotel; Visit the Periyar
Wild life Sanctuary.
        </p>
        <img className="athira-headingImg" src={GOC4} alt="" />
        <p>
        Go for a wildlife cruise to view a large variety of wild animal like Wild Elephants, Nilgiri
Langur, Sambar Deer, Wild Bison, Wild Boar etc... and quite a lot of unique birds can also
be spotted) Overnight stay at Thekkady.
        </p>
        <h6>Day 05: THEKKADY – ALLEPPEY </h6>
        <p>
        After breakfast drive to Alleppey (160 KM / 4 hrs). Upon reaching embark on a four bed
room house boat for your backwater cruise, a splendid area full of canals, lakes and coves.
Your houseboat or &#39;Kettuvalloms&#39; is a luxurious boat with well-appointed bedrooms and
bathrooms. Enjoy traditional food onboard. Apart from the mesmerizing surroundings, it&#39;s
the life along the backwaters that makes this a fascinating experience.
<img className="athira-headingImg" src={goc} alt="" />
 <br />
 Stay overnight on board.

        </p>
        <h6>DAY 06: ALLEPPEY – MARARI BEACH</h6>
        <p>
        After breakfast dis embark from the houseboat and drive to one of the most beautiful and
peaceful beach destinations in Kerala Marari (25 KM / 30 minutes). Here you check into a
beautiful resort. Enjoy the day at leisure. Relax your day at the beach. Enjoy your night stay
at this beach resort
        </p>
        <img className="athira-headingImg" src={GOC6} alt="" />
        <h6>DAY 07: MARARI - COCHIN </h6>
        <p>
        After breakfast you would be transferred to Cochin airport (100 km / 3 hrs) to board your
outbound flight. (If time permits we can enjoy some city sightseeing./shopping etc… in
Cochin)

        </p>

        <h4>Properties foreseen: Luxury properties</h4>
        <table>
            <thead>
                <tr>
                    <th>Destination</th>
                    <th>Property</th>
                    <th>No.of.nights</th>

                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Cochin</td>
                    <td>Brunton Boatyard</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Munnar</td>
                    <td>Ambadi Estate</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Thekkady</td>
                    <td>Spice Village</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Alleppey</td>
                    <td>Houseboat</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Marari</td>
                    <td>Xandari Pearl</td>
                    <td>1</td>
                </tr>
               
            </tbody>
        </table>

        <h4>Properties foreseen: Deluxe properties</h4>
        <table>
            <thead>
                <tr>
                    <th>Destination</th>
                    <th>Property</th>
                    <th>No.of.nights</th>

                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Cochin</td>
                    <td>Hotel Casino</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Munnar</td>
                    <td>Swiss County</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Thekkady</td>
                    <td>Hills &amp; Hues</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Alleppey</td>
                    <td>Houseboat</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Marari</td>
                    <td>Abad Turtle Beach</td>
                    <td>1</td>
                </tr>
               
            </tbody>
        </table>
        <h6>Cost includes:</h6>
        <ul>
            <li>Accommodation</li>
            <li>Daily breakfast and dinner</li>
            <li>All meals in houseboat</li>
            <li>Entire transportation by AC vehicle</li>
            <li>Toll, parking, driver bata</li>
            <li>English speaking guide at Cochin, Munnar and Thekkady</li>
            <li>Entry charges to all monuments</li>
            <li>Boat ride at Thekkady</li>
            <li>All applicable taxes</li>
            
        </ul>
        <h6>Cost excludes:</h6>
        <ul>
            <li>Flight tickets</li>
            <li>Any meals not mentioned above</li>
            <li>All other expenses which are not mentioned in the inclusions</li>
        </ul>

        <h6>Please note:</h6>
        <ul>
            <li>Check in / checkout time for house boat: 12 noon - 9 am </li>
            <li>Check in time at the hotel is 12.00 hrs and check out time is 11.00 hrs</li>
            <li>Booking confirmation is subject to availability. In case of non-availability of rooms at the
hotels mentioned, alternate hotels of similar category will be provided</li>
            <li>Vehicle cannot be used between 20:00 hrs and 08:00 hrs</li>

        </ul>
        
      </div>
      <EnquiryForm/>
      <Footer/>
    </div>
  )
}

export default GOC