import React,{useEffect} from 'react'
import CommonNav from "../CommonNav/CommonNav";

import kerala1 from "../Assets/kerala1.jpg";
import EnquiryForm from "../EnquiryForm/EnquiryForm";
import Footer from '../Footer/Footer'

function CochinChennai() {
    useEffect(() => {
        window.scrollTo(0, 0);
      },[])
  return (
    <div className="Athira-wrapper">
      <CommonNav />
      <div className="athira-MainHeading">
        <h1>India / 18-Day Itinerary / South India: Cochin To Chennai </h1>
        <img className="athira-headingImg" src={kerala1} alt="" />
        <h4>Suggested itinerary</h4>
        <h6>Day 1: Kochi</h6>
        <p>
        Upon arrival at Kochi, we will transfer you to the hotel (40 KM - 1 hour).
        </p>
        <h6>Day 2: Kochi</h6>
        <p>
        Cochin-Once a much prized pawn between the Portuguese, Dutch and English, Kochi or
Cochin in the Southern Indian state of Kerala has a history of being a premier port along
the fabled “spice route” to the east. Fort Cochin was a great emporium of trade, a harbor
filled with ships, streets crowded with merchants and warehouses stored with goods from
every port of Asia and Europe. Visit Chinese Fishing Nets, St. Francis Church, Dutch
Palace and Jewish Synagogue. (closed on Fri &amp; Sat ) Evening free for shopping. Overnight
at hotel
        </p>
        <h6>Day 3: Kochi – Alleppey</h6>
        <p>
        Check out from the hotel and drive to Alleppey (70 KM / 2 hours). Here you check into a
Houseboat. Enjoy the beauty of back waters in Kerala (The Gods Own Country). Sail
through narrow canals seeing the life of local people, majority of them belongs to the
fishermen community. Overnight stay on board.

        </p>
        <h6>Day 4: Alleppey – Kumarakom</h6>
        <p>
        After breakfast disembark from the houseboat at Kumarakom (on the opposite side of the
lake) and check into a beautiful resort. Relax your holiday. Later go out for a short drive -
visit Kumarakom birds sanctuary, enjoy a walk along the paddy fields, along the lake side
etc… Overnight at Kumarakom
        </p>
        <h6>Day 5: Kumarakom -Thekkady</h6>
        <p>
        After breakfast check out and proceed to Thekkady (130 KM / 4 hrs). Check in to hotel.
(Optional: afternoon boating in lake through the middle of Periyar Tiger Reserve Forest.)
Overnight at hotel.

        </p>
        <h6>Day 6: Thekkady</h6>
        <p>
        After breakfast, the whole day you can enjoy various other optional activities - elephant
ride, nature walk, forest safari, trekking etc... Overnight stay at Thekkady
        </p>
        <h6>Day 7: Thekkady - Munnar </h6>
        <p>
        After breakfast proceed to Munnar (120 KM / 4 hrs). A four-hour scenic drive through hill
station. Overnight stay at Munnar.
        </p>
        <h6>Day 8: Munnar</h6>
        <p>
        After breakfast proceed for a full day sight seeing at Munnar. Visit Mattuppetty dam,
Gundala lake, Eravikulam National park (if its open) etc. Overnight stay at Munnar.
 

        </p>
        <h6>Day 9: Munnar - Madurai</h6>
        <p>
        After an early breakfast check out and drive to Madurai (175 KM / 5-6 hours). Check into
hotel. In the afternoon visit the main attraction of Madurai - the great Sree Meenakshi
Temple, the finest example of Dravidian temple architecture. Witness Hindu priests
performing various ceremonies in different parts of the temple complex. In the evening
attend the famous “Palli Arai” ceremony at Meenakshi Temple. Stay overnight at Madurai.
        </p>
        <h6>Day 10: Madurai</h6>
        <p>
        You can visit Thirumalai Nayak Palace constructed in 1636 AD by an Italian architects. You
would be dropped at Madurai airport/railway station at appropriate time
        </p>
        <h6>Day 11: Madurai - Tiruchirapalli</h6>
        <p>
        After breakfast proceed to Tiruchirapalli (150 KM / 4 hours). Visit the magnificent Rock
Fort that dominates the city.437 steps cut out into the rock side and interspersed with
shrines, lead to the Vinayaka Temple built at its highest point. The ascent is climaxed by a
magnificent view of the sacred Kaveri River. At a lower level along the rock is the
Tayumanaswamy Temple dedicated to Lord Shiva. Later, visit Sriranqam, a massive temple
on an island in the Kauvery and dedicated to Lord Ranganathaswamy. Over night stay at
Tiruchirapalli.
        </p>
        <h6>Day 12: Tiruchirapalli - Tanjore</h6>
        <p>
        After breakfast proceed to Tanjore (70 KM / 1.30 hrs). Tanjore was once the capital of
Chola dynasty, that ruled South India between 10th and 14th cent uries. Visit the most
spectacular Brihadeeswara Temple,capped by a monolithic cupola made of a single granite
rock weighing 80 tonnes. Overnight at Tanjore.
        </p>
        <h6>Day 13: Tanjore – Puducherry</h6>
        <p>
        Proceed to Puducherry (200 KM / 5-6 hrs), after breakfast. City of Pondicherry was
designed based on the French grid pattern and features. The town has two sections: the
French Quarter and the Indian quarter. In the French quarter, the buildings are typically
colonial style with long compounds and stately walls. The Indian quarter consists of houses
lined with verandas and houses with large doors and grills. The use of the French language
can still be seen and heard in Puducherry. Overnight at Puducherry.
        </p>
        <h6>Day 14: Puducherry</h6>
        <p>
        Puducherry is one of the most popular tourist spots for national and international tourists.
There are several beaches here. There is also Sri Aurobindo Ashram, where Sri Aurobindo
spent his last years. There are several temples, monuments, parks and mosques which
attract tourists. Overnight at Puducherry.
        </p>
        <h6>Day 15: Puducherry – Mahabalipuram</h6>
        <p>
        Today drive to Mahabalipuram (120 KM / 4 hours). Mahabalipuram was once a flourishing
port of the Pallavas of ancient India. The monuments and sculptures here are world-
renowned. Visit the Shore Temple, Arjuna&#39;s Penance and Artisans Village at
Mahabalipuram. Overnight stay at Mahabalipuram.
        </p>
        <h6>Day 16: Mahabalipuram</h6>
        <p>
        Enjoy and explore the beautiful beaches in Mahabalipuram. Relax your holiday at this
beautiful beach destination. Stay at Mahabalipuram.
        </p>
        <h6>Day 17: Mahabalipuram - Chennai</h6>
        <p>
        Proceed to Chennai (60 KM / 1 hour). Chennai is a perfect blend of traditionalism and a
bustling cosmopolitan life. The major attraction of Chennai are its sprawling golden
beaches, the most popular among them being the picturesque Marina Beach that also
boasts of being the second longest beach in the world. Visit the Kapaleeshwarar Temple
dedicated to Lord Shiva and drive past the majestic Fort St.George and Santhome
Cathedral. Later visit the Government Museum and Art Gallery, has a good collection of
paintings and sculptures, both traditional and modern. Chennai is a city with deep rooted
values, rich culture and friendly people. Overnight stay at Chennai.
        </p>
        <h6>Day 18: Chennai (departure)</h6>
        <p>
        You would be dropped at Chennai airport to catch your return flight.
        </p>

               
      </div>
      <EnquiryForm/>
      <Footer/>
    </div>
  );
}

export default CochinChennai