import React, { useEffect } from 'react'

import Footer from "../Footer/Footer";
import CommonNav from "../CommonNav/CommonNav";
import EnquiryForm from '../EnquiryForm/EnquiryForm';

function StressBuster() {
    useEffect(() => {
        window.scrollTo(0, 0);
      },[])
  return (
    <div className="Athira-wrapper">
    <CommonNav />
    <div className="athira-MainHeading">
      <h1>India  / Kerala: Stress Buster Trail </h1>

      <h4>Skeleton programme</h4>
      <h6>Day 01: Arrival at Kochi</h6>
      <p>
      Sightseeing of heritage town Fort Kochi and Mattancherry
      </p>
      <h6>Day 02: Cochin to Munnar (135 KM - 4 hrs)</h6>
      <p>
      Upon reaching, check into a resort situated in the middle of tea estate
Stay at Munnar
      </p>
      <h6>Day 03: Munnar</h6>
      <p>
      Sightseeing of Munnar: Mattupetty dam, Kundala lake, Eravikulam national park, tea museum
etc… are the very few in the long list <br />
In the evening enjoy a barbecue session. <br />
Stay at Munnar

      </p>
      <h6>Day 04: Munnar – Kumarakom (180 KM – 5 hrs)</h6>
      <p>
      Check into a beautiful lake side resort. (Optional: Enjoy ayurveda session). Stay at Kumarakom

      </p>
      <h6>Day 05: Kumarakom</h6>
      <p>
      Today enjoy lunch at a farm house (nearby Kumarakom) Along with lunch, enjoy a walk long
some plantation - bananas, coconut trees, vegetables etc… and see the farm animals. Stay at
Kumarakom

      </p>
      <h6>Day 06: Kumarakom - Cochin (70 KM – 2 hrs) – Cherai beach (45 KM – 1 hr)</h6>
      <p>
      After breakfast check out and proceed to Cochin. Enjoy some shopping. Walk along marine
drive. (Optional: If required you can enjoy harbor cruise. Enjoy a toddy session). Later check
into beautiful resort at Cherai beach. Stay at Cherai
      </p>
      <h6>Day 07: Departure</h6>
      <p>
      Drop at Cochin airport at appropriate time

      </p>
     
      <h4>Properties foreseen</h4>
      <table>
          <thead>
              <tr>
                  <th>Destination</th>
                  <th>No.of.nights</th>

              </tr>
          </thead>
          <tbody>
              <tr>
                  <td>Cochin</td>
                  <td>1</td>
              </tr>
              <tr>
                  <td>Munnar</td>
                  <td>2</td>
              </tr>
              <tr>
                  <td>Kumarakom</td>
                  <td>2</td>
              </tr>
              <tr>
                  <td>Cherai</td>
                  <td>1</td>
              </tr>
          </tbody>
      </table>
      <h6>Cost includes:</h6>
      <ul>
          <li>Accommodation in one twin/double room</li>
          <li>Daily breakfast</li>
          <li>One barbecue session followed by dinner at Munnar (day 3)</li>
          <li>Farm visit and lunch at Kumarkaom (day 5)</li>
          <li>Toddy session and lunch at Cochin (day 6)</li>
          <li>Sunset cruise at Kumarakom</li>
          <li>Transportation by AC Innova</li>
          <li>Toll, parking and all charges for driver</li>
          <li>All applicable taxes</li>
      </ul>
      <h6>Cost excludes:</h6>
      <ul>
          <li>Flight / Train ticketsn</li>
          <li>Cost for any meals other than those mentioned above</li>
          <li>Charges for guide, entry, any optional activities</li>
          <li>All other expenses which are not mentioned in the inclusions </li>
      </ul>


    </div>
    <EnquiryForm/>
    <Footer/>
  </div>
  )
}

export default StressBuster