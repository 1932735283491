import React from 'react'

function Booking() {
  return (
    <div className="enquiryWraper">
          <form >
      <div className="enquiryField">
      <label  htmlFor='name' >Name</label>
<input type="text"   />
      </div>
      <div className="enquiryField">
      <label  htmlFor='email' >Email id</label>
<input type="email" />
      </div>
      <div className="enquiryField">
      <label  htmlFor='contactNo' >Contact number</label>
<input type="number"  />
      </div>
      <div className="enquiryField">
      <label  htmlFor='prefferedDate' >Preferred date</label>
<input type="date"   />
      </div>
      <div className="enquiryField">
      <label  htmlFor='duration' >Duration</label>
<input type="number" placeholder='No.of Days'   />
      </div>
      <div className="enquiryField">
      <label  htmlFor='prefferedDestination' >Preferred destinations</label>
      <textarea rows="2"  ></textarea>
      </div>

<div className="enquiry-submit">
<button type="submit">Submit</button>
</div>

    </form>
      </div>
  )
}

export default Booking