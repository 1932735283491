import React, { useEffect } from 'react'

import godsowncountry from '../Assets/godsowncountry.jpg'
import goc1 from '../Assets/goc1.cms'
import goc3 from '../Assets/Houeboat 1.jpeg.jpg'
import goc4 from '../Assets/goc4.jpg'
import goc5 from '../Assets/goc5.png'
import goc6 from '../Assets/goc6.jpg'
import teakady from '../Assets/teakady.png'

import CommonNav from "../CommonNav/CommonNav";
import Footer from '../Footer/Footer'
import EnquiryForm from '../EnquiryForm/EnquiryForm'

function GodsOwnCountry() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <div className="Athira-wrapper">
    <CommonNav />
    <div className="athira-MainHeading">
      <h1>India /Kerala: Gods Own Country </h1>
      <img className="athira-headingImg" src={godsowncountry} alt="" />
      <p>
      Various tourist destinations definitely makes a traveller more confusing, while planning a trip to Kerala, the Gods Own Country. Here we have hill stations and green jungles, scenic beauties, pristine beaches and certainly the key player backwaters.

      </p>
      <h4>Pleased to give a brief idea</h4>
      <h6>Munnar</h6>
      <img className="athira-headingImg" src={goc1} alt="" />
      <p>
      Munnar is one of the most beautiful hill stations in Kerala. You can see the wide spread greenery, as the entire region is cultivated with tea plants. Even worlds highest tea estate Kolukkumalai tea estate, which is about 8000 ft above sea level, is located here. A minimum stay of 2 nights is preferred to complete the sightseeing including – gardens, tea mountains, dams etc…being very few
      </p>
      <h6>Thekkady</h6>
      <img src={teakady} alt="" />
      <p>
      Thekkady is another hill station here, and is famous for Periyar Tiger Reserve forest.  Spotting of elephants, bison, boar etc…  while enjoying a wild cruise in Periyar Dam is the main attraction here. You can enjoy various activities here – visit to spice plantation, agriculture tour and jeep ride (game drive), elephant ride etc… are only a very few in the long list.
      </p>
      <h6>Alleppey</h6>
      <img className="athira-headingImg" src={goc3} alt="" />
      <p>
      Main attraction of globetrotters visiting Kerala is to enjoy backwaters, for which Alleppey is famous for. A stay on houseboat is the main attraction. Houseboat is nothing but a boat house (or an house on a boat) It has bath attached well-furnished bed rooms, sit out (open sitting area), a kitchen and butler/chef services etc… A visit to Kerala would be incomplete without a cruise or stay on a houseboat

      </p>
      <h6> Kumarakom</h6>
      <img className="athira-headingImg" src={goc4} alt="" />
      <p>
      The sprawling Vembanad lake, the largest in Kerala, helped another village to become prominent in tourist map, Kumarakom. Both Alleppey and Kumarakom, located on two opposite side of the lake provide almost the same feel. At Kumarakom we suggest you to relax your holiday enjoying the beauty of backwaters. You can also go for a rejuvenating Ayurveda massage.
      </p>
      <h6>Cochin</h6>
      <img className="athira-headingImg" src={goc5} alt="" />
      <p>
      Cochin, the financial capital of Kerala, attracts both domestic and inbound tourists. With metro train, Lulu mall - one of the largest in South India and the marine drive it attracts everyone. You can also enjoy tour of heritage towns Fort Cochin and Mattancherry. Visit Jewish Synagogue (closed on Fridays and Saturdays), which was built in 1568, day long shopping at Jew Street, St. Francis Church, one of the first European churches built in India.
      </p>
      <h6>Marari</h6>
      <img className="athira-headingImg" src={goc6} alt="" />
      <p>
      Along with these we can also suggest Marari one of the most beautiful beach destinations in Gods Own Country
      </p>
    </div>
    <EnquiryForm/>
    <Footer/>
  </div>
  )
}

export default GodsOwnCountry