import React, { useEffect } from "react";

import periyar from '../Assets/periyar.jpg'
import Footer from "../Footer/Footer";
import CommonNav from "../CommonNav/CommonNav";
import EnquiryForm from "../EnquiryForm/EnquiryForm";


function Periyar() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <div className="Athira-wrapper">
      <CommonNav />
      <div className="athira-MainHeading">
        <h1>India / Kerala/ Spice plantation visit @ Periyar</h1>
<img src={periyar} alt="" />
        <p>
          Spices have always been an important part of life in Kerala. Here the
          masters of ayurveda, the science of life, discovered the use of spices
          for medicinal purposes. Spices also play an important role in the
          Kerala cuisine, non-ayruvedic medicine and cosmetics. Periyar, due to
          its location in the lower mountains around 1000 metres above sea
          level, blessed with numerous types of spices. Consequently, the spice
          plantation tour is in one of the most important activities outside the
          reserve that a visitor can engage in. It is an opportunity to see why
          the Kerala cuisine is so different from most of the other Indian
          cuisines. The tour gives you a chance to enjoy cardamom, pepper,
          clove, cinnamon, nutmeg, vanilla and many other varieties of spices
          and medicinal plants
        </p>
      </div>
      <EnquiryForm/>
      <Footer />
    </div>
  );
}

export default Periyar;
