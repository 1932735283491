import React, { useEffect } from 'react'

import CommonNav from "../CommonNav/CommonNav";
import Footer from '../Footer/Footer'
import Moogambika from '../Assets/Mookambika.jpg'
import EnquiryForm from '../EnquiryForm/EnquiryForm';

function MangaloreMoogambika() {
    useEffect(() => {
        window.scrollTo(0, 0);
      },[])
  return (
    <div className="Athira-wrapper">
    <CommonNav />
    <div className="athira-MainHeading">
      <h1>India / 3-Day Itineraries / Mangalore &amp; Moogambika </h1>
      <img src={Moogambika} alt="" />
      <h4>Suggested itinerary</h4>
      <h6>Day 01:</h6>
      <p>
      Upon arrival at Mangalore railway station/airport, transfer them to city hotel. Later
drive to Moogambika temple (140 KM). After darshan return back to the hotel (140 KM). Stay at
Mangalore
      </p>
      <h6>Day 02:</h6>
      <p>
      Enjoy full day sightseeing of Mangalore. Main sightseeing places: Pilikula
Nisargadhama, Ullal beach, Mangaladevi temple, Panambur beach, Tannirbhavi beach etc…
Overnight stay at Mangalore
      </p>
      <h6>Day 03:</h6>
      <p>
      Drop the guest at Mangalore airport/railway station at appropriate time

      </p>
          <h4>Properties foreseen (Room category):</h4>
      <table>
          <thead>
              <tr>
                  <th>Destination</th>
                  <th>Property</th>

              </tr>
          </thead>
          <tbody>
              <tr>
                  <td>Mangalore</td>
                  <td>Hotel Goldfinch</td>
              </tr>
          </tbody>
      </table>
      <h6>Cost includes:</h6>
      <ul>
          <li>Accommodation</li>
          <li>Daily breakfast</li>
          <li>Full day guided tour of Mangalore (Day 02)</li>
          <li>Entry to all monuments</li>
          <li>Transportation by AC vehicle</li>
          <li>Toll, parking and all charges for driver</li>
          <li>All applicable taxes</li>
      </ul>
      <h6>Cost excludes:</h6>
      <ul>
          <li>Flight tickets</li>
          <li>Charges for any optional activities, tip, laundry, telephone charges etc....</li>
          <li>All other expenses which are not mentioned in the inclusions </li>
      </ul>

      
    </div>
    <EnquiryForm/>
    <Footer/>
  </div>
  )
}

export default MangaloreMoogambika