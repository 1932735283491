import React, { useEffect } from "react";
import CommonNav from "../CommonNav/CommonNav";
import EnquiryForm from "../EnquiryForm/EnquiryForm";
import Footer from '../Footer/Footer'

import munnar from '../Assets/Munnar.jpg'

function GodsOwn() {
    useEffect(() => {
        window.scrollTo(0, 0);
      },[])
  return (
    <div className="Athira-wrapper">
      <CommonNav />
      <div className="athira-MainHeading">
        <h1>India / 5-Day Itinerary / Gods Own Country</h1>
        <h4>Suggested itinerary</h4>
        <h6>Day 01: Kochi - Munnar</h6>
        <p>
        Upon arrival at Kochi, we will transfer youto Munnar (140 KM). A five-hour scenic drive
through hill station. On the way visit Valara, Cheeyappara waterfalls, Karadippara view point
etc. Check in to resort. Overnight stay at Munnar
        </p>
        <img src={munnar} alt="" />
        <h6>Day 2: Munnar</h6>
        <p>
        After breakfast proceed for a full day sight seeing of Munnar. Visit Mattuppetty dam, Gundala
lake, Eravikulam National park (if its open) etc. Overnight stay at Munnar.
        </p>
        <h6>Day 3: Munnar - Alleppey</h6>
        <p>
        Check out from the hotel and drive to Alleppey (225 KM). Here you check into a houseboat.
Enjoy cruise. Enjoy the beauty of backwaters in Gods Own Country. Overnight stay in houseboat
        </p>
        <h6>Day 4: Alleppey – Cherai beach</h6>
        <p>
        Disembark from the houseboat after breakfast and proceed to Cherai beach (90 KM/ 2.30
minutes) Here you check into a beautiful resort. Enjoy the whole day at the sprawling beach.
Overnight stay at this beach destination
        </p>
        <h6>Day 5: Cherai – Kochi (departure)</h6>
        <p>
        After breakfast check out from the resort. (If time permits - enjoy sightseeing of Fort Kochi and
Mattancherry - Dutch palace, St&#39;Francis church, Jewish synangogue, Jew Street, Chinese fishing
nets etc...) You would be dropped at Kochi airport (50 KM - 2 hours) at appropriate time
        </p>
       
      </div>
      <EnquiryForm/>
      <Footer/>
    </div>
  )
}

export default GodsOwn