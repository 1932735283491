import React, { useEffect } from 'react'
import CommonNav from '../CommonNav/CommonNav'
import chennai from '../Assets/chennai.jpeg'
import homestay from '../Assets/homestay.jpeg'
import goa5 from '../Assets/goa5.jpg'
import hillstations from '../Assets/hillstations.jpg'
import tajMahal from "../Assets/tajMahal.webp";
import GOC3 from '../Assets/Goc.png'
import goc from '../Assets/goc.jpg'
import Munnar from '../Assets/Trekking-In-Munnar.jpg'
import ChennaiCochin6 from '../Assets/ChennaiCochin6.png'
import StressBuster2 from '../Assets/StressBuster2.jpg'
import kumarakam from '../Assets/kumarakam.jpg'
import mattanchery from '../Assets/mattanchery.jpg'
import kodaikanal from '../Assets/kodaikanal.jpg'
import tamilNadu from '../Assets/Tamilnadu.jpg'
import kashmir from '../Assets/kashmir11.jpg'
import teakady from '../Assets/kerala.jpg'
import goaSurfing from '../Assets/goaSurfing.jpg'
import ayurveda from '../Assets/ayurveda.jpg'
import Footer from '../Footer/Footer';

function Iteneraries() {
    useEffect(() => {
        window.scrollTo(0, 0);
      },[])
      return (
        <div className='Destinations-wrapper'>
        <CommonNav/>
        <div className="destination-cards">
    
          <div className="destinations-card">
          <a className='destinations-cardLink'  href='/#/CochinChennai'>
          <img className='destinations-cardImg' src={chennai} alt="" />
          <div className="destination-cardDescription">
              <h1>South India :
              Cochin To Chennai</h1>  
            </div>
            </a>
          </div>

          <div className="destinations-card">
          <a className='destinations-cardLink'  href='/#/SouthHomestay'>
          <img className='destinations-cardImg' src={homestay} alt="" />
          <div className="destination-cardDescription">
              <h1>Welcome to South : <br />
              Homestay Trip</h1> 
            </div>
            </a>
          </div>

          <div className="destinations-card">
          <a className='destinations-cardLink'  href='/#/GoaKochi'>
          <img className='destinations-cardImg' src={goa5} alt="" />
          <div className="destination-cardDescription">
              <h1>Goa To Kochi</h1>  
            </div>
            </a>
          </div>


          <div className="destinations-card">
          <a className='destinations-cardLink'  href='/#/hillsRinse'>
          <img className='destinations-cardImg' src={hillstations} alt="" />
          <div className="destination-cardDescription">
              <h1>Hills & Rinse</h1>  
            </div>
            </a>
          </div>

          <div className="destinations-card">
          <a className='destinations-cardLink'  href='/#/HolidaysInIndia'>
          <img className='destinations-cardImg' src={tajMahal} alt="" />
          <div className="destination-cardDescription">
          <h1>Holidays in India : <br />
              29 nights &amp; 30 days</h1>
            </div>
            </a>
          </div>


          <div className="destinations-card">
          <a className='destinations-cardLink'  href='/#/GOC'>
          <img className='destinations-cardImg' src={GOC3} alt="" />
          <div className="destination-cardDescription">
          <h1>Welcome to Gods Own Country</h1>
            </div>
            </a>
          </div>

          
          <div className="destinations-card">
          <a className='destinations-cardLink'  href='/#/ChennaiCochin'>
          <img className='destinations-cardImg' src={ChennaiCochin6} alt="" />
          <div className="destination-cardDescription">
          <h1>Chennai To Cochin</h1>
            </div>
            </a>
          </div>
    

          <div className="destinations-card">
          <a className='destinations-cardLink'  href='/#/GoldenTriangleGOC'>
          <img className='destinations-cardImg' src={goc} alt="" />
          <div className="destination-cardDescription">
          <h2>Golden triangle & Gods Own Country</h2>
            </div>
            </a>
          </div>

          <div className="destinations-card">
          <a className='destinations-cardLink'  href='/#/ChennaiMunnar'>
          <img className='destinations-cardImg' src={Munnar} alt="" />
          <div className="destination-cardDescription">
          <h1>Mix Of Chennai & Kerala</h1>
            </div>
            </a>
          </div>

          <div className="destinations-card">
          <a className='destinations-cardLink'  href='/#/destination/StressBuster'>
          <img className='destinations-cardImg' src={StressBuster2} alt="" />
          <div className="destination-cardDescription">
          <h1>Stress Buster Trail</h1>
            </div>
            </a>
          </div>

          <div className="destinations-card">
          <a className='destinations-cardLink'  href='/#/OverToSouth'>
          <img className='destinations-cardImg' src={kumarakam} alt="" />
          <div className="destination-cardDescription">
          <h1>Over To South</h1>
            </div>
            </a>
          </div>

          <div className="destinations-card">
          <a className='destinations-cardLink'  href='/#/GodsOwnCountry'>
          <img className='destinations-cardImg' src={mattanchery} alt="" />
          <div className="destination-cardDescription">
          <h2>4N-5D in Gods Own Country</h2>
            </div>
            </a>
          </div>

          <div className="destinations-card">
          <a className='destinations-cardLink'  href='/#/KarnatakaTamilNadu'>
          <img className='destinations-cardImg' src={kodaikanal} alt="" />
          <div className="destination-cardDescription">
          <h1>Karnataka & Tamil Nadu</h1>
            </div>
            </a>
          </div>

          <div className="destinations-card">
          <a className='destinations-cardLink'  href='/#/KATNKL'>
          <img className='destinations-cardImg' src={tamilNadu} alt="" />
          <div className="destination-cardDescription">
          <h1>Karnataka , Tamil Nadu & Kerala</h1>
            </div>
            </a>
          </div>
          
          <div className="destinations-card">
          <a className='destinations-cardLink'  href='/#/destination/Kashmir'>
          <img className='destinations-cardImg' src={kashmir} alt="" />
          <div className="destination-cardDescription">
          <h1>Kashmir</h1>
            </div>
            </a>
          </div>
          
          <div className="destinations-card">
          <a className='destinations-cardLink'  href='/#/destination/KeralaMadurai'>
          <img className='destinations-cardImg' src={teakady} alt="" />
          <div className="destination-cardDescription">
          <h1>Kerala & Madurai</h1>
            </div>
            </a>
          </div>

          <div className="destinations-card">
          <a className='destinations-cardLink'  href='/#/destination/Goa'>
          <img className='destinations-cardImg' src={goaSurfing} alt="" />
          <div className="destination-cardDescription">
          <h1>Short tour of Goa</h1>
            </div>
            </a>
          </div>

          <div className="destinations-card">
          <a className='destinations-cardLink'  href='/#/WellnessAyurveda'>
          <img className='destinations-cardImg' src={ayurveda} alt="" />
          <div className="destination-cardDescription">
          <h1>Wellness And Ayurveda</h1>
            </div>
            </a>
          </div>
          
    
    
    
    </div>
         <Footer/>
        </div>
      )
}

export default Iteneraries