import React from 'react'
import CommonNav from '../CommonNav/CommonNav'
import Footer from '../Footer/Footer'

import './Contact.css'
import ContactQuery from './ContactQuery'
import { useEffect } from 'react'

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <>
        <CommonNav/>
    <div className='ContactWraper'>
    <div className="ContactContainer">
    <h1>Learning Trails</h1>
        <p>KRA 16</p>
        <p>Opp: Rotary Club</p>
        <p>Kanjirapally P.O.</p>
        <p>Kottayam</p>
        <p>Kerala</p>
        <p>India</p>
        <p>Pincode - 686507</p>
        <div className="footer-contact-email">
          <p>E-mail : thomas@learningtrails.in</p>
        </div>
        <div className="footer-contact-email">
          <p>Phone (India) : +91 999 575 2517</p>
        </div>
    </div>
<ContactQuery/>
    </div>
    <Footer/>
    </>
  )
}

export default Contact