import React, { useEffect } from "react";
import CommonNav from "../CommonNav/CommonNav";
import ChennaiMunnar1 from '../Assets/ChennaiMunnar1.png'
import ChennaiMunnar2 from '../Assets/athirapally2.jpg'
import ChennaiMunnar3 from '../Assets/ChennaiMunnar3.png'
import EnquiryForm from "../EnquiryForm/EnquiryForm";
import Footer from '../Footer/Footer'

function ChennaiMunnar() {
    useEffect(() => {
        window.scrollTo(0, 0);
      },[])
  return (
    <div className="Athira-wrapper">
      <CommonNav />
      <div className="athira-MainHeading">
        <h1>India / 7-Day Itineray / Mix of Chennai and Kerala</h1>
        <h4>Chennai itinerary</h4>
        <h6>Day 01:</h6>
        <p>
        Pick up from hotel and full day sightseeing <br />
Sightseeing of Chennai –Kapaleeswara Temple, Parthasarathi Temple, Santhome Church, Fort St. George
complex - Fort Museum &amp; St.Mary&#39;s Church, Government Museum, Marina Beach. Drive along High Court
building, Chennai Central Railway Station and Rippon Building <br />
In the evening drop at the hotel
        </p>
        <h6>Day 02:</h6>
        <p>
        Pick up from hotel and full day sightseeing
Sightseeing of Chennai –Kapaleeswara Temple, Parthasarathi Temple, Santhome Church, Fort St. George
complex - Fort Museum &amp; St.Mary&#39;s Church, Government Museum, Marina Beach. Drive along High Court
building, Chennai Central Railway Station and Rippon Building
In the evening drop at the hotel
        </p>
        <h6>Day 03:</h6>
        <p>
        Morning drop at Chennai airport <br />
        </p>
        <h6>Kerala Itinerary</h6>
        <p>
Upon arrival at Cochin airport, we will transfer you to the hotel at Cochin (40 KM – 1.30
hrs). Enjoy the whole day at leisure. Enjoy a short drive along the heritage town Fort Kochi and
Mattancherry. Visit Jewish synagogue, St’Francis church (one of the oldest European churches in India)
etc… Later in the evening you can visit Chottanikkara temple (Please follow dress code). Enjoy shopping in
Broadway. Overnight stay at Cochin.
        </p>
        <img className="athira-headingImg" src={ChennaiMunnar1} alt="" />
        <h6>Day 04:</h6>
        <p>
        After breakfast checkout and proceed to one of the famous waterfall destinations Athirapally (90 KM / 2.30
hrs). This beautiful spot, one of the largest waterfalls in South India, has been featured in several movies
(Ravan – Tamil, Guru – Hindi etc…). Upon reaching, check into a resort. Later go out for a short drive and
enjoy the waterfalls. Visit the nearby Vazhachal falls too. Stay in the resort. Overnight at Athirapally.
<img className="athira-headingImg" src={ChennaiMunnar2} alt="" />
        </p>
        <h6>Day 05:</h6>
        <p>
        After breakfast proceed to Munnar (180 KM – 5 hrs). On the way you can see the rubber and pineapple
plantations, the lovely waterfalls in Cheeyappara and Valara and the Karadippara view point. Enjoy the drive
through the tea mountains and the picturesque green carpet of the nature. Overnight stay at Munnar.
<img className="athira-headingImg" src={ChennaiMunnar3} alt="" />

        </p>
        <h6>Day 06:</h6>
        <p>
        Proceed for sightseeing trip of Munnar after the breakfast; Visit Tat Tea Musuem, Eravikulam National
Park at Rajamlai where you can see the rare specious of Nilgiri Tahr, Mattupetty Dam which offers many
fascinating sights to relish, the Shola forests around Mattupetty and Kundala Dam. If time permits, the
guide will take you to more spots - Photo Shooting Point, Echo Point, View Point &amp; the green carpet of
Tea Gardens on the way. Overnight stay at Munnar.
        </p>
        <h6>Day 07: Munnar – Cochin airport –Departure</h6>
        <p>
        Check out after the breakfast and drop at Cochin airport (110 KM –3 hrs)
        </p>

        <table>
            <thead>
                <tr>
                    <th>Destination</th>
                    <th>No.of.nights</th>

                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Cochin</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Athirapally</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Munnar</td>
                    <td>2</td>
                </tr>
               
            </tbody>
        </table>

   
        <h6>Cost includes:</h6>
        <ul>
            <li>Accommodation Daily breakfast &amp; dinner</li>
            <li>All meals at Rainforest Resort Athirapally</li>
            <li>Entry to all monuments</li>
            <li>Full day guided tour of Cochin</li>
            <li>Full day guided tour of Munnar</li>
            <li>Transportation by AC vehicle</li>
            <li>Toll, parking and all charges for driver</li>
            <li>All applicable taxes</li>
            
        </ul>
        <h6>Cost excludes:</h6>
        <ul>
            <li>Flight tickets</li>
            <li>Charges for any optional activities Tip,
laundry, telephone charges etc....</li>
            <li>All other expenses which are not mentioned in the inclusions</li>
        </ul>

        
      </div>
      <EnquiryForm/>
      <Footer/>
    </div>
  )
}

export default ChennaiMunnar