import React, { useEffect } from "react";
import CommonNav from "../CommonNav/CommonNav";
import EnquiryForm from "../EnquiryForm/EnquiryForm";
import Footer from "../Footer/Footer";

function KumarakamVV() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <div className="Athira-wrapper">
      <CommonNav />
      <div className="athira-MainHeading">
        <h1>
          India / Kerala/ Kumarakom - Responsible Tourism programme or Village
          visit
        </h1>
        <p>
          Kumarakom is a cluster of small picturesque islands around the
          Vembanad Lake. The village is well acclaimed for its backwaters,
          delicious cuisines and greenery. This tourist destination, over the
          years, is one of the most-visited in Kerala.
        </p>
        <h5>Our driver will drop you at starting point </h5>
        <h6>STOPOVER 1 :CANOING BOAT/SHIKHARA BOAT</h6>
        <p>
          Your journey begins in a country boat. Proceed over a lake flanked by
          greens, to the manjira village. Get a grand reception from villagers .
        </p>
        <h6>STOPOVER 2: AT THE TOP OF A COCONUT TREE</h6>
        <p>
          Learn how the times changed, as Ajeesh shows you two ways of climbing
          a coconut tree-the laborious traditional way using a thalappu and the
          modern way using latest instruments. Celebrate the end of class by
          treating yourself to fresh tender coconut
        </p>
        <h6>STOPOVER 3 : TASTE OF TENDER COCONUT</h6>
        <p>
          Enjoy the beauty of lush green paddy fields at Sabu’s house. In his
          coconut grove, you will get to taste some sweet coconut water and
          tender coconut. Then you head to Ajeesh’s home.
        </p>
        <h6>STOPOVER 4 ; TODDY TAPPING</h6>
        <p>
          Proceed across paddy fields to reach the next stopover-a coconut
          grove. Taste the famed Kerala beverages, toddy, and fresh out of the
          pot. Watch toddy being nearby, and learn about unique plants and
          spices.
        </p>
        <h6>STOPOVER 5 ; GOWRI’S HOME</h6>
        <p>
          Gowri takes you through the art of weaving coconut leaves. Then she
          lets you try your hand at it.
        </p>
        <h6>STOPOVER 6 : NET FISHING</h6>
        <p>
          Suresh a skilled fisherman of manjira, will teach you some
          tried-and-true fishing tips. Watch in awe as he casts a net and
          catches a few with ease. We then proceed to Latha’s home in a little
          boat.
        </p>
        <h6>STOPOVER 7 : LATHA’S HOUSE</h6>
        <p>
          Latha’s home is where you get to watch the fascinating process of
          coir-making. Check out the various steps involved and try your hand at
          coir spinning.
        </p>
        <h6>STOPOVER 8 : WORLD OF SCREW PINES</h6>
        <p>
          Learn from omana how screw pine products are made. Don’t forget to buy
          the one or two that catch your fancy.
        </p>
        <h5>Total Duration- 3 hour</h5>
      </div>
      <EnquiryForm/>
      <Footer/>
    </div>
  );
}

export default KumarakamVV;
