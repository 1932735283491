import React from 'react'

import './ContactQuery.css'
import { useState } from 'react'

function ContactQuery() {
    const [FullName, setName] = useState("");
    const [email, setEmail] = useState("");
    const [contactNo,setContactNo]=useState('')
    const [StartDate,setPreferredDate]=useState('')
    const [duration,setDuration]=useState('')
    const [Need_Vehicle,setNeed_Vehicle]=useState('')
    const [preferedDestination,setPreferedDestination]=useState('')
    const [people,setPeople]=useState('')
    const [children,setChildren]=useState('')
    const [budget,setBudget]=useState('')
    const minDate=new Date().toISOString().split('T')[0]

    const handleDuration=(event)=>{
      const regex = /^[0-9\b]+$/; //regex to match numeric digits and backspace key
      if (event.target.value === '' || regex.test(event.target.value)) {
        setDuration(event.target.value);
    }
}

    const handleSubmit = async (e) => {
      e.preventDefault();
      const response = await fetch("https://formspree.io/f/mwkjzaad", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({FullName,email,contactNo,preferedDestination,duration,Need_Vehicle,StartDate,people,children,budget}),
      });
      if (response.ok) {
        alert("Form submitted!");
        setName("");
        setEmail("");
        setContactNo("");
        setPreferredDate("");
        setDuration("");
        setNeed_Vehicle("");
        setPreferedDestination("");
        setPeople("");
        setChildren("");
        setBudget("");
      } else {
        alert("Error submitting form");
      }
    }
  return (
      <div className="ContactQueryWraper">
      <div className="ContactQueryHeading">
      <h1>Enquiry Form</h1>
      </div>

          <form onSubmit={handleSubmit}>
          <div className="contactquery-divider">
          <div className="ContactQuery-divider1">
      <div className="ContactQueryField">
      <label  htmlFor='name' >Full Name</label>
<input type="text" value={FullName} onChange={(e) => setName(e.target.value)}  />
      </div>
      <div className="ContactQueryField">
      <label  htmlFor='email' >Email ID</label>
<input type="email" value={email} onChange={(e) => setEmail(e.target.value)}  />
      </div>
      <div className="ContactQueryField">
      <label  htmlFor='contactNo' >Contact Number</label>
<input type="number" value={contactNo} onChange={(e) => setContactNo(e.target.value)}  />
      </div>
      <div className="ContactQueryField">
      <label  htmlFor='prefferedDate' >Start Date</label>
<input type="date" value={StartDate} onChange={(e) => setPreferredDate(e.target.value)} min={minDate} />
      </div>

      <div className="ContactQueryField">
      <label  htmlFor='duration' >Duration (No. of Nights)</label>
<input type="text" maxLength={2}  value={duration} onChange={handleDuration}  />
      </div>
      </div>
      <div className="ContactQuery-divider2">
      <div className="ContactQueryField">
      <label  htmlFor='duration' >Need Vehicle</label>
      <select value={Need_Vehicle} onChange={(e) => setNeed_Vehicle(e.target.value)} >
        <option>Select</option>
        <option>Yes</option>
        <option>No</option>
      </select>
      </div>
      <div className="ContactQueryField">
      <label  htmlFor='prefferedDestination' >Preferred Destinations</label>
      <textarea rows="2" value={preferedDestination} onChange={(e) => setPreferedDestination(e.target.value)} ></textarea>
      </div>

      <div className="ContactQueryField">
      <label  htmlFor='duration' >No: of People (12 yrs &amp; above)</label>
<input type="number"  value={people} onChange={(e) => setPeople(e.target.value)}  />
      </div>
      <div className="ContactQueryField">
      <label  htmlFor='duration' >No: of Children (11 yrs &amp; below)</label>
<input type="number"  value={children} onChange={(e) => setChildren(e.target.value)}  />
      </div>
      <div className="ContactQueryField">
      <label  htmlFor='duration' >Expected Budget (USD)</label>
<input type="number" value={budget} onChange={(e) => setBudget(e.target.value)}  />
      </div>
      </div>
      </div>

<div className="ContactQuery-submit">
<button type="submit">Submit</button>
</div>


    </form>
      </div>

  )
}

export default ContactQuery