import React, { useEffect } from "react";
import CommonNav from "../CommonNav/CommonNav";

import "./Athira.css";
import kerala1 from "../Assets/kerala1.jpg";
import EnquiryForm from "../EnquiryForm/EnquiryForm";
import Footer from '../Footer/Footer'

function Athira() {
    useEffect(() => {
        window.scrollTo(0, 0);
      },[])
  return (
    <div className="Athira-wrapper">
      <CommonNav />
      <div className="athira-MainHeading">
        <h1>India / 10-Day Itineraries / Kerala: Athirapally-Kovalam </h1>
        <img className="athira-headingImg" src={kerala1} alt="" />
        <p>
          Kerala, also known as "God's Own Country," is a state located on the
          southwestern coast of India. It is famous for its scenic beauty,
          abundant greenery, and unique cultural heritage. Tourism is one of the
          primary industries in Kerala and plays a crucial role in the state's
          economy. The state's diverse attractions range from tranquil beaches,
          backwaters, hill stations, wildlife sanctuaries, and spice plantations
          to historic sites, religious shrines, and festivals. Kerala is also
          known for its Ayurvedic treatments and wellness retreats. The state's
          tourism sector has gained significant recognition worldwide for its
          sustainable tourism practices and responsible tourism initiatives.
          With its vast array of attractions and hospitality, Kerala is a
          popular destination for tourists from all over the world.
        </p>
        <h4>Suggested itinerary</h4>
        <h6>Day 01: Ernakulam/kochi - Athirapally</h6>
        <p>
          Upon arrival at Ernakulam railway station, we will transfer you to the
          famous waterfall destination Athirapally (90 KM / 2 hrs). This
          beautiful spot, the largest waterfalls in South India, has been seen
          in several movies (Ravan – Tamil, Guru – Hindi etc…). Upon reaching,
          check into a resort. Later go out for a short drive and enjoy the
          waterfalls. Visit the nearby Vazhachal falls too. Stay in the resort.
          Overnight at Athirapally.
        </p>
        <h6>Day 02: Athirapally - Munnar</h6>
        <p>
        After breakfast proceed to Munnar (180 KM - 6 hours). On the way you can watch the rubber and pineapple plantations, the lovely waterfalls in Cheeyappara and Valara and the Karadippara view point. Enjoy the drive through the tea mountains and the picturesque green carpet of the nature. Overnight stay at Munnar.
        </p>
        <h6>Day 03: Munnar</h6>
        <p>
        Proceed for sightseeing trip of Munnar after the breakfast; Visit Eravikulam National Park at Rajamlai where you can see the  rare specious of  Nilgiri Tahr, Mattupetty Dam which offers many fascinating sights to relish, the Shola forests around Mattupetty and Kundala Dam. Visit Photo Shooting Point, Echo Point, View Point & the green carpet of Tea Gardens on the way. Overnight stay at Munnar

        </p>
        <h6>Day 04: Munnar - Thekkady</h6>
        <p>
        Proceed to Thekkady (120 KM - 4 hours) after breakfast. Check in to hotel; Visit the Periyar Wild life Sanctuary (optional: go for a wildlife cruise to view a large variety of wild animals like wild elephants, Nilgiri Langur, Sambar Deer, Wild Bison, Wild Boar etc... and quite a lot of unique birds can also be spotted) Overnight stay at Thekkady.

        </p>
        <h6>Day 05: Thekkady </h6>
        <p>
        After breakfast enjoy various other optional activities here. (Jungle safari, elephant ride, trekking, spice plantation visit, boating, kathakali (traditional dance form) and kalaripayattu (martial art) etc….). Overnight stay at  Thekkady.

        </p>
        <h6>Day 06: Thekkady - Kumarakom</h6>
        <p>
        After breakfast check out and drive to Kumarakom (130 KM – 3.30 hours). Upon reaching embark on a six bed room houseboat. Enjoy whole day cruise. You can have traditional Kerala food onboard.  If required you can enjoy fishing (angling) and ask the chef to prepare it fresh for lunch or dinner. Overnight stay on board.
        </p>
        <h6>Day 07: Kumarakom - Alleppey </h6>
        <p>
        Check out from the boat after breakfast and drive to Alleppey (40 KM). Upon reaching check in a beautiful lake side resort. Enjoy the beauty of backwaters in Gods Own Country. If required you can enjoy a short drive along the coastal village, a walk along paddy fields etc… Stay in the resort

        </p>
        <h6>Day 08: Alleppey - Trivandrum - Kovalam</h6>
        <p>
        Check out from the boat after breakfast and drive to Trivandrum (180 KM). Visit Shri Padmanbhaswamy temple, museum etc... Later proceed to Kovalam (15 KM). Here you check into the resort. Enjoy whole day at the sprawling beach. Overnight stay at Kovalam
 

        </p>
        <h6>Day 09: Kovalam - Kanyakumari Kovalam </h6>
        <p>
        After breakfast, proceed to Kanyakumari (75 KM), the Southern tip of peninsular India. Enjoy sightseeing, visit the local market. Later drive back to  Kovalam for overnight stay

        </p>
        <h6>Day 10: Kovalam -  Trivandrum </h6>
        <p>
        After breakfast proceed to Trivandrum (15 KM).  You would be dropped at airport/railway station at appropriate time

        </p>
        <h4>Properties foreseen (Room category):</h4>
        <table>
            <thead>
                <tr>
                    <th>Destination</th>
                    <th>Property</th>
                    <th>No.of.nights</th>

                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Athirapally</td>
                    <td>Ayur Soukhyam</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Munnar</td>
                    <td>The Edge</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Thekkady</td>
                    <td>Periyar Meadows</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Kumarakom</td>
                    <td>Houseboat</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Alleppey</td>
                    <td>Kovilakam</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Kovalam</td>
                    <td>Jasmine Palace</td>
                    <td>2</td>
                </tr>
            </tbody>
        </table>
        <h6>Cost includes:</h6>
        <ul>
            <li>Accommodation</li>
            <li>Daily breakfast and dinner</li>
            <li>All meals in houseboat</li>
            <li>Transfer and sightseeing by AC tempo traveller (17 seat)</li>
            <li>Toll, parking, driver bata, inter-state permit</li>
            <li>All applicable taxes</li>
        </ul>
        <h6>Cost excludes:</h6>
        <ul>
            <li>Flight / Train tickets</li>
            <li>Charges for entry, guide and optional activities</li>
            <li>All other expenses which are not mentioned in the inclusions </li>
        </ul>

        <h6>Note:</h6>
        <ul>
            <li>Booking confirmation is subject to availability of rooms. </li>
            <li>Check in time at the hotel is 12.00 hrs and check out time is 11.00 hrs</li>
            <li>Check in time at the houseboat is 12.00 hrs and check out time is 09.00 hrs </li>
        </ul>
        
      </div>
      <EnquiryForm/>
      <Footer/>
    </div>
  );
}

export default Athira;
