import React, { useEffect } from 'react'
import CommonNav from '../CommonNav/CommonNav'
import periyar from '../Assets/periyar.jpg'
import Footer from '../Footer/Footer';
import galleryImg1 from '../Assets/galleryImg1.svg'
import kumarakam from '../Assets/kumarakam.jpg'
import teaPlantionPeriyar1 from '../Assets/teaPlantationPeriyar1.jpg'
import kumbalangi from '../Assets/kumbalangi4.png'
import sunsetkochi from '../Assets/sunsetkochi.jpg'
function Activities() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <div className='Destinations-wrapper'>
    <CommonNav/>
    <div className="destination-cards">

      <div className="destinations-card">
      <a className='destinations-cardLink'  href='/#/destination/TrekkingAtMunnar'>
      <img className='destinations-cardImg' src={galleryImg1} alt="" />
      <div className="destination-cardDescription">
          <h1>Trekking at Munnar</h1> 
        </div>
        </a>
      </div>

      <div className="destinations-card">
      <a className='destinations-cardLink'  href='/#/destination/KumarakamVV'>
      <img className='destinations-cardImg' src={kumarakam} alt="" />
      <div className="destination-cardDescription">
          <h2>Kumarakom – Village Visit</h2> 
        </div>
        </a>
      </div>

      <div className="destinations-card">
      <a className='destinations-cardLink'  href='/#/destination/periyar'>
      <img className='destinations-cardImg' src={periyar} alt="" />
      <div className="destination-cardDescription">
          <h2>Spice plantation visit @ Periyar</h2> 
        </div>
        </a>
      </div>


      <div className="destinations-card">
      <a className='destinations-cardLink'  href='/#/destination/TeaPlantationPeriyar'>
      <img className='destinations-cardImg' src={teaPlantionPeriyar1} alt="" />
      <div className="destination-cardDescription">
          <h2>Tea plantation trek @ Periyar</h2> 
        </div>
        </a>
      </div>


      <div className="destinations-card">
      <a className='destinations-cardLink'  href='/#/destination/KumbalangiVillage'>
      <img className='destinations-cardImg' src={kumbalangi} alt="" />
      <div className="destination-cardDescription">
          <h2>Kumbalangi village</h2> 
        </div>
        </a>
      </div>


      <div className="destinations-card">
      <a className='destinations-cardLink'  href='/#/destination/SunsetCochin'>
      <img className='destinations-cardImg' src={sunsetkochi} alt="" />
      <div className="destination-cardDescription">
          <h2>Sunset cruise at Cochin</h2> 
        </div>
        </a>
      </div>



</div>
     <Footer/>
    </div>
  )
}

export default Activities