import React, { useEffect } from "react";

import CommonNav from "../CommonNav/CommonNav";
import Footer from '../Footer/Footer'

import ooty from "../Assets/ooty.jpg"
import EnquiryForm from "../EnquiryForm/EnquiryForm";

function OverTheHills() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <div className="Athira-wrapper">
      <CommonNav />
      <div className="athira-MainHeading">
        <h1>India / 15-Day Itineraries / Over The Hills </h1>

        <h4>Suggested itinerary</h4>
        <h6>Day 01: Coimbatore (arrival) </h6>
        <p>
          Upon reaching Coimbatore airport/railway station, we will transfer you
          to Ooty (100 KM). Check in at the hotel as you arrive in Ooty. Evening
          free for shopping and other activities and overnight stay at Ooty.
        </p>
        <h6>Day 02: Ooty</h6>
        <img src={ooty} alt="" />
        <p>
        After breakfast proceed for toy train ride (cost extra). Then transfer to visit the Doddabeta Peak which is the highest peak of Ooty.  From here you will get a very panoramic view of the neighboring regions. By noon visit the famous Botanical garden built in 1847 and the  Ooty Lake were boating facility is available. Horse-back riding around the lake also is available. Evening free for shopping and other activities . Overnight at Ooty.
        </p>
        <h6>Day 03: Ooty – Valparai</h6>
        <p>
        After breakfast drive to an off beat hill station Valparai (200 KM) Check into a three bed room cottage. (Here you can enjoy an optional forest drive (jungle safari). Overnight stay at Valparai.
        </p>
        <h6>Day 04: Valparai</h6>
        <p>
        Relax your holiday at this hill station. Stay at Valparai.
        </p>
        <h6>Day 05: Valparai - Kodaikanal </h6>
        <p>
        After breakfast departure for Kodaikanal  (200 KM) Evening check in to resort .Overnight stay at Kodaikanal.
        </p>
        <h6>Day 06: Kodaikanal</h6>
        <p>
        After breakfast proceed for city sightseeing. Lake, Bryant park, Koker’s walk, Five hundred years tree, Pampar fall, Pillar Rock, valley view, upper lake view, shopping. Stay at Kodaikanal
        </p>
        <h6>Day 07: Kodaikanal - Munnar </h6>
        <p>
        After breakfast check out and proceed to Munnar (180 KM). Enjoy the drive through the tea mountains and the picturesque green carpet of the nature. On the way you can watch waterfalls and view point. Upon reaching, check in to hotel. Overnight stay at Munnar.
        </p>
        <h6>Day 08: Munnar</h6>
        <p>
        Proceed for sightseeing trip of Munnar after the breakfast; Visit the Blossom Garden, Tata Tea Museum, Eravikulam National Park at Rajamlai (where you can see the  rare specious of  Nilgiri Tahr), Mattupetty Dam which offers many fascinating sights to relish, the Shola forests around Mattupetty area and Kundala Dam. (Optional: You can enjoy pedal boating). Visit Photo Shooting Point, Echo Point, View Point & the green carpet of Tea Gardens on the way. Overnight stay at Munnar.
        </p>
        <h6>Day 09: Munnar - Thekkady </h6>
        <p>
        Proceed to Thekkady (120 KM - 4 hours) after breakfast. Check into hotel; Visit the Periyar Wild life Sanctuary (optional: go for a wildlife cruise to view a large variety of wild animal like Wild Elephants, Nilgiri Langur, Sambar Deer, Wild Bison, Wild Boar etc... and quite a lot of unique birds can also be spotted) Overnight stay at Thekkady
        </p>
        <h6>Day 10: Thekkady</h6>
        <p>
        After breakfast you can recreate yourself by having various other optional activities like jungle safari, spice plantation tour,  trekking and elephant ride. Overnight stay .at Thekkady.
        </p>
        <h6>Day 11: Thekkady - Kuttikanam</h6>
        <p>
        After breakfast checkout and proceed to another beautiful hilltop destination 'Kuttikanam' (35 KM - 1 hour). Check into a resort. Relax the whole day at this destination which is located about 3500 ft above sea level. Enjoy the misty climate. Stay overnight.     
        </p>
        <h6>Day 12: Kuttikanam - Kumarakom</h6>
        <p>
        After breakfast check out and drive to Paruthumpara (15 KM - Less than half hour), also known as grampi or eagle rock, offers a panoramic view of entire region. The view is mind blowing. Later proceed to Kumarakom (110 KM - 3 hours). Upon reaching embark on a houseboat. Enjoy backwater cruise through the paddy fields, coconut lagoons, narrow canals etc. If possible you can interact with local people, experience the local life. Enjoy a walk along the paddy fields. If required you can enjoy fishing (angling). If fortunate you can ask the chef to prepare it afresh for lunch or dinner. Overnight stay in house boat.
        </p>
        <h6>Day 13: Kumarakom – Kochi - Cherai</h6>
        <p>
        After breakfast, dis embark from the houseboat and proceed to the heritage town Fort Kochi (70 KM - 2 hours). Visit Dutch Palace, Jewish Synagogue, Jew Street, Chinese fishing nets etc... Enjoy shopping in the Kashmiri shops here. Later drive to Cherai Beach (40 KM - 1 hour). Check in to a beach resort. Rest of the day at leisure at beach; Overnight stay at Cherai.
        </p>
        <h6>Day 14: Cherai - Athirapally</h6>
        <p>
        After breakfast we will transfer you to Athirappally (100 KM), the destination famous for South India’s largest waterfall.  Check into an ayurvedic resort. Later enjoy your day at the waterfall.  If required you can go for a short drive to the nearby Vazhachal waterfalls too. Overnight stay at Athirappally
        </p>
        <h6>Day 15: Athirapally (departure)</h6>
        <p>
        After breakfast, check out from the resort and you would be dropped at Kochi airport (50 KM). 
        </p>

        <h4>Properties foreseen:</h4>
        <table>
          <thead>
            <tr>
              <th>Destination</th>
              <th>Property</th>
              <th>No.of.nights</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ooty</td>
              <td>Hillock View</td>
              <td>2</td>
            </tr>
            <tr>
              <td>Valparai</td>
              <td>NEPC Guest house</td>
              <td>2</td>
            </tr>
            <tr>
              <td>Kodaikanal</td>
              <td>Coakers Tower</td>
              <td>2</td>
            </tr>
            <tr>
              <td>Munnar</td>
              <td>Deepwoods</td>
              <td>2</td>
            </tr>
            <tr>
              <td>Thekkady</td>
              <td>Pariyar Meadows</td>
              <td>2</td>
            </tr>
            <tr>
              <td>Kuttikanam</td>
              <td>Rhapsody Villa</td>
              <td>1</td>
            </tr>
            <tr>
              <td>Kumarakom</td>
              <td>Houseboat</td>
              <td>1</td>
            </tr>
            <tr>
              <td>Cherai</td>
              <td>Baywatch Beach Resort</td>
              <td>1</td>
            </tr>
            <tr>
              <td>Athirapally</td>
              <td>Ayur Soukhyam</td>
              <td>1</td>
            </tr>
          </tbody>
        </table>
        <h6>Cost includes:</h6>
        <ul>
          <li>Accommodation</li>
          <li>Daily breakfast and dinner</li>
          <li>All meals in houseboat</li>
          <li>Transfer and sightseeing by AC vehicle</li>
          <li>Toll, parking, driver bata, intert-state permit</li>
          <li>All applicable taxes</li>
        </ul>
        <h6>Cost excludes:</h6>
        <ul>
          <li>Flight / Train tickets</li>
          <li>Charges for entry, guide and optional activities</li>
          <li>All other expenses which are not mentioned in the inclusions </li>
        </ul>

        <h6>Note:</h6>
        <ul>
          <li>Booking confirmation is subject to availability. In case of non-availability of rooms at the hotels mentioned, alternate hotels of similar category will be provided </li>
          <li>
            Check in time at the hotel is 12.00 hrs and check out time is 11.00
            hrs
          </li>
          <li>
            Check in time at the houseboat is 12.00 hrs and check out time is
            09.00 hrs
          </li>
        </ul>
      </div>
      <EnquiryForm/>
      <Footer/>
    </div>
  );
}

export default OverTheHills;
