import React, { useEffect } from 'react'




import CommonNav from "../CommonNav/CommonNav";
import Footer from '../Footer/Footer'
import EnquiryForm from '../EnquiryForm/EnquiryForm'

function WellnessAyurveda() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <div className="Athira-wrapper">
    <CommonNav />
    <div className="athira-MainHeading">
      <h1>India / Wellness And Ayurveda </h1>
      <p>
      Take a leave of your hectic work schedules and come over to recharge &amp; rejuvenate your body &amp; soul for
a better life.
      </p>
      <p>Charaka sutra sthana, Chapter 1, verse 41</p>
      <h6>हितहितम सुखम दुःखमयुस्तस्य हितहितम <br />
मानं च टाचा यत्रोक्तमायुर्वेदः सा उच्यते</h6>
<p>Hitahitam sukham dukhamayustasya hitahitam
Maanam cha tacha yatrokatmayurvedah sa uchyate</p>
      <p>
      Ayurveda is the science of life. Ayurveda gives remedies for… <br />
Hitayu – an advantageous life <br />
Ahita Ayu – a disadvantageous life <br />
Sukhayu – a happy state of health and mind <br />
Ahitayu – an unhappy state of health and mind. <br />
      </p>
      <p>
      It also explains what is good and bad for life and how to measure life.
      </p>

      <p>
      Ayurveda professes a lifestyle regime which not only includes eating foods which pacify your particular
dosha(s), but which includes moving your body through yoga, and pranayama to increase balance,
flexibility and life force in mind and body. It encourages discovering and practicing your dharma (life
purpose or passion) as a key to your own happiness. It promotes expanding your mind, perspective and
behavior through learning, understanding, compassion and tolerance. Ayurveda works with your own
energies to help you to optimize your wellbeing.
      </p>
      <p>
      India being the cradle of Ayurveda has inspired the tourism industry to facilitate tourists seeking wellness
through yoga and Ayurveda during their tour in India.Learning Trails have developed customized healing
therapies in luxurious retreats in the midst of natural freshness along with your tour in the region.
      </p>
      <p>
      We have a network of Ayurvedic resorts and centres in Kerala which provides a variety of authentic
Ayurvedic treatments. Complementing wellness activities , these resorts are set in the midst of natural
beauty of Kerala such as rain forests, backwaters and beaches.
      </p>
      <h6>Abhyangam :</h6>
      <p>
      This is a general body massage that uses traditional massage techniques and medicated
herbal oils which will rejuvenate and relax the entire body by improving the circulation of vital life fluids
and through discharge of waste materials.
      </p>
      <h6>Nasyam :</h6>
      <p>
      Involves introducing herbal medicines into the nasal pathway, which cleanses the sinus and
nasal cavity. It also nourishes the sense organs above the neck. This highly effective therapy eliminates
toxins by stimulating sweat glands and by improving blood circulation usually followed by a steam bath.
      </p>
      <h6>Pathrapotalaswedam :</h6>
      <p>
      This massage is very useful in treating arthritis, muscular aches, inflammatory
conditions etc. Leaves and powders of medicinal herbs are fried in medicated oil, bundled in a muslin
cloth and then applied over the body in a rhythmic manner.
      </p>
      <h6>Sirodhara :</h6>
      <p>
      This is very useful relaxation and rejuvenation therapy for the mind and body, and is very
effective in treating insomnia, mental tension related problems, and some skin problems. In this treatment
a steady stream of decoctions or lukewarm oil is made to flow over the forehead while giving gentle
massage to the head.
      </p>
      <h6>Njavarakizhy :</h6>
      <p>
      Helps relieve muscle wasting and weakness of body. This treatment involves a massage
with medicinal rice called Njavara, cooked with milk and herbs. It is tied into a muslin cloth bag and
applied on the body.
      </p>
      <h6>Sirodhara –</h6>
      <p>
      In this treatment a steady stream of decoctions or lukewarm oil is made to flow over the
forehead while giving gentle massage to the head. This is a very useful relaxation and rejuvenation
therapy for the mind and body, and is very effective in treating insomnia, mental tension related
problems, and some skin problems. 
      </p>
      <br />
      <p>
      Please contact us for planning an exciting cultural exploration trip to India!!!
      </p>
    </div>
    <EnquiryForm/>
    <Footer/>
  </div>
  )
}

export default WellnessAyurveda