import React, { useEffect } from 'react'

import CommonNav from "../CommonNav/CommonNav";
import EnquiryForm from '../EnquiryForm/EnquiryForm';
import Footer from '../Footer/Footer'

function Goa() {
      useEffect(() => {
            window.scrollTo(0, 0);
          },[])
  return (
    <div className="Athira-wrapper">
    <CommonNav />
    <div className="athira-MainHeading">
      <h1>India / 5-Day Itinerary / Short tour of Goa </h1>

      <h4>Suggested itinerary</h4>
      <h6>Day 01:</h6>
      <p>
      Upon reaching Goa airport, our driver will pick you and drop at hotel
Overnight stay at Goa
      </p>
      <h6>Day 02:</h6>
      <p>
      Enjoy guided sightseeing of South Goa. <br />
Se Cathderal church, Basilica of Bom Jesus, A Hindu temple, Panjim bazaar, Miramar beach,
Dona Paula bay, Mandovi River Cruise etc... Stay at Goa
      </p>
      <h6>Day 03:</h6>
      <p>
      Guided tour of Goa <br />
Reise Magos Fort, Dolphin Trip @ Coco Beach (seat in boat/sharing basis), Anjuna beach,
Vagator Beach, Chapora Fort etc… Overnight stay at Goa

      </p>
      <h6>Day 04:</h6>
      <p>
      North Goa guided sightseeing. <br />
Candolim beach, Fort Aguada, Calangute beach, Baga Beach etc… Return to hotel.
      </p>
      <h6>Day 05:</h6>
      <p>
      Drop at Goa airport at appropriate time

      </p>
  
      
    </div>
    <EnquiryForm/>
    <Footer/>
  </div>
  )
}

export default Goa