import React, { useEffect } from 'react'

import tajMahal from '../Assets/tajMahal.webp'


import CommonNav from "../CommonNav/CommonNav";
import Footer from '../Footer/Footer'
import EnquiryForm from '../EnquiryForm/EnquiryForm'

function GoldenTriangle() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <div className="Athira-wrapper">
    <CommonNav />
    <div className="athira-MainHeading">
      <h1>India /Golden Triangle </h1>
      <p>
      The Golden Triangle is a popular tourist circuit in India that covers three cities in the northern part of the
country: Delhi, Agra, and Jaipur. The three cities offer a glimpse into the rich history, culture, and
architecture of India.

      </p>
      <p>
      The first city on the Golden Triangle circuit is Delhi, the capital city of India. Delhi is a vibrant
metropolis that blends modernity and tradition. The city is home to several historical landmarks such as
the Red Fort, Qutub Minar, India Gate, and the Jama Masjid.

      </p>
      <p>
      The second city on the Golden Triangle circuit is Agra, which is famous for the Taj Mahal, one of the
Seven Wonders of the World. The Taj Mahal is a mausoleum built by the Mughal Emperor Shah Jahan in
memory of his wife Mumtaz Mahal.
      </p>
      <img className="athira-headingImg" src={tajMahal} alt="" />
      <p>
      The third and final city on the Golden Triangle circuit is Jaipur, the capital of Rajasthan. Jaipur is also
known as the &quot;Pink City&quot; due to the pink-colored buildings in its old town. The city is famous for its
palaces such as the Hawa Mahal, City Palace, and Jantar Mantar. The Amber Fort, located just outside the
city, is another popular attraction.
      </p>
      <p>
      In addition to the historical and cultural attractions, the Golden Triangle also offers a variety of shopping
opportunities, delicious cuisine, and colorful bazaars. The best time to visit the Golden Triangle is
between October and March, when the weather is pleasant and the tourist crowds are lower. 
      </p>
      
    </div>
    <EnquiryForm/>
    <Footer/>
  </div>
  )
}

export default GoldenTriangle