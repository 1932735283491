import React, { useEffect } from 'react'

import CommonNav from "../CommonNav/CommonNav";
import EnquiryForm from '../EnquiryForm/EnquiryForm';
import Footer from '../Footer/Footer'

function CochinToCochin() {
    useEffect(() => {
        window.scrollTo(0, 0);
      },[])
  return (
    <div className="Athira-wrapper">
    <CommonNav />
    <div className="athira-MainHeading">
      <h1>India / 11-Day Itineraries / Kerala: Cochin to Cochin </h1>

      <h4>Suggested itinerary</h4>
      <h6>Day 1: Kochi</h6>
      <p>
      Upon arrival at Kochi, we will transfer you to the hotel (40 KM  - 1.30 hours). Check
in hotel. Overnight stay at Cochin
      </p>
      <h6>Day 2: Kochi - Munnar</h6>
      <p>
      After breakfast check out from the hotel and proceed to Munnar (135 KM / 4/5
hrs). A five-hour scenic drive through hill station would be thrilling. On the way
visit Valara, Cheeyappara waterfalls, Karadippara view point etc… Check in to
resort. Overnight stay at Munnar
      </p>
      <h6>Day 03: Munnar</h6>
      <p>
      Enjoy full day sightseeing. Visit Mattuppetty dam, Kundala lake, Eravikulam
National park, Pothamedu view point, (You can also visit Photo Shooting Point, Echo
Point, View Point etc….) You will have a X’mas Dinner at the resort. Overnight stay
at Munnar.

      </p>
      <h6>Day 04: Munnar - Thekkady</h6>
      <p>
      After breakfast check out from the hotel and drive to Thekkady (150 KM / 3/4
hrs).  Check in to the hotel; Visit the Periyar Wild life Sanctuary, (optional: enjoy a
wildlife cruise to view a large variety of wild animal and quite a lot of unique birds
can also be spotted). Overnight stay at Thekkady

      </p>
      <h6>Day 5: Thekkady – Kumarakom </h6>
      <p>
      Today proceed to Kumarakom (130 KM / 4 hrs). Upon reaching embark on a house
boat for your backwater cruise, a splendid area full of canals, lakes and coves. Your
houseboat or &#39;Kettuvalloms&#39; is a luxurious boat with well-appointed bedrooms and
bathrooms. Enjoy traditional Kerala food on board. Apart from the mesmerizing
surroundings, it&#39;s the life along the backwaters that makes this a fascinating
experience. Enjoy the beauty of Vembanad lake. Overnight stay on board
      </p>
      <h6>For those staying in houseboat, please note:</h6>
      <ul>
          <li>On day 05, start your journey from Thekkady at 08:00 am, so that you can check
in houseboat at 12:00 noon and have lunch on time and enjoy more cruising</li>
          <li>Houseboat would be anchored along the shore from 5 pm to 8 am</li>
          <li>AC in houseboat will be functional only from 21:00 hrs to 06:00 hrs and that too in
bed room only</li>
      </ul>
      <h6>Day 6: Kumarakom – Varkala</h6>
      <p>
      Today proceed to Varkala beach (170 KM / 4/5 hrs). Upon reaching check into a
beautiful resort. Relax your holiday at this destination. Overnight stay in the
resort.
      </p>
      <h6>Day 7: Varkala</h6>
      <p>
      Enjoy the pristine beach in Gods Own Country. Enjoy a short drive along this
coastal village. Overnight stay at this beach destination.

      </p>
      <h6>Day 8: Varkala – Alleppey</h6>
      <p>
      Today drive to Alleppey (140 KM / 4 hrs) and check into a lake side resort. Relax
your holiday here. Stay at Alleppey.


      </p>
      <h6>Day 9: Alleppey – Cochin</h6>
      <p>
      Drive to Cochin (70 KM / 2 hrs). Enjoy tour of heritage town Fort Cochin and
Mattancherry. Visit Jewish Synagogue (closed on Fridays and Saturdays), which
was built in 1568 and is still an active house of worship for the few remaining Jews
in the area. After the synagogue you visit the St. Francis Church, which was
established by the Portuguese Franciscan friars in 1503. This is one of the first
European churches built in India and the famed Vasco Da Gama was buried in its
courtyard for a few years before his remains were returned to Portugal.
Later you have an option to visit the most photogenic of Cochin’s attractions, 15th
century Chinese fishing nets. You may see local fishermen using these massive nets
with an ease that comes from centuries of practice. Enjoy Kathakali show
(traditional dance-drama art form of Kerala) in a nearby cultural theatre.
Overnight at Cochin.

      </p>
      <h6>Day 10: Cochin - Athirapally </h6>
      <p>
      Today morning you will drive to a special destination, which has one of the key
attractions of tourists visiting Gods Own Country ‘waterfalls’. This destination is
famous for one among South Indias largest waterfalls ‘Athirapally water fall’. Upon
reaching you check into an ayurvedi resort and later enjoy a drive to the falls. You
can pay a short visit to nearby Vazhachal falls too. (Optional: General body
massage) Overnight at Athirapally.

      </p>

      <h6>Day 11: Kochi </h6>
      <p>
      You would be dropped at Cochin airport (50 KM / 1.30 hrs) at appropriate time

      </p>
      <h4>Properties foreseen (Room category):</h4>
      <table>
          <thead>
              <tr>
                  <th>Destination</th>
                  <th>Nights</th>

              </tr>
          </thead>
          <tbody>
              <tr>
                  <td>Cochin</td>
                  <td>1</td>
              </tr>
              <tr>
                  <td>Munnar</td>
                  <td>2</td>
              </tr>
              <tr>
                  <td>Thekkady</td>
                  <td>1</td>
              </tr>
              <tr>
                  <td>Kumarakom</td>
                  <td>1</td>
              </tr>
              <tr>
                  <td>Varkala</td>
                  <td>2</td>
              </tr>
              <tr>
                  <td>Aleppo</td>
                  <td>1</td>
              </tr>
              <tr>
                  <td>Cochin</td>
                  <td>1</td>
              </tr>
              <tr>
                  <td>Athirapally</td>
                  <td>1</td>
              </tr>
          </tbody>
      </table>
      <h6>Cost includes:</h6>
      <ul>
          <li>Accommodation</li>
          <li>Daily breakfast and dinner</li>
          <li>All meals in houseboat</li>
          <li>Entire transportation by AC vehicle</li>
          <li>Toll, parking and all charges for driver</li>
          <li>All applicable taxes</li>
      </ul>
      <h6>Cost excludes:</h6>
      <ul>
          <li>Accommodation</li>
          <li>Flight / Train tickets</li>
          <li>Charges for entry, guide and optional activities</li>
          <li>All other expenses which are not mentioned in the inclusions </li>
      </ul>

      <h6>Note:</h6>
      <ul>
          <li>Check in time at the hotel is 14.00 hrs and check out time is 11.00
hrs</li>
      </ul>
      
    </div>
    <EnquiryForm/>
    <Footer/>
  </div>
  )
}

export default CochinToCochin