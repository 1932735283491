import React, { useEffect } from "react";
import CommonNav from "../CommonNav/CommonNav";
import homestay from "../Assets/homestay.jpeg";
import EnquiryForm from "../EnquiryForm/EnquiryForm";
import Footer from '../Footer/Footer'
import alleppey from '../Assets/alleppey.jpg' 
import vagamon from '../Assets/vagamon1.jpg'
function SouthHomestay() {
    useEffect(() => {
        window.scrollTo(0, 0);
      },[])
  return (
    <div className="Athira-wrapper">
      <CommonNav />
      <div className="athira-MainHeading">
        <h1>India / 11-Day Itinerary / Welcome to South: Homestay Trip </h1>
        <img className="athira-headingImg" src={homestay} alt="" />
        <h4>Suggested itinerary</h4>
        <h6>Day 01: Cochin (Arrival)</h6>
        <p>
        Pick up from Cochin airport (most of flights from Malaysia arrive late night at Cochin airport)
We transfer you to a hotel nearby airport. Stay at Cochin.
        </p>
        <h6>Day 02: Cochin – Munnar</h6>
        <p>
        After breakfast, proceed to Munnar (100 KM/ 3 hrs). On the way you can watch the lovely
waterfalls in Cheeyappara and Valara. Upon reaching you check into Rosegardens Homestay.
Enjoy the drive through the tea mountains and the picturesque green carpet of the nature.
Stay at Munnar
        </p>
        <h6>Day 03: Munnar</h6>
        <p>
        After breakfast proceed to Munnar for sightseeing. Visit Tata Tea Musuem, Eravikulam
National Park at Rajamalai where you can see the rare specious of Nilgiri Tahr, Mattupetty Dam
which offers many fascinating sights to relish, the Shola forests around Mattupetty and
Kundala Dam. If time permits, the guide will take you to more spots - Photo Shooting Point, Echo
Point, View Point &amp; the green carpet of Tea Gardens on the way. Overnight stay at Munnar.

        </p>
        <h6>Day 04: Munnar – Thekkady</h6>
        <p>
        After breakfast proceed to Thekkady (120 KM / 3:30 hrs), famous for Periyar Tiger Reserve
Forest. Check into Pepper County Homestay. You can enjoy various activities at Thekkady. Go for
a wildlife boat cruise to view a large variety of wild animal - Wild Elephants, Langur, Sambar
Deer, Wild Bison, Wild Boar etc... quite a lot of unique birds also can be spotted. In the evening
you can watch Kathakali, an ancient dance-drama art form of Kerala and also Kalaripayattu ; it’s
a martial art originated in ancient South India. Overnight stay at Thekkady

        </p>
        <h6>Day 05: Thekkady </h6>
        <p>
        Whole day at Thekkady.
You can enjoy various other optional activities
(Optional activities – not included in cost. Please let us know if you want to do these activities)
Nature walk/soft trekking offers excellent opportunity of watching birds, butterflies and other wildlife
through a range of habitats during the walk. <br />
Jeep safari to Harvest farmhouse. Walk in the vegetable farm, and see the several fruit trees
plantations, can pluck grapes in the vineyard, watch the bees busy storing honey etc… You can also enjoy
spice plantation visit. <br />
Cooking demo with dinner is included. Overnight stay at Thekkady

        </p>
        <h6>Day 06: Thekkady - Vagamon</h6>
        <p>
        After breakfast proceed to Vagamon (60 KM / 2 hrs) and check into Vanilla County, an heritage

plantation bungalow. Enjoy leisure at the property. Here you cn enjoy various activities and get
involved with some untouched nature trails. Enjoy guided spice plantation visit.
(Optional activities – not included in cost. Please let us know if you want to do these activities)
Jeep ride to rock pool: (03 hours programme - 20 minutes jeep ride 15 minutes hike to a private natural
rock pool)
Stay at Vagamon
        </p>
        <img src={vagamon} alt="" />
        <h6>Day 07: Vagamon - Alleppey </h6>
        <p>
        After breakfast check out and en route to Alleppey we stop for rubber plantation visit and
lunch at Church Bell Homestay at Kanjirapally (70 KM/ 2 hrs). Here you can enjoy guided rubber
plantation visit.
After lunch we proceed to Alleppey (75 KM / 2 hrs). Alleppey - A town with canals, backwaters,
beaches, and lagoons is called &quot;Venice of the East.&quot; Alleppey is located on the shores of
Vembanad lake, the largest in Kerala. Here you check into another beautiful homestay Vembanad
Lake House. Stay at Alleppey

        </p>
        <img src={alleppey} alt="" />
        <h6>Day 08: - Alleppey</h6>
        <p>
        Check out after breakfast and you would be transferred to nearby house boat boarding point.
Embark on a two bed room houseboat (It’s a house on a boat). Enjoy the whole day cruise. If
required, you can join the chef at kitchen and learn to cook traditional Kerala food. You can have
all meals onboard. Enjoy the beauty of backwaters in Gods Own Country. (Boat would be
anchored at night) Overnight stay on board
 

        </p>
        <h6>Day 09: Alleppey – Cochin</h6>
        <p>
        Disembark from the boat after breakfast and proceed to Cochin (50 KM / 1.30 hrs). Here you
check into another homestay located on the shore of backwaters. Enjoy the whole day at
leisure. You can visit Chottanikkara temple (25 KM / 45 minutes) (Please follow dress code).
Later enjoy a short drive along the heritage town Fort Kochi and Mattancherry. Visit Jewish
synagogue, St’Francis church (one of the oldest European churches in India) etc… You can
enjoy harbour cruise (1 hour). Enjoy window shopping.
(Optional activities – not included in cost. Please let us know if you want to do these activities)
Sunset boating (for hrs)
Morning trip to paddy fields for birding, fish auction, Puthanthodu beach side etc…. by tuktuk or car (7
am to 9 am). Shall provide assistance too.
Overnight stay at Cochin.

        </p>
        <h6>Day 10: Cochin – Guruvayoor - Athirapally</h6>
        <p>
        After breakfast, proceed to Guruvayoor temple (90 KM / 2:30 hrs). (Please follow dress code)
After temple visit darshan) proceed to Athirapally (85 KM / 2 hrs), which is famous for the
largest waterfall in Gods Own Country. Later in the day go out for a short drive and enjoy the
waterfalls. Visit the nearby Vazhachal falls too. Stay in the resort. Overnight stay at
Athirapally.

        </p>

        <h6>Day 11: Cochin (departure)</h6>
        <p>
        Check out from the resort after breakfast. Our driver will drop you at Cochin airport at
appropriate time (Flights from Cochin to Malaysia are at late night)

        </p>
        <h4>Properties foreseen :</h4>
        <table>
            <thead>
                <tr>
                    <th>Destination</th>
                    <th>Property</th>
                    <th>No.of.nights</th>

                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Cochin</td>
                    <td>Flora Airport Hotel – Deluxe hotel</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Munnar</td>
                    <td>Rosegardens – Homestay</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Thekkady</td>
                    <td>Pepper County – Homestay</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Vagamon</td>
                    <td>Vanilla County – Plantation Bungalow</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Alleppey</td>
                    <td>Vembanad Lake House - Homestay</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Alleppey</td>
                    <td>Houseboat – Luxury</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Cochin</td>
                    <td>Gramam – Homestay</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Athirapally</td>
                    <td>Rain Forest – Luxury resort</td>
                    <td>1</td>
                </tr>
            </tbody>
        </table>
        <h6>Cost includes:</h6>
        <ul>
            <li>Accommodation</li>
            <li>Daily breakfast and dinner</li>
            <li>Full day guided tour of Munnar (Day 03)</li>
            <li>Wildlife boat cruise at Thekkady (Day 04)</li>
            <li>Kathakali and kalari show at Thekkady (Day 04)</li>
            <li>Cooking demo with dinner at Thekkady (Day 05)</li>
            <li>Guided Spice plantation visit at Vanilla County (Day 06)</li>
            <li>Nature walk - Self hiking through the plantation at Vanilla County (Day 06)</li>
            <li>Cycling &amp; Board games at Vanilla County (Day 06)</li>
            <li>Rubber plantation visit and lunch (Day 07)</li>
            <li>All meals onboard houseboat (Day 08)</li>
            <li>Guided sightseeing of Cochin (Day 09)</li>
            <li>Cooking demo with dinner at Cochin (Day 09)</li>
            <li>Entry to all monuments</li>
            <li>Entire transportation by AC vehicle</li>
            <li>Toll, parking and all charges for driver</li>
            <li>All applicable taxes</li>
            
        </ul>
        <h6>Cost excludes:</h6>
        <ul>
            <li>Flight tickets</li>
            <li>Charges for any optional activities tip, laundry, telephone charges etc....</li>
            <li>All other expenses which are not mentioned in the inclusions </li>
        </ul>

        <h6>Please note:</h6>
        <ul>
            <li>Non ac rooms at Munnar &amp; Thekkady (Being hill station, climate is cool &amp; ac not required) </li>
            <li>Homestays: No room service and Television</li>
        </ul>
        
      </div>
      <EnquiryForm/>
      <Footer/>
    </div>
  )
}

export default SouthHomestay