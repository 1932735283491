import React from "react";

import "./Footer.css";
import logo from "../Assets/logo.svg";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer-wraper">
      <div className="footer-quicklinks">
        <div className="footer-quicklinks1">
          <Link className="footer-quicklink" to="/">
          HOME
          </Link>
          <Link className="footer-quicklink" to="/gallery">
          GALLERY
          </Link>
          <Link className="footer-quicklink" to="/AboutUs">
          ABOUT US
          </Link>
          <Link className="footer-quicklink" to="/contact">
          CONTACT US
          </Link>
          <Link className="footer-quicklink" to="/destination">
          DESTINATIONS
          </Link>
          <Link className="footer-quicklink" to="/Iteneraries">
          ITINERARY
          </Link>
          <Link className="footer-quicklink" to="/Activities">
          ACTIVITIES
          </Link>
        </div>
      </div>

      <div className="footer-logo">
        <img className="footer-logoImg" src={logo} alt="logo" />
        <p>
          Learning Trails arrange travel packages across India. While talking of
          arranging tours we organize leisure tours for families & friends, MICE
          for corporates, study tour for students and also shore excursion for
          cruise passengers.
        </p>
      </div>
      <div className="footer-contact">
        <div className="footer-contact-email">
          <a href="mailto:thomas@learningtrails.in"><i class="fa-solid fa-envelope"></i></a>
          <p>thomas@learningtrails.in</p>
        </div>
        <div className="footer-contact-email">
          <a href="tel:+91 9995752517"><i class="fa-solid fa-phone"></i></a>
          <p>Phone (India) : +91 999 575 2517</p>
        </div>

      </div>
      <div className="copywrite">
        {" "}
        <p>© 2023 Learning Trails. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
