import React, { useEffect } from 'react'

import sunsetkochi from '../Assets/sunsetkochi.jpg'
import Footer from "../Footer/Footer";
import CommonNav from "../CommonNav/CommonNav";
import EnquiryForm from '../EnquiryForm/EnquiryForm';

function SunsetCochin() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <div className="Athira-wrapper">
      <CommonNav />
      <div className="athira-MainHeading">
        <h1>India / Kerala/ Sunset/harbour cruise at Cochin</h1>
<img src={sunsetkochi} alt="" />
        <p>
        Cochin or Kochi in the South Indian state of Kerala is an idyllic retreat on the Arabian Sea.
Serene back waters, lagoons with feathery coconut palms and picturesque islands make it an
attractive tourist destination. Quaint cantilever Chinese fishing nets, the beautiful Malabar
Coast and the setting sun make a perfect background for a boat cruise. This is one of the
finest naturals harbours in the world and was once a major centre of trade and commerce with
the Arabs, Chinese, Portuguese, Dutch and the British. Sunset motorboat harbour cruise in
Cochin is mostly 02 hrs cruise during the time of sun set in the Kochi Harbour area with
spectacular view of Marine Drive, Chinese Fishing Nets, Bolgatty Island, Willington Island
and Kochi Harbour. See the cantilevered Chinese fishing nets and the beautiful coastline from
the motor boat.
        </p>
      </div>
      <EnquiryForm/>
      <Footer />
    </div>
  )
}

export default SunsetCochin