import React, { useEffect } from "react";
import CommonNav from "../CommonNav/CommonNav";
import tajMahal from "../Assets/tajMahal.webp";
import EnquiryForm from "../EnquiryForm/EnquiryForm";
import Footer from '../Footer/Footer'

function HolidaysInIndia() {
    useEffect(() => {
        window.scrollTo(0, 0);
      },[])
  return (
    <div className="Athira-wrapper">
      <CommonNav />
      <div className="athira-MainHeading">
        <h1>India / 30-Day Itinerary / Holidays in India</h1>
        <img className="athira-headingImg" src={tajMahal} alt="" />
        <h4>Suggested itinerary</h4>
        <h6>Day 01: Arrive at Chennai</h6>
        <p>
        Upon arrival at Chennai airport, you would be transferred to the hotel. Relax for the whole day. Over
nigh stay
        </p>
        <h6>Day 02: Chennai</h6>
        <p>
        Chennai is a perfect blend of traditionalism and a bustling cosmopolitan life. The major attraction of
Chennai are its sprawling golden beaches, the most popular among them being the picturesque Marina
Beach that also boasts of being the second longest beach in the world. Visit the Kapaleeshwarar Temple
dedicated to Lord Shiva and drive past the majestic Fort St.George and Santhome Cathedral. Later visit
the Government Museum and Art Gallery, has a good collection of paintings and sculptures, both
traditional and modern. A must visit on a trip to India, Chennai is a city with deep rooted values, rich
culture and friendly people. Overnight stay at Chennai.
        </p>
        <h6>Day 03: Chennai-Kancheepuram-Mahabalipuram</h6>
        <p>
        After breakfast proceed for a day trip to Kancheepuram (80 KM), the &#39;City of Thousand Temples&#39;,
considered as the second holiest place in India and one of the seven sacred cities. It was successively
the capital of the Pallavas and the Cholas. This place is also famous for hand-woven silk fabrics.
Later in the day drive to Mahabalipuram (80 KM). Mahabalipuram, once a flourishing port of the
Pallavas of ancient India. The monuments and sculptures here are world-renowned. Visit the Shore
Temple, Arjuna&#39;s Penance and Artisans Village at Mahabalipuram. Overnight stay at Mahabalipuram.

        </p>
        <h6>Day 04: Mahabalipuram-Chidambaram-Thanjavur</h6>
        <p>
        After breakfast check out the hotel and drive to Chidambaram (175 KM) and have a glimpse of the
town. Chidambaram highlights the beautiful temples, it is the seat for the famous Nataraja temple. It is
located in the center of the town, covering an area of 40 acres. It was built by Kopperunjingan, who
ruled between 1229 AD and 1278 AD. Then drive to Thanjavur (120 KM). Arrive at Thanjavur and
check in to the hotel. Rest of the day free at leisure. Overnight at Thanjavur.

        </p>
        <h6>Day 05: Thanjavur- Trichy- Chettinad</h6>
        <p>
        After breakfast proceed for sightseeing tour of Tanjore. Tanjore was once the capital of Chola dynasty,
that ruled South India between 10th and 14th cent uries. Visit the most spectacular Brihadeeswara
Temple,capped by a monolithic cupola made of a single granite rock weighing 80 tonnes.
Drive to Trichy (70 KM) Visit the magnificent Rock Fort that dominates the city.437 steps cut out into
the rock side and interspersed with shrines, lead to the Vinayaka Temple built at its highest point. The
ascent is climaxed by a magnificent view of the sacred Kaveri River. At a lower level along the rock is
the Tayumanaswamy Temple dedicated to Lord Shiva. Later, visit Sriranqam, a massive temple on an
island in the Kauvery and dedicated to Lord Ranganathaswamy. Then proceed to Chettinad (100 KM).
Check in to hotel for over night stay.

        </p>
        <h6>Day 06: Chettinad-Madurai</h6>
        <p>
        After breakfast visit bustling village built over the last 200 years is a treasure trove of antiques and
well-crafted hand-made products ranging from Jewellery to Furniture. At least a dozen villages are
around Chettinad and Karaikudi having their own temples and admiring the architectural heritage is
very interesting. A stroll through the rustic market will give you a close up cross section of village life.
Chettinad is also famous for its use of variety of spices used in preparing food, especially chicken.
Later drive to Madurai (175 KM) and check in hotel.
        </p>
        <h6>Day 07: Madurai</h6>
        <p>
        After breakfast proceed for sightseeing tour of Madurai. The main attraction of Madurai is the great
Sree Meenakshi Temple, the finest example of Dravidian temple architecture existed about 2000 years.
Enjoy the fabulous collection of elaborately sculptured and painted deities in the hall of 1000 pillars.
All hours of the day pilgrims from all over India come to seek the blessings of Meenakshi (Goddess
Parvati) &amp; Sundareswarar (Lord Shiva).Witness Hindu priests performing various ceremonies in
different parts of the temple complex, including religious aspects of traditional weddings. After visit to
the temple,you can also visit nearby Thirumalai Nayak Palace constructed in 1636 AD by an Italian
architect, the palace is an interesting confluence of Dravidian, Islamic &amp; European architectural styles.
Late in the evening attend the famous “Palli Arai” ceremony at Sree Meenakshi Temple. Stay at
Madurai.
        </p>
        <h6>Day 08: Madurai-Periyar/Thekkady</h6>
        <p>
        After breakfast check out from hotel and drive to Periyar (150 KM). Check in to hotel. (you can enjoy
various optional activities here: boating in Periyar river, elephant ride etc…) Overnight at hotel
 

        </p>
        <h6>Day 09: Periyar-Kumarakom</h6>
        <p>
        Early morning go for a forest drive and later proceed to Kumarakom (130 KM) Embark on a house
boat. Enjoy the beauty of back water ‘Vemband lake’, largest in India. Sail through narrow canals and
see the life of local people, majority of them belongs to the fishermen community. Overnight on board.

        </p>
        <h6>Day 10: Kumarakom-Alleppey</h6>
        <p>
        Disembark from the boat at Alleppey on the opposite side of the lake and proceed to a lake side resort.
Enjoy your stay at this resort. And if required you can go for a village walk - a walk along the paddy
fields, which resembles a wide stretch of green carpet all around. Later visit a local coir factory (if its
open). Over night stay in the resort.

        </p>

        <h6>Day 11: Alleppey-Marari</h6>
        <p>
        After breakfast check out from the resort and proceed to one of the beautiful and peaceful beach
destinations in Kerala – Mararikkulam (15 KM). Check into a beautiful beach resort. Later in the day
you can interact with the fishermen of Mararikkulam, a sleepy fishing village sandwiched between the
backwaters and the Arabian Sea. Stay here for 2 nights.

        </p>

        <h6>Day 12:at Marari</h6>
        <p>
        Enjoy the sprawling beach. (at Marari)
        </p>

        <h6>Day 13: Mararikkulam-Cochin/Kochi</h6>
        <p>
        After breakfast check out from the resort and drive to Kochi (60 KM). Full day sight seeing tour of
Cochin. <br/>
Cochin-Once a much prized pawn between the Portuguese, Dutch and English, Kochi or Cochin in the
Southern Indian state of Kerala has a history of being a premier port along the fabled “spice route” to
the east. Fort Cochin was a great emporium of trade, a harbor filled with ships, streets crowded with
merchants and warehouses stored with goods from every port of Asia and Europe. Visit Chinese
Fishing Nets, St. Francis Church, Dutch Palace and Jewish Synagogue.(closed on Fri &amp; Sat). Enjoy
kathakali show (traditional dance-drama of Kerala noted for the attractive make-up of characters,
elaborate costumes, detailed gestures and well-defined body movements) at a cultural centre centre.
Overnight at hotel

        </p>

        <h6>Day 14: Kochi-Palakkad</h6>
        <p>
        After breakfast drive from Kochi to Palakkad (150 KM). Check into a traditional heritage home stay,
the place to experience the village life of Kerala. The house set in a rustic village outside Palakkad
town takes you back to a by gone era. Away from the busy traffic and into a countryside that is
absolutely quiet and calm. Here you can indulge in various activities including, visit to a basket
weaving centre and walk along the paddy field. Over night stay at Palakkad.

        </p>

        <h6>Day 15: Palakkad- Ooty</h6>
        <p>
        After breakfast, proceed to Ooty (150 km). Ooty - &quot;Queen of Hill stations&quot; is nestled in the Nilgiris
range. Visit Pykara Dam, Kamarajsagar and Ooty Botanical Garden. (It time permits visit various other
sight seeing spots - Dodda Betta Peak, Valley View Lake, Sim’s Peak, Coonoor Tea Gardens, Dolphin’s
Nose etc… ) A drive along this beautiful hill station itself will rejuvenate you. Over night stay at
Coonoor..

        </p>

        <h6>Day 16: Ooty - Mysore</h6>
        <p>
        After breakfast, drive to Mysore (150 KM). The capital of a former princely state, Mysore is a heritage
city which possess palaces and gardens and is famous for its silk, sandalwood and jasmine. Visit
Summer Palace of Tippu Sultan, Srirangapatnam and Ranganathittu Bird Sanctuary. Over night in
Mysore.
        </p>

        <h6>Day 17: Mysore</h6>
        <p>
        Attrations: Mysore Maharaja&#39;s Palace, which is one of the biggest in India. Somnathpur, Nandi Bull
and Chamundi Hills. Evening free to explore the colorful bazaars and later visit Mysore Palace in
lights. Visit the Brindavan garden and watch the dancing fountain show. Overnight stay at Mysore.

        </p>


        <h6>Day 18 &amp; 19: Mysore - Coorg</h6>
        <p>
        After breakfast check out from the hotel and proceed to Coorg (Kodagu) (130 KM). Coorg is called the
Scotland of India due to its peculiar weather. Coorg or Kodagu is famous for coffee plantation as well
as planters, majority of them belongs to Kshatriya clan. Explore this hill station. Major attractions
include: Iruppu falls (waterfall), Talacauvery (birth place of Cauvery river), Dubare elephant camp
(kraal), Kushal Nagar (Golden temple), Bylakuppe (famous for Budha temple, monasteries and one of
the major Tibetian settlements in India), Madikkeri - Rajah Seat, Fort etc …. Enjoy two nights stay
at Coorg.

        </p>

        <h6>Day 20: Coorg - Hassan</h6>
        <p>
        Check out the hotel in the morning after breakfast and drive to Hassan (120 km), the city founded
during the Hoysala Dynasty, which ruled the region from 11th to 14th century. After checking in a hotel
and taking some rest, proceed to Hoysaleshwara temple, a glaring example of Hoysalan architectural
excellence. <br/>
Later drive another 10 km and reach Belur. Here pay a visit to the magnificent Chennakeshava temple,
dedicated to Lord Krishna. Both these temples, which are prominent in the tourist attractions’ list, due
to their importance in Indian epic stories Mahabharata and Ramayana witness lot of tourists as well as
pilgrims round the year. Later return to the hotel at Hassan for over night stay.

        </p>

        <h6>Day 21: Hassan - Hospet</h6>
        <p>
        Today morning you check out and drive to Hospet/Hampi (350 KM). Upon reaching Hospet in the
evening, you check into the hotel. Over night stay.
        </p>
        <h6>Day 22: Hospet &amp; Hampi</h6>
        <p>
        After breakfast, explore Hospet, the satellite town built during the reign of Krishnadevaraya. This is a
typical country town of Karnataka and is frequented by visitors to the Vijayanagar ruins. Hospet’s
nearby destination Hampi, once the capital of the Hindu empire Vijayanagara, is listed as one of the
UNESCO World Heritage Sites. Hampi is charismatic even in its ruined state. Vast stretches of boulder-
strewn hills make the backdrop of Hampi unique. Dotted around the hills and valleys are 500 plus
monuments. In Hampi at every turn there is a surprise. Every monument hides more than what they
reveal. Over night stay at Hospet.
        </p>
        <h6>Day 23: Badami</h6>
        <p>
        After breakfast, drive to Badami (175 km) and check into a hotel. Badami, picturesquely situated at the
mouth of a ravine between two rocky hills, is famous for its cave temples-all hewn out of sand stone on
the rock face of a hill. Explore Badami Caves. Visit Aihole and Pattadakkal (40 KM). Aihole was the
ancient capital of Chalukyas. Famous as the cradle of Indian architecture, Aihole has over a hundred
temples, built between the 6th and 8th centuries. Situated on the left bank of the Malaprabha River,
Pattadakkal is a World Heritage Centre. The town has 10 major temples representing early Chalukyan
architecture. Later return to Badami for over night stay.

        </p>
        <h6>Day 24: Badami-Goa</h6>
        <p>
        After breakfast, drive to the famous beach destination of India ‘Goa’ (275 KM). Visit the various
beaches here. Stay at this exotic beach destination for 2 nights. (Day 24 &amp; Day 25)

        </p>
        <h6>Day 26: Goa - Delhi </h6>
        <p>
        We will drop you at Goa airport in the morning to catch your flight to Delhi
Meeting and assistance on arrival at Delhi airport. Transfer to hotel. Later proceed for combined tour of
Old and New Delhi including visit to Qutub Minar built by Qutub-ud-Din Aibek in 1199, Humayun&#39;s
Tomb, India Gate (War Memorial Arch ), Lakshminarayan Temple - a modern Hindu Temple. Also
drive past President&#39;s House, Parliament House, Government Secretariat Buildings and Connaught
Place shopping centre. Drive past the Red Fort and Jama Masjid. Take a rickshaw ride in the alleys of
Old Delhi which is an experience in itself.

        </p>
        <h6>Day 27: Delhi - Agra</h6>
        <p>
        After breakfast at 0730 hrs drive to Agra (203 KM). Arrive and check-into hotel. After lunch visit The
Agra Fort containing the Pearl Mosque, the Halls of Public and Private Audience. Also visit the Tomb
of Itmad-ud-Daullah built by Empress Noorjehan in memory of her father. At sunset visit the world
famous Taj Mahal built by the Moghul Emperor Shahjehan in 1630 for his Queen Mumtaz Mahal to
enshrine her mortal remains. (CLOSED ON FRIDAYS) Overnight at hotel.
        </p>
        <h6>Day 28: Agra - Jaipur</h6>
        <p>
        After breakfast drive to Jaipur (256 KM) enroute visitng Fatehpur Sikri. On arrival check-in to hotel

        </p>
        <h6>Day 29: Jaipur - Delhi</h6>
        <p>
        After breakfast visit Amer Fort at 0800 hrs (optional: ascent on elephant’s back one way) - the ancient
capital of the State. Visit the Sheesh Mahal or the Hall of Victory glittering with mirrors. Proceed for a
tour of Jaipur thereafter. Jaipur Rajasthan was given a colour coat of pink a century ago in honour of a
visiting Prince. Built by Maharaja Jai Singh, the notable astronomer, this city is 260 years old. Visit
Maharaja&#39;s City Palace, the Observatory &amp; Ram Niwas Gardens. Drive past Hawa Mahal &amp; through the
pink rose residential &amp; business areas.
Evening drive back to Delhi. Check-in at hotel Shanti Palace.

        </p>
        <h6>Day 30: Delhi (departure)</h6>
        <p>
        Morning transfer to airport to board flight for onward journey

        </p>



        <h4>Properties foreseen :</h4>
        <table>
            <thead>
                <tr>
                    <th>Destination</th>
                    <th>Property</th>
                    <th>No.of.nights</th>

                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Chennai</td>
                    <td>Accord Metropolitan</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Mahabalipuram</td>
                    <td>Ideal Beach Resort</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Thanjavur</td>
                    <td>Ideal River View Resort</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Chettinad</td>
                    <td>Visalam</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Madurai</td>
                    <td>Hotel Sangam</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Kumarakom</td>
                    <td>House boat</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Alleppey</td>
                    <td>Fortune JP Palace</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Ooty</td>
                    <td>Lemon Tree</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Marari</td>
                    <td>Marari Villas</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Kochi</td>
                    <td>Hotel Casino</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Palakkad</td>
                    <td>Tharavadu Heritage
Homestay</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Ooty</td>
                    <td>Sinclairs</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Mysore</td>
                    <td>The Windflower</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Coorg</td>
                    <td>The Windflower</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Hassan</td>
                    <td>Hoysala Village Resort</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Hospet</td>
                    <td>Royal Orchid Central</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Badami</td>
                    <td>Badami Court Hotel</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Goa</td>
                    <td>Lemon Tree</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Delhi</td>
                    <td>Hans Plaza</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Agra</td>
                    <td>Mansingh Palace</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Jaipur</td>
                    <td>Mansingh Palace</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Delhi</td>
                    <td>Shanti Palace</td>
                    <td>1</td>
                </tr>

            </tbody>
        </table>
        <h6>Cost includes:</h6>
        <ul>
            <li>Accommodation</li>
            <li>Daily breakfast and dinner</li>
            <li>All meals in house boat</li>
            <li>AC vehicle for entire transportation including sightseeing as per the itinerary</li>
            <li>Driver bata, toll, parking, inter state permit (all expenses related to vehicle)</li>
            <li>Guided spice plantation visit at Thekkady/Periyar</li>
            <li>Guided coir factory visit at Alleppey</li>     
            <li>Kathakali tickets at Kochi</li>  
            <li>Arrival assistance at Chennai &amp; Delhi airports</li>  
            <li>All currently applicable taxes</li>         
        </ul>
        <h6>Cost excludes:</h6>
        <ul>
            <li>Any air/train fare</li>
            <li>Charges for English speaking guide, entry and optional activities</li>
            <li>Expenses of personal nature such as tips, telephone calls, laundry, liquor
etc</li>
<li>Anything not specified in ‘cost includes’</li>
        </ul>

        <h6>Please note:</h6>
        <ul>
            <li>Rooms are subject to availability </li>
            <li>Check-in time in the above properties is 11:00 am and check out at 12:00 noon </li>
            <li>Check-in time in houseboat is 12:00 noon and check out at 09:00 am</li>
            <li>Early check in or late check out are subject to availability &amp; prior request</li>
        </ul>
        
      </div>
      <EnquiryForm/>
      <Footer/>
    </div>
  )
}

export default HolidaysInIndia