import React, { useEffect } from "react";

import "./TrekkingAtMunnar.css";
import Footer from '../Footer/Footer'

import TrekkingInMunnar from "../Assets/Trekking-In-Munnar.jpg";
import TIM1 from "../Assets/TIM1.jpg";
import tim2 from "../Assets/tim2.jpg";
import tim3 from "../Assets/tim3.jpg";
import CommonNav from "../CommonNav/CommonNav";
import EnquiryForm from "../EnquiryForm/EnquiryForm";
function TrekkingATMunnar() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <div className="Athira-wrapper">
      <CommonNav />
      <div className="athira-MainHeading">
        <h1>India /Kerala: Trekking at Munnar </h1>
        <img className="athira-headingImg" src={TrekkingInMunnar} alt="" />
        <h6>Meesapulimalai “the second highest peak in South India” </h6>
        <img className="athira-headingImg" src={TIM1} alt="" />
        <p>
          Early morning we will drive up to Silent valley estate through green
          carpeted tea plantations of KDHP to the starting point, it will be
          about 1750 meters MSL (approx) we will starting with the jeep able
          track (used by only forest department, the trek will pass through gum
          trees, pine plantations etc…, before we reach the grasslands we can
          see a very nice waterfalls (seasonal). Will reach the grassland and
          rest of trek will be on the ridges of majestic Western Ghats with the
          spectacular view around, we need to ascend and descend few hills
          before we summit the peak “2637 meters MSL” and from the peak we can
          see low land of Tamil Nadu, highest tea estate, Anayirangal Dam, etc…,
          the view will be spectacular, after a short break we will trek back to
          Silent Valley through grasslands, gum, pine tree plantations, etc…,
          with few ascending and descending…(we have to walk on the same track
          for a very short distance to the finishing point) Finish at silent
          valley and drive back to Munnar (Light vehicle or Jeeps are ideal)
        </p>
        <h5>Duration: 6-8 hours</h5>
        <h6>
          Kolukkumalai Jeep safari, tea trail and Orthodox Tea Factory (1935)
          visit
        </h6>
        <img className="athira-headingImg" src={tim2} alt="" />
        <p>
          “The highest tea growing estate in the World”
          <br />
          After breakfast drive through the Kanan Devan Hills tea plantations
          and scenic beauty valleys, Lock heart gap, spice plantations etc…,{" "}
          <br />
          After around 25 kilometers jeep ride through the normal road we will
          reach the jeep able, jumpy, bumpy, rugged road to the World’s highest
          organic tea growing estate, it’s situates in the eastern slope of
          western Ghats which makes the tea very special as well the view, on
          our way to this estate we will have very wide view of tea estates and
          ride will be through small narrow jeep able roads with few bends, once
          we reach the top we will be able to see the Low land of Tamil Nadu,
          the second highest peak in S. India, etc…, from there we will drive or
          walk little further to one of the oldest orthodox tea factory in this
          region. After visiting the Factory we will have fresh tea picked,
          processed and made on the spot. Soon after we will start walk to a
          small ground where we will have our picnic lunch near the cliff with
          wonderful view around, will proceed to Munnar after lunch in the jeep
          itself if we like we can walk as much as we can and Will be a
          wonderful experience of the jeep drive through the tea plantations
          jeep able rugged road and can have close look to the spectacular
          country side and the rural Tamil people who is working in the tea
          plantations with the warm smile, also we can visit one of the oldest
          tea factory – in this tour in between we can walk as much we want.
        </p>
        <h5>Duration: Full day tour/Half day with fewer treks</h5>
        <h6>Top Valley trek </h6>
        <img className="athira-headingImg" src={tim3} alt="" />
        <p>
          We have to drive about 30 kilometers from Munnar towards Top Station
          through few of the major sightseeing spots of Munnar like Madupatty
          Dam, Echo-Point, Kundala Dam etc…, it could take 45 minutes to 01 hour
          drive to the starting point. Our trek will start - tea estate village
          will lead to tea plantation and to evergreen shola forest, montane
          forest, grasslands, also we will trek on the ridges of Western Ghats
          Eastern Slope which offers spectacular view to low land of Tamil Nadu,
          the second highest peak in South India, the highest tea estate in the
          World “Kolukkumalai” etc…, also this is one of the best trek to
          explore some of the endemic birds of this region. Our trek will start
          at the height of 1800 meters MSL (approx) and the highest point of the
          trek will be about 2250 meters MSL. Finish the trek at (near) Top
          Station and drive back to Munnar
        </p>
        <h5>Duration: 3-5 hours </h5>
      </div>
      <EnquiryForm/>
      <Footer/>
    </div>
  );
}

export default TrekkingATMunnar;
