import React, { useEffect } from 'react'

import teaPlantionPeriyar from '../Assets/teaPlantationPeriyar.jpeg'
import Footer from "../Footer/Footer";
import CommonNav from "../CommonNav/CommonNav";
import EnquiryForm from '../EnquiryForm/EnquiryForm';

function TeaPlantationPeriyar() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <div className="Athira-wrapper">
      <CommonNav />
      <div className="athira-MainHeading">
        <h1>India / Kerala/ Tea plantation trek @ Periyar</h1>
<img src={teaPlantionPeriyar} alt="" />
        <p>
        Explore the tea plantations on a soft trekking expedition and open up your gaze to the beauty of a place that has lost itself to Mother Nature’s warm embrace! The Plantation is about 20 minutes drive from Periyar, one naturalist will accompany with you and he will explain about  the process of how tea is grown, plucked and processed into what we consume as a hot beverage The view of local ladies plucking tea leaves from estate in the region increases the fun element of this trek. This trek will provide maximum scenic beauty of this region and a great experience amidst the tea garden.
        </p>
      </div>
      <EnquiryForm/>
      <Footer />
    </div>
  )
}

export default TeaPlantationPeriyar