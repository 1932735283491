import { useEffect } from "react";
import {
  Routes, Route,
} from "react-router-dom";
import './App.css';
import Booking from "./Booking/Booking";
import Contact from "./Contact/Contact";
import ContactQuery from "./Contact/ContactQuery";

import ACVA from "./Destinations/ACVA";
import AKVK from "./Destinations/AKVK";
import Athira from "./Destinations/Athira";
import BangaloreGoa from "./Destinations/BangaloreGoa";
import ChennaiKerala from "./Destinations/ChennaiKerala";
import CochinToCochin from "./Destinations/CochinToCochin";
import Destinations from "./Destinations/Destinations";
import FishingKumarakam from "./Destinations/FishingKumarakam";
import Goa from "./Destinations/Goa";
import GodsOwnCountry from "./Destinations/GodsOwnCountry";
import HillStations from "./Destinations/HillStations";
import Kashmir from "./Destinations/Kashmir";
import KeralaMadurai from "./Destinations/KeralaMadurai";
import KTK from "./Destinations/KTK";
import KumarakamVV from "./Destinations/KumarakamVV";
import KumbalangiVillage from "./Destinations/KumbalangiVillage";
import MangaloreMoogambika from "./Destinations/MangaloreMoogambika";
import OverTheHills from "./Destinations/OverTheHills";
import Periyar from "./Destinations/Periyar";
import StressBuster from "./Destinations/StressBuster";
import SunsetCochin from "./Destinations/SunsetCochin";
import TeaPlantationPeriyar from "./Destinations/TeaPlantationPeriyar";
import TrekkingATMunnar from "./Destinations/TrekkingATMunnar";
import Activities from './Activities/Activities'

import Home from './home/Home';
import Iteneraries from "./Itineraries/Iteneraries";
import ScrollToTop from "./ScrollToTop";
import CochinChennai from "./Itineraries/CochinChennai";
import SouthHomestay from "./Itineraries/SouthHomestay";
import GoaKochi from "./Itineraries/GoaKochi";
import HillsRinse from "./Itineraries/HillsRinse";
import HolidaysInIndia from "./Itineraries/HolidaysInIndia";
import GOC from "./Itineraries/GOC";
import ChennaiCochin from "./Itineraries/ChennaiCochin";
import GoldenTriangleGOC from "./Itineraries/GoldenTriangleGOC";
import ChennaiMunnar from "./Itineraries/ChennaiMunnar";
import OverToSouth from "./Itineraries/OverToSouth";
import GodsOwn from "./Itineraries/GodsOwn";
import KarnaatakaTamilnadu from "./Itineraries/KarnaatakaTamilnadu";
import KATNKL from "./Itineraries/KATNKL";
import About from "./About/About";
import GalleryPage from "./GalleryPage/GalleryPage";
import BangaloreKarnataka from "./Destinations/BangaloreKarnataka";
import ChennaiTamilNadu from "./Destinations/ChennaiTamilNadu";
import GoaDestination from "./Destinations/GoaDestination";
import GoldenTriangle from "./Destinations/GoldenTriangle";
import SrinagarJammu from "./Destinations/SrinagarJammu";
import WellnessAyurveda from "./Itineraries/WellnessAyurveda";
import ShimlaManali from "./Destinations/ShimlaManali";




function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <div className="App">
    <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/destination" element={<Destinations/>} />
        <Route path="/destination/Athira-Kovalam" element={<Athira/>} />
        <Route path="/destination/TrekkingAtMunnar" element={<TrekkingATMunnar/>} />
        <Route path="/destination/BangaloreGoa" element={<BangaloreGoa/>} />
        <Route path="/destination/FishingKumarakam" element={<FishingKumarakam/>} />
        <Route path="/destination/GodsOwnCounry" element={<GodsOwnCountry/>} />
        <Route path="/destination/hillStations" element={<HillStations/>} />
        <Route path="/destination/KTK" element={<KTK/>} />
        <Route path="/destination/kumarakamVV" element={<KumarakamVV/>} />
        <Route path="/destination/ChennaiKerala" element={<ChennaiKerala/>} />
        <Route path="/destination/OverTheHills" element={<OverTheHills/>} />
        <Route path="/destination/periyar" element={<Periyar/>} />
        <Route path="/destination/TeaPlantationPeriyar" element={<TeaPlantationPeriyar/>} />
        <Route path="/destination/MangaloreMoogambika" element={<MangaloreMoogambika/>} />
        <Route path="/destination/KumbalangiVillage" element={<KumbalangiVillage/>} />
        <Route path="/destination/SunsetCochin" element={<SunsetCochin/>} />
        <Route path="/destination/StressBuster" element={<StressBuster/>} />
        <Route path="/destination/CochinToCochin" element={<CochinToCochin/>} />
        <Route path="/destination/AKVK" element={<AKVK/>} />
        <Route path="/destination/ACVA" element={<ACVA/>} />
        <Route path="/destination/Kashmir" element={<Kashmir/>} />
        <Route path="/destination/Goa" element={<Goa/>} />
        <Route path="/destination/KeralaMadurai" element={<KeralaMadurai/>} />
        <Route path="/booking" element={<Booking/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/contactQuery" element={<ContactQuery/>} />
        <Route path="/Iteneraries" element={<Iteneraries/>} />
        <Route path="/Activities" element={<Activities/>} />
        <Route path="/CochinChennai" element={<CochinChennai/>} />
        <Route path="/SouthHomestay" element={<SouthHomestay/>} />
        <Route path="/GoaKochi" element={<GoaKochi/>} />
        <Route path="/HillsRinse" element={<HillsRinse/>} />
        <Route path="/HolidaysInIndia" element={<HolidaysInIndia/>} />
        <Route path="/GOC" element={<GOC/>} />
        <Route path="/ChennaiCochin" element={<ChennaiCochin/>} />
        <Route path="/GoldenTriangleGOC" element={<GoldenTriangleGOC/>} />
        <Route path="/ChennaiMunnar" element={<ChennaiMunnar/>} />
        <Route path="/OverToSouth" element={<OverToSouth/>} />
        <Route path="/GodsOwnCountry" element={<GodsOwn/>} />
        <Route path="/KarnatakaTamilNadu" element={<KarnaatakaTamilnadu/>} />
        <Route path="/KATNKL" element={<KATNKL/>} />
        <Route path="/AboutUs" element={<About/>} />
        <Route path="/gallery" element={<GalleryPage/>} />
        <Route path="/BangaloreKarnataka" element={<BangaloreKarnataka/>} />
        <Route path="/ChennaiTamilNadu" element={<ChennaiTamilNadu/>} />
        <Route path="/GoaDestination" element={<GoaDestination/>} />
        <Route path="/GoldenTriangle" element={<GoldenTriangle/>} />
        <Route path="/SrinagarJammu" element={<SrinagarJammu/>} />
        <Route path="/WellnessAyurveda" element={<WellnessAyurveda/>} />
        <Route path="/ShimlaManali" element={<ShimlaManali/>} />
      </Routes>
    </div>
  );
}

export default App;
