import React, { useEffect } from 'react'

import CommonNav from "../CommonNav/CommonNav";
import EnquiryForm from '../EnquiryForm/EnquiryForm';
import Footer from '../Footer/Footer'
import Goc from '../Assets/Goc.png'
import GOC1 from '../Assets/GOC1.png'
import GOC3 from '../Assets/GOC3.png'
import teakady from '../Assets/teakady.png'

function KeralaMadurai() {
    useEffect(() => {
        window.scrollTo(0, 0);
      },[])
  return (
    <div className="Athira-wrapper">
    <CommonNav />
    <div className="athira-MainHeading">
      <h1>India / 10-Day Itinerary / Kerala &amp; Madurai </h1>
      <h4>Suggested itinerary</h4>
      <h6>Day 01: Cochin</h6>
      <p>
      Upon arrival at Cochin airport, Taj hotel will arrange transfer.
      </p>
      <h6>Day 02: Cochin</h6>
      <p>
      Enjoy tour of heritage town Fort Cochin and Mattancherry. Visit Jewish Synagogue (closed
on Fridays and Saturdays), which was built in 1568 and is still an active house of worship for
the few remaining Jews in the area. Next to the synagogue is Cochin’s ancient and colorful
Jew Street with its antique stores and souvenir peddlers. There is evidence of a Jewish
presence in Kochi from 70 AD. After the synagogue you visit the St. Francis Church, which
was established by the Portuguese Franciscan friars in 1503. This is one of the first European
churches built in India and the famed Vasco Da Gama was buried in its courtyard for a few
years before his remains were returned to Portugal. <br />
<img src={GOC1} alt="" />
<br />
Later you have an option to visit the most photogenic of Cochin’s attractions, 15th century
Chinese fishing nets. You may see local fishermen using these massive nets with an ease that
comes from centuries of practice, the lowering and raising of the nets has a synchronicity and
rhythm that is quite an amazing experience to watch. Enjoy Kathakali show (traditional
dance-drama art form of Kerala) in a nearby cultural theatre. Overnight at Cochin <br />
<img src={Goc} alt="" />
</p>
      <h6>Day 03: Cochin</h6>
      <p>
      Relax your holiday. Overnight at Cochin
      </p>
      <h6>Day 04: Cochin to Munnar</h6>
      <p>
      After breakfast check out and proceed to Munnar (140 KM / 4/5 hrs). Enjoy the drive through
the tea mountains and the picturesque green carpet of the nature. On the way you can watch
Valara and Cheeyappara waterfalls and Karidippara view point. Upon reaching, check into
hotel. Overnight stay at Munnar.
      </p>
      <h6>Day 05: Munnar </h6>
      <p>
      Proceed for sightseeing trip of Munnar after breakfast. Visit the Blossom Garden, Tata Tea
Museum (closed on Mondays), Eravikulam National Park at Rajamlai (where you can see the
rare specious of Nilgiri Tahr), Mattupetty Dam which offers many fascinating sights to
relish, the Shola forests around Mattupetty area and Kundala Dam. (You can also visit Photo
Shooting Point, Echo Point, View Point &amp; the green carpet of Tea Gardens on the way.
Overnight stay at Munnar.
<img src={GOC3} alt="" />
      </p>
      <h6>Day 06: Munnar to Madurai</h6>
      <p>
      Proceed to Madurai (175 KM – 4/5 hrs). Meenakshi Amman temple in Madurai is considered
as one of the seven wonders of India. Today visit the evening ceremony at the temple.
Overnight stay at Madurai
      </p>
      <h6>Day 07: Madurai </h6>
      <p>
      Today enjoy sightseeing. See the architectural beauty of Madurai Meenakshi Amman
temple. Later visit palace of Tirumalai Nayak, Mariammam Teppakkulam basin etc….
Overnight stay at Madurai

      </p>
      <h6>Day 08: Madurai to Periyar/Thekkady</h6>
      <p>
      Proceed to Thekkady (150 KM - 4 hours) after breakfast. Check into hotel; Visit the Periyar
Wild life Sanctuary.

      </p>
      <img src={teakady} alt="" />
      <h6>Day 09: Periyar/Thekkady </h6>
      <p>
      Set out for boat cruise in the early hours (Boating starts at 07:00 am. You can take packed
breakfast from the hotel if informed on previous day) Go for wildlife cruise to view a large
variety of wild animal like Wild Elephants, Nilgiri Langur, Sambar Deer, Wild Bison, Wild
Boar etc... and quite a lot of unique birds can also be spotted.
(You can enjoy various optional activities like elephant ride, spice plantation visit, nature
walk (soft trek) etc…) Overnight stay at Thekkady.
      </p>
      <h6>Day 10: Periyar/Thekkady to Kumarakom</h6>
      <p>
      Proceed to Kumarakom (130 KM/4hrs). Relax your holiday at a lake side resort. (Optional:
Enjoy an Ayurveda massage) Stay at Kumarakom
      </p>
      <table>
          <thead>
              <tr>
                  <th>Destination</th>
                  <th>Nights</th>

              </tr>
          </thead>
          <tbody>
              <tr>
                  <td>Munnar</td>
                  <td>2</td>
              </tr>
              <tr>
                  <td>Madurai</td>
                  <td>2</td>
              </tr>
              <tr>
                  <td>Thekkady</td>
                  <td>2</td>
              </tr>
              <tr>
                  <td>Kumarakom</td>
                  <td>1</td>
              </tr>
          </tbody>
      </table>
      <h6>Cost includes:</h6>
      <ul>
          <li>Accommodation</li>
          <li>Daily breakfast and dinner</li>
          <li>English Speaking Guide at Cochin</li>
          <li>English Speaking Guide at Munnar</li>
          <li>English Speaking Guide at Madurai</li>
          <li>English Speaking Guide at Thekkady</li>
          <li>Boat ride at Thekkady (at 07:00 am)</li>
          <li>Transportation by AC Vehicle</li>
          <li>Toll, parking and all charges for driver</li>
          <li>All applicable taxes</li>
      </ul>
      <h6>Cost excludes:</h6>
      <ul>
          <li>Flight / Train tickets</li>
          <li>Charges for entry optional activities</li>
          <li>Tip, laundry, telephone charges, entry charges to any monuments/sightseeing spots</li>
          <li>All other expenses which are not mentioned in the inclusions </li>
      </ul>

      <h6>Note:</h6>
      <ul>
          <li>Booking confirmation is subject to availability of rooms. </li>
          <li>Check in time at the hotel is 12.00 hrs and check out time is 11.00 hrs</li>
          <li>Check in time at the houseboat is 12.00 hrs and check out time is 09.00 hrs </li>
      </ul>
      
    </div>
    <EnquiryForm/>
    <Footer/>
  </div>
  )
}

export default KeralaMadurai