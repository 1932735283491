import React from 'react'
import CommonNav from '../CommonNav/CommonNav'
import Footer from '../Footer/Footer'
import AboutUs from '../home/AboutUs/AboutUs'

function About() {
  return (
    <div>
        <CommonNav/>
        <AboutUs/>
        <Footer/>
    </div>
  )
}

export default About